import React, { memo } from "react";
import {
       DataListSelect,    
       FormControlSelect,
       LabelSelect,
       DataListInput,
    } from "./Styled";

const InputDataListSelect = (props) => {

    return(
        <FormControlSelect transparent={props.transparent || false}>
            <LabelSelect error={props.error}>{props.label}</LabelSelect>
            <DataListInput 
                list={props.list} 
                id={props.id} 
                name={props.name} />
            <DataListSelect id={props.list}>
            {props.options.map((item, index) => (
                    <option key={index} value={item.value}/>
                ))}
            </DataListSelect>
        </FormControlSelect>
      
    );

} 
export default memo(InputDataListSelect);