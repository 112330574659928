import React from "react";
import { Container } from "@material-ui/core";
import moment from "moment";
import Loading from "components/loading";
import ButtonCustom from "components/buttonCustom";

import useHome from "./useHome";

import {
  Main,
  Title,
  TitleIcon,
  Inner,
  TbContainer,
  TbCustom,
  TbHead,
  TbBody,
  TbRow,
  TbCell,
  TbFooter,
  TbPagination,
  Status,
  BtnGroup,
  Icon,
  GroupField,
  StyledRadioButton,
  HiddenRadioButton,
  RadioButtonLabel,
  BudgeMessages,
} from "./Styled";

import ComunImg from "./images/talk.png";

const Home = () => {
  const {
    transitionIn,
    loadingPage,
    listMessages,
    currentLang,
    handleChangePage,
    handleChangeRowsPerPage,
    handleShowMessage,
    handleShowAddMessage,
    handleLoadMessages,
    generalStatus,
    translate,
    Utils,
    pagination,
  } = useHome();

  if (loadingPage) {
    return <Loading />;
  }

  const RadioButton = ({ label, checked, onChange, value }) => {
    return (
      <RadioButtonLabel>
        <HiddenRadioButton
          value={value}
          checked={checked}
          onChange={onChange}
        />
        <StyledRadioButton checked={checked} />
        {label}
      </RadioButtonLabel>
    );
  };

  return (
    <React.Fragment>
      <Main>
        <Container>
          <Title transition={transitionIn}>
            <TitleIcon src={ComunImg} />
            {translate("home.title")} - Solicitações{" "}
            {generalStatus === "opened" ? "em aberto" : "encerradas"}
          </Title>

          <GroupField>
            <RadioButton
              label="Em aberto"
              checked={generalStatus === "opened"}
              onChange={handleLoadMessages}
              value="opened"
            />
            <RadioButton
              label="Encerrados"
              checked={generalStatus === "closed"}
              onChange={handleLoadMessages}
              value="closed"
            />
          </GroupField>

          <Inner transition={transitionIn}>
            <TbContainer>
              <TbCustom>
                <TbHead>
                  <TbRow>
                    <TbCell align="center">{translate("home.table.id")}</TbCell>
                    <TbCell align="center">
                      {translate("home.table.area")}/
                      {translate("home.table.tipo")}
                    </TbCell>
                    <TbCell align="center">
                      {translate("home.table.data")}
                    </TbCell>
                    <TbCell align="center" width={90}>
                      {translate("home.table.status")}
                    </TbCell>
                    <TbCell>Mensagens</TbCell>
                  </TbRow>
                </TbHead>
                <TbBody>
                  {listMessages.length > 0 &&
                    listMessages.map((item) => (
                      <TbRow
                        key={item.cmit_id_comunicacao_interna}
                        onClick={() =>
                          handleShowMessage(item.cmit_id_comunicacao_interna)
                        }
                      >
                        <TbCell align="center">
                          {item.cmit_id_comunicacao_interna}
                        </TbCell>
                        <TbCell align="center">
                          {item.cmit_area} / {item.cmit_tipo}
                        </TbCell>

                        <TbCell align="center">
                          {moment(item.cmit_dh_registro).format(
                            Utils.formatDataHoraIsLang(currentLang)
                          )}
                        </TbCell>
                        <TbCell align="center" width={80}>
                          <Status
                            currentStatus={item.cmit_status}
                            type={item.cmit_status}
                          >
                            {Utils.formataStatusComunicacao(item.cmit_status)}
                          </Status>
                        </TbCell>
                        <TbCell align="center" width={60}>
                          {item.mensagensNaoLidas > 0 ? (
                            <BudgeMessages>
                              {item.mensagensNaoLidas}
                            </BudgeMessages>
                          ) : (
                            ""
                          )}
                        </TbCell>
                      </TbRow>
                    ))}
                </TbBody>
                {pagination.totalItems > pagination.itemsPerPage && (
                  <TbFooter>
                    <TbRow>
                      <TbPagination
                        rowsPerPageOptions={[10]}
                        colSpan={4}
                        count={pagination.totalItems}
                        rowsPerPage={pagination.itemsPerPage}
                        page={pagination.currentPage - 1}
                        labelRowsPerPage={translate("home.table.perPage")}
                        labelDisplayedRows={({ from, to, count }) => {
                          return `${from} - ${to} ${translate(
                            "home.table.of"
                          )} ${count}`;
                        }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </TbRow>
                  </TbFooter>
                )}
              </TbCustom>
            </TbContainer>
          </Inner>

          <BtnGroup transition={transitionIn}>
            <ButtonCustom
              color="default"
              type="button"
              size="large"
              className="btn-green"
              startIcon={<Icon className="icon icon-plus" />}
              onClick={handleShowAddMessage}
              fullWidth
            >
              {translate("home.btnAdd")}
            </ButtonCustom>
          </BtnGroup>
        </Container>
      </Main>
    </React.Fragment>
  );
};

export default Home;
