import React, { memo } from "react";
import {
  Icon,
  MultipleSelectLabel,
  SelectedLabelItem,
  FormControlSelect,
  LabelSelect,
  TSelect,
  TSelectItem,
} from "./Styled";

const InputSelect = (props) => {

  if (props.multiple === true) {
    return (
      <>
        <FormControlSelect transparent={props.transparent || false}>
          {props.label && (
            <LabelSelect
              labeldark={props.labeldark ?? false}
              error={props.error}
            >
              {props.label}
            </LabelSelect>
          )}
          <TSelect
            multiple
            labelId={props.labelId}
            name={props.name}
            id={props.labelId}
            value={props.value}
            onChange={props.onChange}
            error={props.error}
          >
            {props.options.map((item, index) => (
              <TSelectItem key={index} value={item.value}>
                {item.label}
              </TSelectItem>
            ))}
          </TSelect>
        </FormControlSelect>
        {props.value.length > 0 && !props.hideSelected && (
          <MultipleSelectLabel>
            {props.value.map((item, index) => (
              <SelectedLabelItem key={index}>
                {item}{" "}
                <Icon
                  className="icon icon-close"
                  onClick={() => props.onRemoveItemSelectMultiple(item)}
                />
              </SelectedLabelItem>
            ))}
          </MultipleSelectLabel>
        )}
      </>
    );
  }

  return (
    <FormControlSelect transparent={props.transparent || false}>
      {props.label && (
        <LabelSelect labeldark={props.labeldark ?? "false"} error={props.error}>
          {props.label}
        </LabelSelect>
      )}
      <TSelect
        labelId={props.labelId}
        name={props.name}
        id={props.labelId}
        value={props.value}
        onChange={props.onChange}
        label={props.label}
        error={props.error}
      >
        {props.options.map((item, index) => (
          <TSelectItem key={index} value={item.value}>
            {item.label}
          </TSelectItem>
        ))}
      </TSelect>
    </FormControlSelect>
  );
};

export default memo(InputSelect);
