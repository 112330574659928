import styled from "styled-components";

import ReactSelect from "react-select";

export const Container = styled.div`
    display: flex;
    width: 100%;

    .react-select {
        flex: 1;

        & + div {
            margin-left: 16px;
        }
    }

    justify-content: space-between;
`;

export const Select = styled(ReactSelect)`
    &&{
       background-color: red;
    }
`;