import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: transparent;
`;

export const Main = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Header = styled.header`
  display: flex;
  height: 280px;
  background-color: transparent;
  margin-bottom: 20px;
  justify-content: center;
`;

export const Logo = styled.img`
  display: flex;
`;

export const Mensagem = styled.div`
  display: block;
  margin-top: 20px;
  margin-bottom: 30px;
  & > h1 {
    font-size: 22px;
    color: #ffffff;
  }
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 150px;
  width: 50%;
  height: 40px;
  background-color: #008000;
  border: 1px solid #FFFFFF;
  color: #ffffff;
  margin-top: 10px;
  cursor: pointer;
  &:hover {
    background-color: #004d00;
  }
  & > span {
    font-size: 16px;
  }
`;
