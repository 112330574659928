import React from "react";
import { Container, Grid } from "@material-ui/core";

import ButtonCustom from "components/buttonCustom";
import InputLabelRadio from "components/form/InputLabelRadio";
import AlertCustom from "components/alertCustom";
import Loading from "components/loading";

import useTermsOfUse from "./useTermsOfUse";

import ModalCustom from "./modalCustom";

import codigoEticaCondutaResumo from "./codigo-de-etica-e-conduta-resumida.pdf";
import codigoEticaCondutaCompleto from "./codigo-de-etica-e-conduta-completo.pdf";

import posterCliente from "./images/altum_clientes.png";
import videoCliente from "./videos/altum_clientes.mp4";
import posterColab from "./images/altum_colab.png";
import videoColab from "./videos/altum_colab.mp4";

import {
    Main,
    ContentPage,
    Transition,
    Header,
    Title,
    Logo,
    Slider,
    ContainerVideo,
    GroupBtns,
    BtnDownload,
    Icon,
} from "./Styled";

import LogoImg from "./images/logo.png";

const ButtonArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <ButtonCustom
            color="secondary"
            className={className}
            style={{ ...style }}
            onClick={onClick}
        >
            <span className="icon icon-arrow" />
        </ButtonCustom>
    );
};

const TermsOfUser = () => {
    const {
        transitionIn,
        loadingPage,
        loadingForm,
        loadingModal,
        contractAccepted,
        openMessage,
        message,
        openModal,
        typeModal,
        selectedValue,
        error,
        refSignature,
        sliderCurrent,
        handleOpenAlert,
        handleChangeTermoUso,
        handleAceiteTermoUso,
        handleNoAceiteTermoUso,
        handleCloseModal,
        handleClearSignature,
        handleSendSignature,
        handleChangeSlider,
        translate,
    } = useTermsOfUse();

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        nextArrow: <ButtonArrow className="bt__arrow--right" />,
        prevArrow: <ButtonArrow className="bt__arrow--left" />,
        afterChange: (current) => handleChangeSlider(current),
    };

    if (loadingPage) {
        return <Loading />;
    }

    return (
        <React.Fragment>
            <Main>
                <Container>
                    <ContentPage loadingForm={loadingForm}>
                        <Grid container justifyContent="center">
                            <Grid item xs={11} md={10}>
                                <Header>
                                    <Title transition={transitionIn}>
                                        {translate("termsOfUse.title")}
                                    </Title>
                                    <Logo
                                        src={LogoImg}
                                        transition={transitionIn}
                                    />
                                </Header>
                                <Transition transitionIn={transitionIn}>
                                    <Slider {...settings}>
                                        
                                    <ContainerVideo>
                                            <video
                                                poster={posterColab}
                                                controls={sliderCurrent === 0}
                                            >
                                                <source
                                                    src={videoColab}
                                                    type="video/mp4"
                                                />
                                            </video>
                                        </ContainerVideo>

                                        <ContainerVideo>
                                            <video
                                                poster={posterCliente}
                                                controls={sliderCurrent === 1}
                                            >
                                                <source
                                                    src={videoCliente}
                                                    type="video/mp4"
                                                />
                                            </video>
                                        </ContainerVideo>
                                       
                                    </Slider>
                                </Transition>
                                <GroupBtns transition={transitionIn}>
                                    <BtnDownload
                                        href={codigoEticaCondutaResumo}
                                        target="_blank"
                                    >
                                        <Icon className="icon-download" />
                                        {translate(
                                            "termsOfUse.btnDownloadVersionResum"
                                        )}
                                    </BtnDownload>
                                    <BtnDownload
                                        href={codigoEticaCondutaCompleto}
                                        target="_blank"
                                    >
                                        <Icon className="icon-download" />
                                        {translate(
                                            "termsOfUse.btnDownloadVersionComplete"
                                        )}
                                    </BtnDownload>
                                </GroupBtns>
                            </Grid>

                            {!contractAccepted && (
                                <>
                                    <Grid
                                        className="m-top-50"
                                        item
                                        xs={11}
                                        md={10}
                                    >
                                        <InputLabelRadio
                                            checked={selectedValue === "aceito"}
                                            onChange={handleChangeTermoUso}
                                            aria-label="aceito"
                                            value="aceito"
                                            options={[
                                                {
                                                    label: translate(
                                                        "termsOfUse.termUse"
                                                    ),
                                                    value: "aceito",
                                                },
                                            ]}
                                        />
                                    </Grid>
                                    <Grid
                                        className="m-top-10"
                                        item
                                        xs={11}
                                        md={10}
                                    >
                                        <Grid container spacing={4}>
                                            <Grid item xs={12} md={6}>
                                                <ButtonCustom
                                                    color="default"
                                                    type="button"
                                                    size="large"
                                                    onClick={
                                                        handleNoAceiteTermoUso
                                                    }
                                                    fullWidth
                                                >
                                                    {translate(
                                                        "termsOfUse.btnNoAccept"
                                                    )}
                                                </ButtonCustom>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <ButtonCustom
                                                    color="default"
                                                    type="button"
                                                    size="large"
                                                    className="btn-green"
                                                    disabled={error}
                                                    onClick={
                                                        handleAceiteTermoUso
                                                    }
                                                    fullWidth
                                                >
                                                    {translate(
                                                        "termsOfUse.btnAccept"
                                                    )}
                                                </ButtonCustom>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </ContentPage>
                </Container>
            </Main>
            <ModalCustom
                typeModal={typeModal}
                loadingModal={loadingModal}
                open={openModal}
                onClose={handleCloseModal}
                refSignature={refSignature}
                handleClearSignature={handleClearSignature}
                handleSendSignature={handleSendSignature}
            />
            <AlertCustom
                message={message}
                openMessage={openMessage}
                closeMessage={handleOpenAlert}
                transition="down"
                origin={{ vertical: "top", horizontal: "right" }}
            />
        </React.Fragment>
    );
};

export default TermsOfUser;
