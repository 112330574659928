import React from "react";
import { Grid } from "@material-ui/core";
import { Main, Transition, GroupInfo, Image, Title } from "./Styled";

import Loading from "components/loading";
import ButtonLink from "components/buttonLink";

import { Utils } from "utils";
import usePage404 from "./usePage404";

import PageNotfound from "./images/pageNotfound.svg";

const Page404 = () => {
    const { loadingPage, transitionIn, translate } = usePage404();

    if (loadingPage) {
        return <Loading />;
    }

    return (
        <React.Fragment>
            <Main>
                <Grid container>
                    <Grid item xs={10} md={10}>
                        <Transition transitionIn={transitionIn}>
                            <GroupInfo>
                                <Image src={PageNotfound} />
                                <Grid container>
                                    <Grid item xs={10} md={7}>
                                        <Title as="h1">
                                            {translate("page404.title")}
                                        </Title>
                                        <ButtonLink to={Utils.setPrefix("/")}>
                                            {translate("page404.goBack")}
                                        </ButtonLink>
                                    </Grid>
                                </Grid>
                            </GroupInfo>
                        </Transition>
                    </Grid>
                </Grid>
            </Main>
        </React.Fragment>
    );
};

export default Page404;
