import React, { memo } from "react";
import { Slide } from "@material-ui/core";
import { Message } from "./Styled";

const AlertCustom = ({
    message,
    openMessage,
    closeMessage,
    transition,
    severity,
    origin,
}) => {
    switch (transition) {
        case "left":
            return (
                <Message
                    severity={severity || "error"}
                    message={message}
                    open={openMessage}
                    onClose={closeMessage}
                    TransitionComponent={TransitionLeft}
                    anchorOrigin={{
                        vertical: origin.vertical,
                        horizontal: origin.horizontal,
                    }}
                    autoHideDuration={6000}
                />
            );
    
        case "right":
            return (
                <Message
                    severity={severity || "error"}
                    message={message}
                    open={openMessage}
                    onClose={closeMessage}
                    TransitionComponent={TransitionRight}
                    anchorOrigin={{
                        vertical: origin.vertical,
                        horizontal: origin.horizontal,
                    }}
                    autoHideDuration={6000}
                />
            );
    
        case "up":
            return (
                <Message
                    severity={severity || "error"}
                    message={message}
                    open={openMessage}
                    onClose={closeMessage}
                    TransitionComponent={TransitionUp}
                    anchorOrigin={{
                        vertical: origin.vertical,
                        horizontal: origin.horizontal,
                    }}
                    autoHideDuration={6000}
                />
            );
     
        case "down":
            return (
                <Message
                    severity={severity || "error"}
                    message={message}
                    open={openMessage}
                    onClose={closeMessage}
                    TransitionComponent={TransitionDown}
                    anchorOrigin={{
                        vertical: origin.vertical,
                        horizontal: origin.horizontal,
                    }}
                    autoHideDuration={6000}
                />
            );
         
            default:
                return(<></>)
    }
};

const TransitionLeft = (props) => {
    return <Slide {...props} direction="left" />;
};
const TransitionRight = (props) => {
    return <Slide {...props} direction="right" />;
};
const TransitionUp = (props) => {
    return <Slide {...props} direction="up" />;
};
const TransitionDown = (props) => {
    return <Slide {...props} direction="down" />;
};

export default memo(AlertCustom);
