import styled from "styled-components";
import Form from "components/form";
import Theme from "Theme";

export const Main = styled.main`
  min-height: 100vh;
  background: rgb(33, 75, 138);
  background: linear-gradient(
    139deg,
    rgba(33, 75, 138, 1) 25%,
    rgba(0, 145, 67, 1) 100%
  );
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Inner = styled.div`
  max-width: 360px;
  height: 100%;
  background-color: ${Theme.colorWhite};
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  padding: 30px 0;
  border-radius: 6px;
`;

export const Logo = styled.img`
  max-width: 230px;
  height: auto;
  margin: auto auto 40px;
  transition: all 0.3s ease-in-out;
  opacity: ${(props) => (props.transition ? 1 : 0)};
  transform: translateX(${(props) => (props.transition ? 0 : "-30px")});

  @media (max-width: 768px) {
    max-width: 160px;
  }
`;

export const FormPage = styled(Form)`
  max-width: 400px;
`;

export const AnimeForm = styled.div`
  transition: all 0.5s ease-in-out;
  opacity: ${(props) => (props.transition ? "1" : "0")};
  transform: translateX(${(props) => (props.transition ? "0" : "30px")});
`;

export const GroupField = styled.div`
  margin-bottom: 5px;
`;

export const GroupBtn = styled.div`
  margin: 22px 0 30px;
`;

export const A = styled.a`
  color: ${Theme.colorBlack};
  font-size: 16px;
  font-weight: 300;
  text-align: center;
  display: table;
  text-decoration: none;
  margin: auto;
  cursor: pointer;
  &:hover {
    color: #a0a0a0;
  }
  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;

export const B = styled.strong`
  color: ${Theme.colorBlueDark};
  font-weight: 500;
  text-align: center;
  display: block;
`;

export const MessageSuccess = styled.span`
  width: 300px;
  line-height: 1.3;
  color: ${Theme.colorGreen};
  font-weight: 500;
  text-align: center;
  display: block;
  margin-bottom: 20px;
`;

export const Strong = styled.strong`
  color: ${Theme.colorBlueDark};
  font-weight: 500;
  text-align: center;
`;

export const Register = styled.div`
  margin-top: 30px; 
  a {
    &,
    strong {
      color: ${Theme.colorWhite};
    }
  }
`;

export const ContainerButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonDenuncia = styled.a`
  width: 40px;
`;
