import React, { memo } from "react";
import { BtnDefault, BtnPrimary, BtnSecondary } from "./Styled";

const ButtonCustom = (props) => <Btn {...props}>{props.children}</Btn>;

const Btn = (props) => {
    switch (props.color) {
        case "default":
            return <BtnDefault {...props}>{props.children}</BtnDefault>;
        case "primary":
            return <BtnPrimary {...props}>{props.children}</BtnPrimary>;
        case "secondary":
            return <BtnSecondary {...props}>{props.children}</BtnSecondary>;
        default:
            return <BtnDefault {...props}>{props.children}</BtnDefault>;
    }
};

export default memo(ButtonCustom);
