import { createStore } from "redux";

const INITIAL_STATE = {
    data: [],
    message: "",
    showModal: false,
};

const states = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "ADD_DATA":
            return { ...state, data: action.data };
        case "ADD_MESSAGE":
            return { ...state, message: action.value };
        case "ADD_SHOW_MODAL":
            return { ...state, showModal: action.value };
        default:
            return state;
    }
};

const Store = createStore(states);

export default Store;
