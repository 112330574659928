import React, { memo } from "react";
import Region from "./region";
import Contact from "./contact";
import Address from "./address";
import BasicInformation from "./basicInformation";
import Specializations from "./specializations";
import ProfessionalExperience from "./professionalExperience";

const AllSteps = ({ id }) => {
    const stepsComponent = {
        0: <Region />,
        1: <Contact />,
        2: <BasicInformation />,
        3: <Address />,
        4: <Specializations />,
        5: <ProfessionalExperience />,
        
    };

    return stepsComponent[id];
};

export default memo(AllSteps);
