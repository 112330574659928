import React from "react";
import { withRouter, Switch, Route, Redirect } from "react-router-dom";
import { WorkWithUsProvider } from "contexts/WorkWithUs.contexts";
import { Utils } from "utils";

// Páginas
import Login from "screens/login";
import Register, {
  Welcome,
  SelectTypeOfVacancy,
  RegistrationCompleted,
} from "screens/workWithUs";
import EmailConfirmation from "screens/emailConfirmation";
import PasswordRecovery from "screens/passwordRecovery";
import ForgotPassword from "screens/forgotPassword";
const Public = () => (
  <React.Fragment>
    <WorkWithUsProvider>
      <Switch>
        <Route
          exact
          path={Utils.setPrefix("/email-confirmation/:hash")}
          component={EmailConfirmation}
        />
        <Route exact path={Utils.setPrefix("/login")} component={Login} />
        <Route
          exact
          path={Utils.setPrefix("/trabalhe-conosco")}
          component={Welcome}
        />
        <Route
          exact
          path={Utils.setPrefix("/trabalhe-conosco/selecione-tipo-vaga")}
          component={SelectTypeOfVacancy}
        />
        <Route
          exact
          path={Utils.setPrefix("/trabalhe-conosco/cadastro")}
          component={Register}
        />
        <Route
          exact
          path={Utils.setPrefix("/trabalhe-conosco/cadastro/finalizado")}
          component={RegistrationCompleted}
        />
        <Route
          exact
          path={Utils.setPrefix("/password-recovery")}
          component={PasswordRecovery}
        />

        <Route
          exact
          path={Utils.setPrefix("/forgot-password")}
          component={ForgotPassword}
        />
        <Redirect to={Utils.setPrefix("/login")} />
      </Switch>
    </WorkWithUsProvider>
  </React.Fragment>
);

export default withRouter(Public);
