import { useState, useEffect, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Utils } from "utils";

import Api from "service/api";

const useDocuments = () => {
    const [transitionIn, setTransitionIn] = useState(false);
    const [loadingPage, setLoadingPage] = useState(true);
    const [loadingModal, setLoadingModal] = useState(false);
    const [documents, setDocuments] = useState([]);
    const [contentModal, setContentModal] = useState({});
    const [openModal, setOpenModal] = useState(false);
    const [modalType, setModalType] = useState("");
   // const [imgSignature, setImgSignature] = useState("");
    const [openMessage, setOpenMessage] = useState(false);
    const [messageType, setMessageType] = useState("error");
    const [message, setMessage] = useState("");

    const { t: translate } = useTranslation();

    const refSignature = useRef();

    document.title = translate("documents.head.title");

    const getData = useCallback(() => {
        try {
            Api.get(`/documentos`)
                .then((response) => {
                    const {
                        data: { documentos },
                    } = response;
                    //console.log(response);
                    setDocuments(documentos);
                    setLoadingPage(false);

                    setTimeout(() => {
                        setTransitionIn(true);
                    }, 100);
                })
                .catch((error) => {
                    setLoadingPage(true);
                    setTimeout(() => {
                        setTransitionIn(true);
                    }, 100);
                    console.log(error);
                });
        } catch (error) {
            if (Api.isCancel(error)) {
                setTransitionIn(true);
            } else {
                setTransitionIn(true);
                throw error;
            }
        }
    }, []);

    const getDocument = useCallback((id) => {
        setLoadingModal(true);
        try {
            Api.get(`/documentos/${id}`)
                .then((response) => {
                    const {
                        data: {
                            documento: {
                                doat_id_documento_aceite_nome,
                                doat_arquivo,
                                doat_texto,
                                status_aceite,
                                id_aceite,
                            },
                        },
                    } = response;

                    setContentModal({
                        title: doat_id_documento_aceite_nome,
                        urlPdf: doat_arquivo,
                        textHtml: doat_texto,
                        status: status_aceite,
                        acceptId: id_aceite,
                    });

                    setTimeout(() => {
                        setLoadingModal(false);
                    }, 1000);
                })
                .catch((error) => {
                    console.log(error);
                });
        } catch (error) {
            if (Api.isCancel(error)) {
                setTransitionIn(true);
            } else {
                setTransitionIn(true);
                throw error;
            }
        }
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
        getData();
    }, [getData]);

    const handleOpenModal = useCallback(
        (id) => {
            getDocument(id);
            setOpenModal(true);
        },
        [getDocument]
    );

    const handleCloseModal = useCallback(() => {
        setOpenModal(false);
        setContentModal({});
        setModalType("");
        setMessageType("error");
    }, []);

    const handleShowAccept = useCallback(
        (accept) => {
            setModalType(accept);
        },
        []
    );

    const handleClearSignature = useCallback(() => {
        refSignature.current.clear();
    }, []);

    const sendAccept = useCallback((id, assinatura) => {
        Api.post("aceite", {
            id,
            assinatura,
        })
            .then((response) => {
                if (response.data.aceite) {
                    handleCloseModal();
                    setMessageType("success");
                    setMessage(translate("documents.messageSuccess"));
                    setOpenMessage(true);
                }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setLoadingModal(false);
            });
    }, [handleCloseModal, translate]);

    const handleSendSignature = useCallback(() => {
        if (refSignature.current.isEmpty()) {
            setMessage(translate("termsOfUse.messages.errorSignature"));
            setOpenMessage(true);
            return;
        }

        setLoadingModal(true);

        const signature = refSignature.current.toDataURL("image/jpeg", 0.5);

        const dataURLtoFile = (dataurl, filename) => {
            var arr = dataurl.split(","),
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]),
                n = bstr.length,
                u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new File([u8arr], filename, { type: mime });
        };

        const file = dataURLtoFile(signature, "signature.jpg");

        setTimeout(() => {
            sendAccept(contentModal.acceptId, file);
        }, 500);
    }, [contentModal, sendAccept, translate]);

    const handleOpenAlert = () => setOpenMessage(!openMessage);

    return {
        transitionIn,
        loadingPage,
        loadingModal,
        openModal,
        documents,
        contentModal,
        modalType,
        message,
        openMessage,
        messageType,
        refSignature,
        handleOpenModal,
        handleCloseModal,
        handleShowAccept,
        handleClearSignature,
        handleSendSignature,
        handleOpenAlert,
        translate,
        Utils,
    };
};

export default useDocuments;
