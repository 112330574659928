import React from "react";
import useForgotPassword from "./useForgotPassword";
import Loading from "components/loading";
import AlertCustom from "components/alertCustom";
import InputPass from "components/form/InputPass";
import ButtonCustom from "components/buttonCustom/ButtonCustom";
import { Container as ContainerUi } from "@material-ui/core";
import LogoImgBig from "../../assets/images/altum.png";
import LogoImg from "../login/images/logo.png";
import {
  Container,
  Header,
  Main,
  Logo,
  Inner,
  GroupField,
  FormPage,
  GroupBtn,
  BoxComment,
  MessageSuccess,
  MessageDanger,
  Title,
} from "./Styled";
const ForgotPassword = () => {
  const {
    hasConfirmedEmail,
    loading,
    error,
    success,
    message,
    isHashValid,
    password,
    confirmPassword,
    handleChangePassword,
    handleChangeConfirmPassword,
    handleSubmit,
    alertSeverity,
    openMessage,
    setOpenMessage,
    transitionIn,
  } = useForgotPassword();

  if (loading) {
    return <Loading />;
  }

  if (!isHashValid) {
    return (
      <Main>
        <Header>
          <Logo src={LogoImgBig} transition={transitionIn} />
        </Header>
        <ContainerUi>
          <MessageDanger>
            <h2>{message}</h2>
          </MessageDanger>
        </ContainerUi>
      </Main>
    );
  }

  if (success) {
    return (
      <Main>
        <Header>
          <Logo src={LogoImgBig} transition={transitionIn} />
        </Header>
        <ContainerUi>
          <MessageSuccess>
            <h2>{message}</h2>
          </MessageSuccess>
        </ContainerUi>
      </Main>
    );
  }

  return (
    <Main>
      <ContainerUi>
        <Inner>
        <Logo src={LogoImg} transition={transitionIn} />
        <Title>Digite sua nova senha</Title>
        <FormPage autoComplete="off">
          <GroupField>
            <InputPass
              fullWidth
              type="password"
    
              name="password"
              value={password}
              onChange={(e) => handleChangePassword(e)}
              placeholder="Digite a nova senha"
              required
              autoComplete="new-password"
              error={error.password ?? false}
            />
          </GroupField>

          <GroupField>
            <InputPass
              fullWidth
              type="password"
            
              name="confirmPassword"
              value={confirmPassword}
              onChange={(e) => handleChangeConfirmPassword(e)}
              placeholder="Confirme a Nova senha"
              required
              autoComplete="new-password"
              error={error.confirmPassword ?? false}
            />
          </GroupField>

          <GroupBtn>
            <ButtonCustom
              color="default"
              type="button"
              size="medium"
              onClick={handleSubmit}
              disabled={
                password === "" ||
                confirmPassword === "" ||
                password !== confirmPassword
              }
              fullWidth
            >
              Enviar
            </ButtonCustom>
          </GroupBtn>
        </FormPage>
        </Inner>
      </ContainerUi>

      <AlertCustom
        message={message}
        openMessage={openMessage}
        closeMessage={() => setOpenMessage(false)}
        transition="down"
        severity={alertSeverity}
        origin={{ vertical: "top", horizontal: "right" }}
      />
    </Main>
  );
};

export default ForgotPassword;
