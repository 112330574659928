import styled from "styled-components";
import { Dialog, DialogTitle } from "@material-ui/core";

import InputText from "components/form/InputText";

import Theme from "Theme";

export const Modal = styled(Dialog)`
    && {
        .MuiPaper-root {
            width: 100%;
            max-width: 600px;
            background-color: rgba(0, 0, 0, 0.95);
            padding-top: 20px;
            padding-bottom: 20px;
            overflow-x: hidden;

            .btn-green {
                max-width: 180px;
                margin: 10px auto 20px;
            }
        }
    }
`;

export const ModalTitle = styled(DialogTitle)`
    && {
        color: ${Theme.colorWhite};
        text-align: center;
    }
`;

export const ModalBody = styled.div`
    width: 100%;
    padding-bottom: 15px;

    form {
        width: 100%;

        label {
            color: ${Theme.colorWhite};
        }
    }
`;

export const GroupField = styled.div`
    width: 100%;
    margin-bottom: 0;
`;

export const Input = styled(InputText)`
    && {
        .MuiInputLabel-shrink,
        .MuiInputLabel-root.Mui-focused {
            color: ${(props) =>
        props.labeldark ? Theme.colorWhite : Theme.colorWhite};
            top: 0;

            &.Mui-error,
            .MuiFormLabel-asterisk.Mui-error {
                color: ${Theme.formText.label.error};
            }
        }
    }
`;
