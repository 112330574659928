import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import ButtonCustom from "components/buttonCustom";
import { DatePicker } from "../DatePicker";
import { useAddCourse } from "./useAddCourse";
import { Icon } from "screens/workWithUs/Styled";

import {
    Title,
    GroupField,
    Input,
    InputFileContainer,
    InputFileText,
} from "./Styled";

export const WorkWithUSAddCourse = (props) => {
    const {
        translate,
        attachCertificateText,
        fieldsModal,
        errorModal,
        handleChangeText,
        handleChangeDate,
        handleAddCourse,
        handleChangeFileCertificate,
        setCourseType,
    } = useAddCourse();

    const [dateOfTheConclusion, setDateOfTheConclusion] = useState(null);

    useEffect(() => {
        setCourseType(props.courseType ?? '');
    });

    return (
        <>
            <Title>{translate("workWithUs.modalAddCourse.title")}</Title>
            <Grid container justifyContent="center">
                <Grid item xs={12} md={10}>
                    <GroupField>
                        <Input
                            fullWidth
                            transparent="true"
                            id="name"
                            name="name"
                            label={translate(
                                "workWithUs.modalAddCourse.form.course"
                            )}
                            autoComplete="off"
                            value={fieldsModal?.name || ""}
                            onChange={(props) =>
                                handleChangeText(props, "courses")
                            }
                            error={errorModal?.name}
                        />
                    </GroupField>
                </Grid>
                <Grid item xs={12} md={10}>
                    <GroupField>
                        <Input
                            fullWidth
                            transparent="true"
                            id="academy"
                            name="academy"
                            label={translate(
                                "workWithUs.modalAddCourse.form.academy"
                            )}
                            autoComplete="off"
                            value={fieldsModal?.academy || ""}
                            onChange={(props) =>
                                handleChangeText(props, "courses")
                            }
                            error={errorModal?.academy}
                        />
                    </GroupField>
                </Grid>
                <Grid item xs={12} md={10}>
                    <GroupField>
                        <DatePicker
                            label={translate(
                                "workWithUs.form.step3.timeCourse"
                            )}
                            value={dateOfTheConclusion}
                            onChange={(newValue) =>
                                handleChangeDate(
                                    newValue,
                                    "dateOfTheConclusion",
                                    setDateOfTheConclusion(newValue)
                                )
                            }
                        />
                    </GroupField>
                </Grid>
                <Grid item xs={12} md={10}>
                    <GroupField>
                        <InputFileContainer>
                            <Icon className="icon icon-document-edit" />

                            <InputFileText>
                                {attachCertificateText}
                            </InputFileText>
                            <input
                                type="file"
                                className="display-none"
                                id="input-certificate-file"
                                onChange={(e) => handleChangeFileCertificate(e)}
                            />
                        </InputFileContainer>
                    </GroupField>
                </Grid>

                <Grid item xs={12} md={10}>
                    <ButtonCustom
                        className="btn-green"
                        onClick={() => handleAddCourse(props.handleSave)}
                    >
                        {translate("workWithUs.save")}
                    </ButtonCustom>
                </Grid>
            </Grid>
        </>
    );
};
