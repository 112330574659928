import styled from "styled-components";
import { IconButton } from "@material-ui/core";

import SliderCusom from "react-slick";

import Theme from "Theme";

import Loading from "./images/loading.svg";

export const Main = styled.main`
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    padding-top: 15px;

    &:before {
        width: 100%;
        height: 100%;
        content: "";
        position: fixed;
        top: 0;
        left: 0;
        background-color: #eeeeee;
    }

    & > div {
        position: relative;
    }
`;

export const ContentPage = styled.div`
    position: relative;
    transition: all 0.3s ease-in-out;
    opacity: ${(props) => (props.loadingForm ? 0.6 : 1)};
    transform: scale(${(props) => (props.loadingForm ? 1.1 : 1)});

    &:before {
        width: 100%;
        height: 100%;
        content: "";
        transition: all 0.3s ease-in-out;
        background-image: url("${Loading}");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 60px;
        position: fixed;
        top: 0;
        left: 0;
        visibility: ${(props) => (props.loadingForm ? "visible" : "hidden")};
        z-index: ${(props) => (props.loadingForm ? 1233434 : -5)};
    }
`;

export const Transition = styled.div`
    transition: all 0.4s ease-in-out;
    opacity: ${(props) => (props.transitionIn ? 1 : 0)};
    transform: translateX(${(props) => (props.transitionIn ? "0" : "30px")});
`;

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 40px 0;
    margin-bottom: 20px;

    @media (max-width: 768px) {
        justify-content: center;
    }
`;

export const Title = styled.h1`
    font-size: 22px;
    font-weight: bold;
    color: ${Theme.colorBlueDark};
    text-align: center;
    transition: all 0.3s ease-in-out;
    opacity: ${(props) => (props.transition ? 1 : 0)};
    transform: translateX(${(props) => (props.transition ? 0 : "-30px")});

    @media (max-width: 768px) {
        order: 1;
    }
`;

export const Logo = styled.img`
    margin: 0;
    transition: all 0.3s ease-in-out;
    opacity: ${(props) => (props.transition ? 1 : 0)};
    transform: translateX(${(props) => (props.transition ? 0 : "30px")});

    @media (max-width: 768px) {
        max-width: 160px;
        margin-bottom: 20px;
    }
`;

export const Slider = styled(SliderCusom)`
    width: 100%;
    margin: auto;
    position: relative;

    .slick-list {
        width: 100%;
        position: relative;
        overflow: hidden;

        .slick-track {
            display: flex;

            .slick-slide {
                transition: opacity 1.5s ease;
                opacity: 0;
                visibility: hidden;

                & > div {
                    width: 100%;
                    position: relative;
                }

                &.slick-active {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }

    .slick-disabled {
        opacity: 0.2;
        background: ${Theme.colorGray};

        &:hover {
            background: ${Theme.colorGray};
        }
    }

    .slick-prev,
    .slick-next {
        min-width: 30px;
        min-height: 30px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        padding: 0;
        z-index: 5;

        span {
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .slick-prev {
        left: -40px;

        @media (max-width: 768px) {
            left: -20px;
        }
    }

    .slick-next {
        right: -40px;

        @media (max-width: 768px) {
            right: -20px;
        }

        span {
            transform: rotate(90deg);
        }
    }

    .slick-dots {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;

        li {
            display: inline-block;
            margin: 5px;

            button {
                width: 15px;
                height: 15px;
                border-radius: 50%;
                overflow: hidden;
                text-indent: -3000px;
                background-color: ${Theme.colorGray};
            }

            &.slick-active {
                button {
                    background-color: ${Theme.colorBlueDark};
                }
            }
        }
    }
`;

export const ContainerVideo = styled.div`
    height: 0;
    background-color: #000;
    padding-bottom: 56.25%;
    transition: all 0.4s ease-in-out;
    margin-bottom: 10px;
    position: relative;

    video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .video-react-big-play-button {
        visibility: hidden;
        display: none;
    }

    .video-react-control-bar {
        visibility: hidden;
        display: none;
    }

    .play-video-custom {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 1%;
        left: 1%;

        &:hover {
            background: transparent;
        }

        .icon-video {
            font-size: 40px;
            color: ${Theme.colorBlueDark};
        }
    }
`;

export const GroupBtns = styled.div`
    width: 100%;
    padding: 20px 0;
    transition: all 0.4s ease-in-out;
    opacity: ${(props) => (props.transition ? 1 : 0)};

    button {
        margin-bottom: 5px;

        .MuiButton-label {
            line-height: 1;
            text-align: left;
        }
    }
`;

export const Icon = styled.span`
    && {
        font-size: 22px;
        font-weight: normal;
        color: ${Theme.colorBlueDark};
    }
`;

export const GroupBtnInner = styled.div`
    && {
        width: 100%;

        a {
            margin: 15px auto 30px;
        }
    }
`;

export const ContainerModal = styled.div`
    && {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;

export const ContentModal = styled.div`
    && {
        width: 100%;
        max-width: 840px;
        background-color: ${Theme.colorWhite};
        border-radius: 10px;
        position: relative;
        transition: all 0.3s ease-in-out;
        transform: scale(${(props) => (props.loadingModal ? 0.93 : 1)});
        overflow: hidden;

        @media (max-width: 768px) {
            width: 95%;
        }

        &:before {
            width: 100%;
            height: 100%;
            content: "";
            background-color: rgba(255, 255, 255, 0.6);
            background-image: url("${Loading}");
            background-position: center;
            background-repeat: no-repeat;
            border-radius: 10px;
            background-size: 60px;
            position: absolute;
            top: 0;
            left: 0;
            transition: all 0.3s ease-in-out;
            opacity: ${(props) => (props.loadingModal ? 1 : 0)};
            z-index: ${(props) => (props.loadingModal ? 5 : -1)};
        }
    }
`;

export const HeaderModal = styled.div`
    && {
        width: 100%;
        position: relative;
    }
`;

export const BtnCloseModal = styled(IconButton)`
    && {
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 1;

        .icon-close {
            font-size: 12px;
        }
    }
`;

export const ScrollY = styled.div`
    && {
        width: calc(100% - 20px);
        min-height: 150px;
        max-height: 60vh;
        overflow-x: hidden;
        overflow-y: auto;
        margin: 0;
        padding: 10px 20px;
    }
`;

export const BodyModal = styled.div`
    && {
        width: 100%;
    }
`;

export const BoxInfo = styled.div`
    && {
        width: 100%;
        padding: 20px 0;
    }
`;

export const BoxSignature = styled.div`
    && {
        width: calc(100% - 60px);
        height: 300px;
        position: relative;
        margin: auto;

        &:before {
            width: calc(100% - 40px);
            height: 1px;
            content: "";
            border-bottom: 3px dashed ${Theme.colorGray};
            position: absolute;
            top: 60%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
`;

export const TitleModal = styled.h3`
    && {
        color: ${Theme.colorBlack};
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 30px;
    }
`;

export const TextModal = styled.p`
    && {
        color: ${Theme.colorBlack};
        font-size: 14px;
        line-height: 1.5;
    }
`;

export const FooterModal = styled.div`
    && {
        width: 100%;
        min-height: 120px;
        padding: 20px;
        background-color: ${Theme.colorGrayLight};
        text-align: center;

        p {
            line-height: 1.4;
            font-size: 12px;
        }
    }
`;

export const BtnGroups = styled.div`
    && {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 20px;

        button {
            max-width: 200px;
            margin: 0 5px;
        }
    }
`;

export const BtnDownload = styled.a`
    & {
        width: 100%;
        padding: 15px;
        color: #fff;
        font-weight: 600;
        text-transform: uppercase;
        color: ${Theme.colorBlueDark};
        background-color: transparent;
        border: 1px solid ${Theme.colorBlueDark};
        border-radius: 6px;
        transition: all 0.6s linear;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        margin: 20px auto;

        @media (max-width: 340px) {
            max-width: 270px;
        }

        .icon-download {
            margin-right: 10px;
            font-size: 14px;
            color: ${Theme.colorBlueDark};
            transition: all 0.6s linear;
        }

        &:hover {
            color: ${Theme.colorWhite};
            background-color: ${Theme.colorBlueDark};

            .icon-download {
                color: ${Theme.colorWhite};
            }
        }
    }
`;
