import React from "react";
import { Container } from "@material-ui/core";
import useChangeEmail from "./useChangeEmail";
//import Loading from "components/loading";
import AlertCustom from "components/alertCustom";
import InputPass from "components/form/InputPass";
import InputText from "components/form/InputText";
import ButtonCustom from "components/buttonCustom";
import Loading from "components/loading/Loading";

import {
  Main,
  Inner,
  Logo,
  FormPage,
  AnimeForm,
  GroupField,
  GroupBtn,
  B,
  LinkGoBack,
} from "./Styled";

//import LogoImg from "./images/logo.png";

const ChangeEmail = () => {
  const {
    newEmail,
    password,
    handleEmailChange,
    handlePasswordChange,
    handleSubmit,
    passwordError,
    newEmailError,
    transitionIn,
    loadingPage,
    loadingForm,
    buttonDisabled,
    message,
    translate,
    openMessage,
    setOpenMessage,
    goLink,
  } = useChangeEmail();

  if (loadingPage) {
    return <Loading />;
  }

  return (
    <React.Fragment>
      <Main>
        <Container>
          <Inner>
            <FormPage
              novalidate
              method="post"
              autoComplete="off"
              loadingForm={loadingForm}
              onSubmit={handleSubmit}
            >
              <AnimeForm transition={transitionIn}>
                <GroupField>
                  <B>Alterar E-mail</B>
                </GroupField>

                <GroupField>
                  <InputText
                    fullWidth
                    id="newEmail"
                    name="newEmail"
                    label={translate(
                      "updateEmail.form.inputChangeEmail.newEmailLabel"
                    )}
                    autoComplete="newEmail"
                    value={newEmail}
                    error={newEmailError}
                    onChange={handleEmailChange}
                  />
                </GroupField>
                <GroupBtn>
                  <ButtonCustom
                    color="default"
                    type="submit"
                    size="medium"
                    disabled={buttonDisabled}
                    fullWidth
                  >
                    {translate("updateEmail.form.btnSaveEmail")}
                  </ButtonCustom>
                </GroupBtn>
              </AnimeForm>
            </FormPage>
            <LinkGoBack onClick={() => goLink("/")}>Voltar</LinkGoBack>
          </Inner>
        </Container>
      </Main>
      <AlertCustom
        message={message}
        openMessage={openMessage}
        closeMessage={() => setOpenMessage(false)}
        transition="down"
        origin={{ vertical: "top", horizontal: "right" }}
      />
    </React.Fragment>
  );
};

export default ChangeEmail;
