import React from "react";
import { withRouter, Switch, Route, Redirect } from "react-router-dom";
import { Utils } from "utils";

// Páginas
import TermsOfUse from "screens/termsOfUse";
import Logout from "screens/logout";

const PrivateTermsOfUse = () => (
    <React.Fragment>
        <Switch>
            <Route
                exact
                path={Utils.setPrefix("/termos-de-uso")}
                component={TermsOfUse}
            />
            <Route
                exact
                path={Utils.setPrefix("/termos-de-uso/logout")}
                component={Logout}
            />
            <Redirect to={Utils.setPrefix("/termos-de-uso")} />
        </Switch>
    </React.Fragment>
);

export default withRouter(PrivateTermsOfUse);
