import styled from "styled-components";
import Theme from "Theme";
import Form from "components/form";
import InputText from "components/form/InputText";
import InputUpload from "components/form/InputUpload";
import PreviewImage from "components/form/PreviewImage";
import ButtonCustom from "../../components/buttonCustom";
import { Utils } from "utils";

export const Main = styled.main`
  display: flex;
  justify-content: center;
  margin-top: 160px;
  position: relative;
  overflow-x: hidden;
  &:before {
    width: 100%;
    height: 100%;
    content: "";
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: ${Theme.colorLightBgCommunication};
  }

  & > div {
    position: relative;
  }
`;

export const Container = styled.div`
  width: 50%;

  @media (max-width: 390px) {
    width: 100%;
    padding: 0 8px 0 8px;
  }
`;

export const Title = styled.h1`
  font-size: 22px;
  font-weight: bold;
  color: ${Theme.colorBlack};
  text-align: center;
  transition: all 0.3s ease-in-out;
  opacity: ${(props) => (props.transition ? 1 : 0)};
  transform: translateX(${(props) => (props.transition ? 0 : "-30px")});
  margin: 20px auto 30px;
`;

export const TitleIcon = styled.img`
  max-width: 50px;
  height: auto;
  margin: auto auto 15px;
  display: table;
`;

export const Icon = styled.span`
  && {
    font-size: 22px;
    font-weight: normal;
    color: ${(props) => props.color ?? Theme.colorWhite};
  }
`;

export const BtnGroup = styled.div`
  && {
    transition: all 0.3s ease-in-out;
    opacity: ${(props) => (props.transition ? 1 : 0)};
    transform: translateX(${(props) => (props.transition ? 0 : "-30px")});
    margin: 40px 0 15px;
  }
`;

export const HeaderMessage = styled.div`
  && {
    transition: all 0.6s ease-in-out;
    opacity: ${(props) => (props.transition ? 1 : 0)};
    width: 100%;
    padding-bottom: 15px;
    border-bottom: 1px solid ${Theme.colorDarkGray};
  }
`;

export const Status = styled.span`
  border-radius: 6.5px;
  display: table;
  background-color: ${(props) => Utils.statusComunication(props.type)};
  padding: 5px 10px;
  color: ${(props) =>
    props.type === "EM_ANALISE" ? Theme.colorBlack : Theme.colorWhite};
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 10px;
`;

export const Text = styled.p`
  && {
    margin-top: ${(props) => (props.noContent !== undefined ? "30px" : "0")};
    font-size: ${(props) => (props.noContent !== undefined ? "18px" : "14px")};
    font-weight: ${(props) => (props.bold !== undefined ? props.bold : "400")};
    text-align: ${(props) => (props.center !== undefined ? "center" : "left")};
    color: ${Theme.colorBlack};
    line-height: 1.6;
    display: block;
    white-space: pre-line;
  }
`;

export const Data = styled.span`
  && {
    font-size: 12px;
    font-weight: 400;
    color: ${Theme.colorGray};
    line-height: 1.6;
    display: block;
    position: absolute;
    right: 10px;
    margin-top: 20px;
  }
`;

export const GroupMessages = styled.div`
  && {
    display: flex;
    justify-content: center;
    padding: 0 30px 0 30px;
    width: 100%;
    margin-top: 35px;
    display: table;
  }
`;

export const ListPhotos = styled.div`
  && {
    width: 100%;
    clear: both;
    margin: 30px -15px;
    transition: all 0.6s ease-in-out;
    opacity: ${(props) => (props.transition ? 1 : 0)};
    transform: translateY(${(props) => (props.transition ? 0 : "30px")});
  }
`;

export const ListPhotoItem = styled.div`
  && {
    display: inline-block;
    position: relative;
    margin: 15px 15px 35px;
  }
`;

export const Photo = styled.img`
  && {
    width: 100%;
    max-width: 320px;
    min-height: 280px;
    display: inline-block;
    border-radius: 5px;
    border: 8px solid ${Theme.colorBorderImgMessage};
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);

    @media (max-width: 390px) {
      max-width: 260px;
    }
  }
`;

export const ListMessage = styled.div`
  && {
    max-width: calc(100% - 480px);
    min-width: 340px;
    transition: all 0.4s ease-in-out;
    opacity: ${(props) => (props.transition ? 1 : 0)};
    transform: translateY(${(props) => (props.transition ? 0 : "30px")});
    padding: 15px 10px;
    background-color: ${(props) =>
      props.me ? Theme.colorWhite : Theme.colorMessage};
    float: ${(props) => (props.me ? "right" : "left")};
    clear: both;

    border-radius: 6px;
    margin-bottom: 50px;
    position: relative;

    @media (max-width: 400px) {
      min-width: 260px;
      max-width: 300px;
    }

    p {
      color: ${(props) => (props.me ? Theme.colorBlack : Theme.colorWhite)};
      line-height: 1.4;
      word-break: normal;
    }
  }
`;

export const ButtomLinkDownload = styled.a`
  && {
    width: 100%;
    height: 45px;
    color: ${Theme.colorWhite};
    padding-top: 8px;
    text-align: center;
    font-weight: 600;
    text-transform: none;
    background-color: ${Theme.buttonMain.default[0]};
    background-image: linear-gradient(
      to right,
      ${Theme.buttonMain.default[0]},
      ${Theme.buttonMain.default[1]}
    );
    border: 0;
    border-radius: 6px;
    transition: all 0.6s linear;
    text-decoration: none;
    &:hover {
      background-color: ${Theme.buttonMain.hover[0]};
      background-image: linear-gradient(
        to right,
        ${Theme.buttonMain.hover[0]},
        ${Theme.buttonMain.hover[1]}
      );
    }

    &.btn-green {
      background-color: ${Theme.buttonGreen.default[0]};
      background-image: linear-gradient(
        to right,
        ${Theme.buttonGreen.default[0]},
        ${Theme.buttonGreen.default[1]}
      );

      &:hover {
        background-color: ${Theme.buttonGreen.hover[0]};
        background-image: linear-gradient(
          to right,
          ${Theme.buttonGreen.hover[0]},
          ${Theme.buttonGreen.hover[1]}
        );
      }
    }
  }
`;

export const ButtomDowload = styled.div`
  && {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 15px;
    padding-top: 10px;
  }
`;

export const ButtomFooter = styled.div`
  && {
    margin: 40px 0 80px;
    display: flex;
    justify-content: center;

    .btn-green {
      max-width: 250px;
      margin: auto;
      transition: all 0.3s ease-in-out;
      opacity: ${(props) => (props.transition ? 1 : 0)};
      transform: translateX(${(props) => (props.transition ? 0 : "-30px")});
    }
  }
`;

export const NewMessageContainer = styled.div`
  && {
    width: 100%;
    margin: 2px 0 15px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
`;

export const NewMessageField = styled(InputText)`
  && {
    width: 90%;
    .MuiInputLabel-shrink,
    .MuiInputLabel-root.Mui-focused {
      color: ${(props) =>
        props.labeldark ? Theme.colorBlack : Theme.colorBlack};
      top: 0;

      &.Mui-error,
      .MuiFormLabel-asterisk.Mui-error {
        color: ${Theme.formText.label.error};
      }
    }
  }
`;

export const NewMessageBtnAttachment = styled(InputUpload)`
  && {
    width: 30px;
    height: 55px;
    background-color: blue;
    margin-left: 2px;
    margin-top: 18px;
  }
`;

export const NewMessageBtnSend = styled(ButtonCustom)`
  && {
    width: 30px;
    height: 55px;
    background-color: green;
    margin-left: 2px;
    margin-top: 18px;
  }
`;

export const GroupField = styled.div`
  width: 100%;
  margin-bottom: 10px;
`;

export const ListPreviewFotos = styled.div`
  && {
    width: 50%;
    margin-left: 180px;
    overflow-y: auto;
    @media (max-width: 400px) {
      margin-left: 10px;
      width: 95%;
    }
  }
`;

export const PreviewFoto = styled(PreviewImage)``;

export const FormPage = styled(Form)`
  width: 100%;
  margin-bottom: 80px;
`;

export const MessageAnexo = styled.p`
  color: red;
  margin-top: 10px;
`;
