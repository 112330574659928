import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuth } from "contexts/Auth.contexts";
import { Utils } from "utils";
import Api from "service/api";

const useUpdatePassword = () => {
  const {
    contractAccepted,
    setUpdatePassword,
    profile,
    setIsPendingEmailConfirmation,
    isPendingEmailConfirmation,
    isAuth,
  } = useAuth();
  const [loadingPage, setLoadingPage] = useState(true);
  const [transitionIn, setTransitionIn] = useState(false);
  const [loadingForm, setLoadingForm] = useState(false);
  const [openMessage, setOpenMessage] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [email, setEmail] = useState(profile.pess_email_recuperacao ?? "");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [oldPasswordError, setOldPasswordError] = useState(false);
  const [newPasswordError, setNewPasswordError] = useState(false);
  const [repeatPasswordError, setRepeatPasswordError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [message, setMessage] = useState("");
  const history = useHistory();
  const { t: translate } = useTranslation();

  document.title = translate("updatePassword.head.title");

  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      setLoadingPage(false);
    }, 600);

    setTimeout(() => {
      setTransitionIn(true);
    }, 800);
  }, [contractAccepted, isPendingEmailConfirmation]);

  const onButtonDisabled = (
    oldPassword,
    newPassword,
    repeatPassword,
    email
  ) => {
    setButtonDisabled(false);
    if (
      oldPassword.length < 6 ||
      newPassword.length < 6 ||
      repeatPassword.length < 6 ||
      email.length < 6 ||
      !validateEmail(email) ||
      newPassword !== repeatPassword
    ) {
      setButtonDisabled(true);
    }
  };

  const onChangeOldPassword = (event) => {
    const value = event.target.value;
    setOldPassword(value);
    setOldPasswordError(false);
    onButtonDisabled(value, newPassword, repeatPassword, email);
    if (value.length < 6) {
      setOldPasswordError(true);
    }
  };

  const onChangeNewPassword = (event) => {
    const value = event.target.value;
    setNewPassword(value);
    setNewPasswordError(false);
    onButtonDisabled(oldPassword, value, repeatPassword, email);
    if (value.length < 6) {
      setNewPasswordError(true);
    }
  };

  const onChangeRepeatPassword = (event) => {
    const value = event.target.value;
    setRepeatPassword(value);
    setRepeatPasswordError(false);
    onButtonDisabled(oldPassword, newPassword, value, email);
    if (value.length < 6) {
      setRepeatPasswordError(true);
    }
  };

  const onChangeEmail = (event) => {
    const value = event.target.value;
    setEmail(value);
    onButtonDisabled(oldPassword, newPassword, repeatPassword, value);
  };

  function validateEmail(emailString) {
    const regex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
    const isValid = regex.test(emailString);
    setEmailError(!isValid);
    return isValid;
  }

  const onSubmitNewPasword = async (event) => {
    event.preventDefault();
    setLoadingForm(true);

    const randomPass = Math.random().toString(36).substring(7);
    const pass = `${randomPass}-${newPassword}`;
    localStorage.setItem("ead_pass", pass);

    await Api.post("usuario/alterar-senha", {
      senha_antiga: oldPassword,
      senha_nova: newPassword,
      senha_confirmacao: repeatPassword,
      email: email,
    })
      .then((response) => {
        const { status } = response;
        if (status === 200) {
          setUpdatePassword(false);
          setIsPendingEmailConfirmation(false);

          if (contractAccepted) {
            (async () => {
              await isAuth();
              history.push(Utils.setPrefix("/pending-email-confirmation"));
            })();
            return;
          }
          history.push(Utils.setPrefix("/termos-de-uso"));
        }
      })
      .catch((error) => {
        setLoadingForm(false);
        setOpenMessage(true);
        if (error.response && error.response.status === 400) {
          const errorMessage = JSON.parse(error.request.response);
          setMessage(errorMessage.data.message);
        } else {
          setMessage(translate("login.form.msg.loginInvalid"));
        }
      });
  };

  return {
    transitionIn,
    loadingPage,
    loadingForm,
    openMessage,
    oldPassword,
    newPassword,
    repeatPassword,
    email,
    oldPasswordError,
    newPasswordError,
    repeatPasswordError,
    emailError,
    buttonDisabled,
    message,
    setOpenMessage,
    onChangeOldPassword,
    onChangeNewPassword,
    onChangeRepeatPassword,
    onChangeEmail,
    onSubmitNewPasword,
    translate,
  };
};

export default useUpdatePassword;
