import { useEffect, useState } from "react";
import Api from "service/api";
import { set } from "store";

const useForgotPassword = () => {
  const [hasConfirmedEmail, setHasConfirmedEmail] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isHashValid, setIsHashValid] = useState(true);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [hashRecovery, setHashRecovery] = useState("");
  const [openMessage, setOpenMessage] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState(false);
  const [transitionIn, setTransitionIn] = useState(false);
  const [message, setMessage] = useState("Senha Alterada com sucesso");

  const handleChangePassword = (event) => {
    setPassword(event.target.value);
  };

  const handleChangeConfirmPassword = (event) => {
    setConfirmPassword(event.target.value);
  };

  const verifyPassword = () => {
    if (password !== confirmPassword) {
      setError(true);
      setMessage("As senhas não conferem");
      return false;
    }
  };

  const verificationHash = async (hash) => {
    try {
      const response = await Api.post(`/grupo-altum/verify-hash/${hash}`);
      if (response.status === 200) {
        setIsHashValid(response.data.success);
        if (!response.data.success) {
          setMessage(response.data.message);
          setSuccess(true);
        }
      }
    } catch (error) {
      setIsHashValid(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (verifyPassword() === false) {
      return;
    }

    setLoading(true);
    setError(false);
    try {
      const response = await Api.post(
        `/grupo-altum/change-password/${hashRecovery}`,
        {
          senha_nova: password,
          senha_confirmacao: confirmPassword,
        }
      );

      if (response.status === 200) {
        setSuccess(true);
        setMessage("Senha alterada com sucesso");
      }
    } catch (error) {
      setError(true);
      setMessage("Erro ao alterar senha");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const verify = params.get("verify");
    setHashRecovery(verify);
    (async () => {
      await verificationHash(verify);
      setLoading(false);
    })();
    setTimeout(() => {
      setTransitionIn(true);
    }, 800);
  }, []);

  return {
    hasConfirmedEmail,
    loading,
    error,
    success,
    message,
    password,
    confirmPassword,
    handleChangePassword,
    handleChangeConfirmPassword,
    verifyPassword,
    handleSubmit,
    alertSeverity,
    message,
    isHashValid,
    openMessage,
    setOpenMessage,
    transitionIn,
  };
};

export default useForgotPassword;
