import styled from "styled-components";
import { Dialog, DialogTitle } from "@material-ui/core";
import { Input } from "../../../Styled";

export const Modal = styled(Dialog)`
    && {
        .MuiPaper-root {
            width: 100%;
            max-width: 600px;
            background-color: #fff;
            padding-top: 20px;
            padding-bottom: 20px;

            .btn-green {
                max-width: 180px;
                margin: 10px auto 20px;
            }
        }
    }
`;

export const ModalTitle = styled(DialogTitle)`
    && {
        color: #222222;
        text-align: center;
    }
`;

export const ModalBody = styled.div`
    width: 100%;
    padding: 16px 32px;
    text-align: left;
    line-height: 24px;
`;


export const PcdCidField = styled(Input)`
    && {
       display: ${props => props.isVisible? 'block' : 'none'};
    }
`;