import React, { memo } from "react";
import { PreviewFt, IconRemove } from "./Styled";

const PreviewImage = (props) => (
    
    <PreviewFt onClick={props.onClick}>
        {/*<img alt={props.alt||'img'} src={props.src} />*/}
        <span>{props.fileName}</span>
        <IconRemove className="icon icon-close" />
    </PreviewFt>

);

export default memo(PreviewImage);
