import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "contexts/Auth.contexts";

const useSelectLang = () => {
    const { setLoadingPage, setMessage } = useAuth();
    const { i18n, t: translate } = useTranslation();

    const options = [
        { value: "pt-BR", label: translate("selectLang.ptBR") },
        { value: "en", label: translate("selectLang.en") },
        { value: "es", label: translate("selectLang.es") },
    ];

    const langDefault =
        window.localStorage.i18nextLng !== undefined
            ? window.localStorage.i18nextLng
            : "pt-BR";
    const [lang, setLang] = useState(
        options.find((item) => item.value === langDefault)
    );

    const handleChange = (event) => {
        const option = event.target.value;

        setLoadingPage(true);
        setMessage("");
        setLang(option);
        i18n.changeLanguage(option);
        window.localStorage.setItem("i18nextLng", option);

        setTimeout(() => {
            setLoadingPage(false);
        }, 500);
    };

    return {
        options,
        lang,
        handleChange,
    };
};

export default useSelectLang;
