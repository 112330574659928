import React from "react";
import Loading from "components/loading";
import { Main, Header, Logo, Mensagem, Container, Button } from "./Styled";
import LogoImg from "../../assets/images/altum.png";
import useEmailConfirmation from "./useEmailConfirmation";
const EmailConfirmation = () => {
  const { message, handleLogin, loading } = useEmailConfirmation();

  if (loading) {
    return <Loading />;
  }

  return (
    <Container>
      <Header>
        <Logo src={LogoImg} />
      </Header>
      <Main>
        <Mensagem>
          <h1>{message}</h1>
        </Mensagem>
        <Button onClick={handleLogin}>
          <span>Voltar para o login</span>
        </Button>
      </Main>
    </Container>
  );
};

export default EmailConfirmation;
