import React, { memo, useCallback } from "react";
import { InputText, InputCheckboxCustom } from "../form";
import ButtonCustom from "components/buttonCustom";

import useModalSelectOptions from "./useModalSelectOptions";

import { Modal, ModalTitle, GroupSearch, List, ListItem } from "./Styled";

const ModalSelectOptions = ({ search, onSearch, optionName, data, action }) => {
    const {
        translate,
        openModal,
        handleCloseModal,
        handleChange,
        getValuesCheckeds,
    } = useModalSelectOptions();

    const handleSaveModal = useCallback(() => {
        handleCloseModal();

        if (action && action.fn) {
            action.fn();
        }
    }, [action, handleCloseModal]);

    return (
        <Modal
            onClose={handleCloseModal}
            open={openModal?.name === "ModalSelectOptions"}
        >
            <ModalTitle>
                {translate("workWithUs.modalSelectOptions.title")}
            </ModalTitle>
            <GroupSearch>
                <InputText
                    fullWidth
                    transparent="true"
                    id="name"
                    name="name"
                    label={"Buscar..."}
                    autoComplete="off"
                    value={search}
                    onChange={onSearch}
                />
            </GroupSearch>
            <List>
                {data.map((item, index) => (
                    <ListItem key={index}>
                        <InputCheckboxCustom
                            name={optionName}
                            label={item.label}
                            value={item.value}
                            onChange={handleChange}
                            checked={
                                getValuesCheckeds(optionName, item.value) ||
                                false
                            }
                        />
                    </ListItem>
                ))}
            </List>
            <ButtonCustom className="btn-green" onClick={handleSaveModal}>
                {translate(`workWithUs.${action?.name || "save"}`)}
            </ButtonCustom>
        </Modal>
    );
};

export default memo(ModalSelectOptions);
