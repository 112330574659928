import React from "react";
import usePasswordRecovery from "./usePasswordRecovery";
import { InputMaskCpf, InputMaskCellphone,InputSelect } from "components/form";
import Loading from "components/loading";
import AlertCustom from "components/alertCustom";
import InputText from "components/form/InputText";
import ButtonCustom from "components/buttonCustom/ButtonCustom";
import LogoImg from "../../assets/images/altum.png";

import {
  Container,
  Header,
  Main,
  Logo,
  GroupField,
  FormPage,
  GroupBtn,
  BoxComment,
  MessageSuccess,
  Title,
} from "./Styled";

const PasswordRecovery = () => {
  const {
    name,
    onChangeName,
    email,
    onChangeEmail,
    cpf,
    onChangeCpf,
    whatsApp,
    onChangeWhatsApp,
    comentario,
    onChangeComentario,
    matricula,
    onChangeMatricula,
    empresa,
    onChangeEmpresa,
    loading,
    handleSubmitPasswordRecovery,
    buttonDisabled,
    showMessageSuccess,
    setOpenMessage,
    openMessage,
    alertSeverity,
    message,
    error,
    listEmpresas,
  } = usePasswordRecovery();

  if (loading) {
    return <Loading />;
  }

  if (showMessageSuccess) {
    return (
      <Container>
        <Header>
          <Logo src={LogoImg} />
        </Header>
        <Main>
          <FormPage>
            <GroupField>
              <MessageSuccess>
                <h2>Solicitação Enviada com sucesso.</h2>
                <p>
                  Vamos analisar seus dados. Entraremos em contato em breve
                  através do seu E-mail ou WhatsApp
                </p>
              </MessageSuccess>
            </GroupField>
          </FormPage>
        </Main>
      </Container>
    );
  }

  return (
    <Container>
      <Header>
        <Logo src={LogoImg} />
      </Header>
      <Main>
        <Title>Recuperação de senha, preencha todos os campos com (*).</Title>
        <FormPage>

        <GroupField>
            <InputText
              fullWidth
              type="text"
              label="CPF"
              name="cpf"
              onChange={(e) => onChangeCpf(e)}
              value={cpf}
              placeholder="Digite seu cpf"
              InputProps={{
                inputComponent: InputMaskCpf,
              }}
              required
              error={error.cpf ?? false}
            />
          </GroupField>

          
          <GroupField>
            <InputText
              fullWidth
              type="text"
              label="Seu nome completo"
              name="name"
              onChange={(e) => onChangeName(e)}
              value={name}
              placeholder="Digite seu nome completo"
              required
              labeldark={"true"}
              error={error.name ?? false}
            />
          </GroupField>

          <GroupField>
            <InputText
              fullWidth
              type="text"
              label="WhatsApp"
              name="whatsApp"
              onChange={(e) => onChangeWhatsApp(e)}
              value={whatsApp}
              placeholder="Digite seu WhatsApp"
              InputProps={{
                inputComponent: InputMaskCellphone,
              }}
              labeldark={"false"}
              error={error.whatsApp ?? false}
              required
            />
          </GroupField>
          <GroupField>
            <InputText
              fullWidth
              type="email"
              label="E-mail"
              name="email"
              value={email}
              onChange={(e) => onChangeEmail(e)}
              placeholder="Digite seu e-mail"
              required
              error={error.email ?? false}
            />
          </GroupField>
          <GroupField>
            <InputText
              fullWidth
              type="matricula"
              label="Matrícula/Registro"
              name="matricula"
              value={matricula}
              onChange={(e) => onChangeMatricula(e)}
              placeholder="Digite sua matrícula/registro"
              required
              error={error.matricula ?? false}
            />
          </GroupField>

          <GroupField>
            <InputSelect
              fullWidth
              labeldark={"false"}
              transparent="false"
              label="Empresa"
              name="empresa"
              value={empresa}
              onChange={(e) => onChangeEmpresa(e)}
              options={listEmpresas}
              required
              error={error.empresa ?? false}
            />
          </GroupField>

          <GroupField>
            <BoxComment
              label="Comentário"
              name="comentario"
              value={comentario}
              onChange={(e) => onChangeComentario(e)}
              placeholder="Escreva alguma observação adcional"
              multiline
              rows={4}
              fullWidth
            />
          </GroupField>
          <GroupBtn>
            <ButtonCustom
              color="default"
              type="button"
              size="medium"
              onClick={handleSubmitPasswordRecovery}
              disabled={buttonDisabled}
              fullWidth
            >
              Enviar
            </ButtonCustom>
          </GroupBtn>
        </FormPage>
      </Main>

      <AlertCustom
        message={message}
        openMessage={openMessage}
        closeMessage={() => setOpenMessage(false)}
        transition="down"
        severity={alertSeverity}
        origin={{ vertical: "top", horizontal: "right" }}
      />
    </Container>
  );
};

export default PasswordRecovery;
