import React, { memo } from "react";
import { Radio } from "@material-ui/core";
import { GroupRadios, LabelRadio } from "./Styled";

const InputLabelRadio = (props, valueChecked) => (
    <GroupRadios>
        {props.label && <label htmlFor={props.name}>{props.label}</label>}
        {props.options.length > 0 &&
            props.options.map((item, index) => (
                <LabelRadio
                    key={index}
                    value={item.value}
                    defaultValue={props.defaultValue}
                    inputRef={props.inputRef}
                    control={<Radio />}
                    label={item.label}
                    onChange={props.onChange}
                    checked={props.checked === item.value}
                />
            ))}
    </GroupRadios>
);

export default memo(InputLabelRadio);
