import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Utils } from "utils";

const useRegistrationCompleted = () => {
    const [loadingPage, setLoadingPage] = useState(true);
    const [transitionIn, setTransitionIn] = useState(false);

    const history = useHistory();
    const { t: translate } = useTranslation();

    document.title = translate("workWithUs.head.registrationCompleted.title");

    useEffect(() => {
        window.scrollTo(0, 0);
        setLoadingPage(false);
        setTimeout(() => {
            setTransitionIn(true);
        }, 100);
    }, []);


    const hadleGoLink = (link) => {
        history.push(Utils.setPrefix(`${link}`));
    };

    return {
        transitionIn,
        loadingPage,
        translate,
        hadleGoLink,
    };
};

export default useRegistrationCompleted;
