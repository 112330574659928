import React from "react";
import { Container, Grid } from "@material-ui/core";
import Loading from "components/loading";
import ButtonCustom from "components/buttonCustom";
import Form from "components/form";
import ModalAddCourse from "components/modalAddCourse";
import ModalProfessionExperience from "components/modalProfessionExperience";
import AlertCustom from "components/alertCustom";
import AllSteps from "./allSteps";
import useRegister from "./useRegister";

import {
  Main,
  HeaderPage,
  Title,
  BtnGroupGoBack,
  BtnGroup,
  Icon,
  Stepper,
  StepConnector,
  Step,
  StepLabel,
  StepContent,
} from "../Styled";

const Register = () => {
  const {
    translate,
    transitionIn,
    loadingPage,
    steps,
    activeStep,
    completed,
    openModal,
    openAlert,
    loading,
    handleCloseAlert,
    hadleGoback,
    handleNext,
    handleBack,
    handleOnRegister,
  } = useRegister();

  if (loadingPage) {
    return <Loading />;
  }

  return (
    <React.Fragment>
      {loading && (
        <Loading info={translate("workWithUs.registrationCompleted.loading")} />
      )}
      <Main className={openModal?.name ? "open-modal" : ""}>
        <HeaderPage>
          <BtnGroupGoBack transition={transitionIn}>
            <ButtonCustom
              color="default"
              type="button"
              size="large"
              className="btn-goback"
              onClick={activeStep === 0 ? hadleGoback : handleBack}
              fullWidth
            >
              <Icon className="icon icon-arrow" />
            </ButtonCustom>
          </BtnGroupGoBack>

          <Title transition={transitionIn}>
            {translate("workWithUs.title")}
          </Title>
        </HeaderPage>

        <Container>
          <Grid container className="justify-content-center">
            <Grid item xs={11} md={10} lg={10} xl={10}>
              <Grid container className="justify-content-center">
                <Grid item xs={11} md={10} lg={8} xl={8}>
                  <Form enctype="multipart/form-data">
                    <Stepper
                      nonLinear
                      activeStep={activeStep}
                      connector={<StepConnector />}
                    >
                      {steps.map((item, index) => (
                        <Step key={index} completed={!!completed[index]}>
                          <StepLabel />
                        </Step>
                      ))}
                    </Stepper>
                    <StepContent>
                      <AllSteps id={activeStep} />
                    </StepContent>
                  </Form>
                </Grid>
              </Grid>

              <Grid container className="justify-content-center">
                <Grid item xs={12} md={6} lg={3} xl={3}>
                  <BtnGroup transition={transitionIn}>
                    <ButtonCustom
                      fullWidth
                      color="default"
                      type="button"
                      size="large"
                      id="button-advance-step"
                      className={activeStep !== 5 ? "btn-yellow" : "btn-green"}
                      onClick={activeStep !== 5 ? handleNext : handleOnRegister}
                    >
                      {activeStep !== 5
                        ? translate("workWithUs.advance")
                        : translate("workWithUs.finish")}
                    </ButtonCustom>
                  </BtnGroup>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Main>
      <ModalAddCourse />
      <ModalProfessionExperience />
      <AlertCustom
        message={openAlert?.message}
        openMessage={openAlert?.status}
        closeMessage={handleCloseAlert}
        transition="down"
        origin={{ vertical: "top", horizontal: "right" }}
      />
    </React.Fragment>
  );
};

export default Register;
