import React from "react";
import { Container } from "@material-ui/core";

import Loading from "components/loading";
import AlertCustom from "components/alertCustom";
import InputPass from "components/form/InputPass";
import InputText from "components/form/InputText";
import ButtonCustom from "components/buttonCustom";

import {
  Main,
  Inner,
  Logo,
  FormPage,
  AnimeForm,
  GroupField,
  GroupBtn,
  B,
  WarningAboutChangeEmail,
  WarningAboutChangeEmailText,
} from "./Styled";

import useLogin from "./useUpdatePassword";

import LogoImg from "./images/logo.png";

const UpdatePassword = () => {
  const {
    transitionIn,
    loadingPage,
    loadingForm,
    openMessage,
    oldPassword,
    newPassword,
    repeatPassword,
    email,
    oldPasswordError,
    newPasswordError,
    repeatPasswordError,
    emailError,
    buttonDisabled,
    message,
    setOpenMessage,
    onChangeOldPassword,
    onChangeNewPassword,
    onChangeRepeatPassword,
    onSubmitNewPasword,
    onChangeEmail,
    translate,
  } = useLogin();

  if (loadingPage) {
    return <Loading />;
  }

  return (
    <React.Fragment>
      <Main>
        <Container>
          <Inner>
            <Logo src={LogoImg} transition={transitionIn} />
            <FormPage
              novalidate
              method="post"
              autoComplete="off"
              loadingForm={loadingForm}
              onSubmit={onSubmitNewPasword}
            >
              <AnimeForm transition={transitionIn}>
                <GroupField>
                  <B>{translate("updatePassword.form.labelNewPassword")}</B>
                </GroupField>
                <GroupField>
                  <InputPass
                    fullWidth
                    id="oldPassword"
                    name="oldPassword"
                    label={translate(
                      "updatePassword.form.inputPassword.labelOld"
                    )}
                    autoComplete="new-password"
                    value={oldPassword}
                    error={oldPasswordError}
                    onChange={onChangeOldPassword}
                  />
                </GroupField>
                <GroupField>
                  <InputPass
                    fullWidth
                    id="newPassword"
                    name="newPassword"
                    label={translate(
                      "updatePassword.form.inputPassword.labelNew"
                    )}
                    autoComplete="new-password"
                    value={newPassword}
                    error={newPasswordError}
                    onChange={onChangeNewPassword}
                  />
                </GroupField>
                <GroupField>
                  <InputPass
                    fullWidth
                    id="repeatPassword"
                    name="repeatPassword"
                    label={translate(
                      "updatePassword.form.inputPassword.labelRepeat"
                    )}
                    autoComplete="new-password"
                    value={repeatPassword}
                    error={repeatPasswordError}
                    onChange={onChangeRepeatPassword}
                  />
                </GroupField>
                <GroupField>
                  <InputText
                    fullWidth
                    id="email"
                    name="email"
                    label={translate(
                      "updatePassword.form.inputPassword.labelEmail"
                    )}
                    autoComplete="email"
                    value={email}
                    error={emailError}
                    onChange={onChangeEmail}
                  />
                </GroupField>
                {/*
                    <WarningAboutChangeEmail>
                  <WarningAboutChangeEmailText>
                    {translate("updatePassword.form.warningAboutChangeEmail")}
                  </WarningAboutChangeEmailText>
                </WarningAboutChangeEmail>
                
               */ }

                <GroupBtn>
                  <ButtonCustom
                    color="default"
                    type="submit"
                    size="medium"
                    disabled={buttonDisabled}
                    fullWidth
                  >
                    {translate("login.form.btnLogin")}
                  </ButtonCustom>
                </GroupBtn>
              </AnimeForm>
            </FormPage>
          </Inner>
        </Container>
      </Main>
      <AlertCustom
        message={message}
        openMessage={openMessage}
        closeMessage={() => setOpenMessage(false)}
        transition="down"
        origin={{ vertical: "top", horizontal: "right" }}
      />
    </React.Fragment>
  );
};

export default UpdatePassword;
