import styled from "styled-components";
import { InputSelect as SelectOptions } from "components/form";

export const ContainerSelect = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: transparent;
`;

export const InputSelect = styled(SelectOptions)`
  width: 100%;
  height: 50px;
  padding: 0 20px;
  border-radius: 5px;
  font-size: 16px;
  color: #ffffff;
  transition: all 0.3s ease;
  outline: none;
`;
