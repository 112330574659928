import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useAuth } from "contexts/Auth.contexts";
import { Utils } from "utils";

import Api from "service/api";

const videoId = process.env.REACT_APP_VIDEO_ID;

const useTermsOfUse = () => {
    const [transitionIn, setTransitionIn] = useState(false);
    const [loadingPage, setLoadingPage] = useState(true);
    const [loadingForm, setLoadingForm] = useState(false);
    const [loadingModal, setLoadingModal] = useState(false);
    const [openResumeTerms, setOpenResumeTerms] = useState(true);
    const [openMessage, setOpenMessage] = useState(false);
    const [message, setMessage] = useState("");
    const [openModal, setOpenModal] = useState(false);
    const [typeModal, setTypeModal] = useState("");
    const [selectedValue, setSelectedValue] = useState("");
    const [imgSignature, setImgSignature] = useState("");
    const [error, setError] = useState(true);
    const [sliderCurrent, setSliderCurrent] = useState(0);

    const { contractAccepted, setContractAccepted } = useAuth();

    const history = useHistory();
    const { t: translate } = useTranslation();
    const refSignature = useRef();

    document.title = translate("termsOfUse.head.title");

    useEffect(() => {
        window.scrollTo(0, 0);
        setTimeout(() => {
            setLoadingPage(false);
        }, 100);

        setTimeout(() => {
            setTransitionIn(true);
        }, 200);
    }, []);

    const handleOpenAlert = () => setOpenMessage(!openMessage);

    const handleShowOrHideResumeTerms = () =>
        setOpenResumeTerms(!openResumeTerms);

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const handleChangeTermoUso = (event) => {
        setTypeModal("signature");
        setOpenModal(true);
    };

    const handleClearSignature = () => {
        refSignature.current.clear();
    };

    const handleSendSignature = () => {
        if (refSignature.current.isEmpty()) {
            setMessage(translate("termsOfUse.messages.errorSignature"));
            setOpenMessage(true);

            return false;
        }

        setLoadingModal(true);

        const signature = refSignature.current.toDataURL("image/jpeg", 0.5);

        const dataURLtoFile = (dataurl, filename) => {
            var arr = dataurl.split(","),
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]),
                n = bstr.length,
                u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new File([u8arr], filename, { type: mime });
        };

        const file = dataURLtoFile(signature, "signature.jpg");

        console.log(file);

        setTimeout(() => {
            setImgSignature(file);
            setOpenModal(false);
            setLoadingModal(false);
            setSelectedValue("aceito");
            setError(false);
        }, 500);
    };

    const handleAceiteTermoUso = () => {
        setLoadingForm(true);

        Api.post("aceite", {
            id: 1,
            assinatura: imgSignature,
        })
            .then((response) => {
                console.log(response.data);
                console.log(response.data.aceite);
                if (response.data.aceite) {
                    setContractAccepted(true);
                    history.push(Utils.setPrefix("/"));
                }
            })
            .catch((error) => {
                console.log(error);
                setLoadingForm(false);
            });
    };

    const handleNoAceiteTermoUso = () => {
        history.push(Utils.setPrefix("/termos-de-uso/logout"));
    };

    const handleChangeSlider = (current) => {
        setSliderCurrent(current);
        document.querySelectorAll("video").forEach((elem) => {
            elem.pause();
        });
    };

    return {
        transitionIn,
        loadingPage,
        loadingForm,
        loadingModal,
        openResumeTerms,
        contractAccepted,
        openMessage,
        message,
        openModal,
        typeModal,
        selectedValue,
        videoId,
        error,
        refSignature,
        sliderCurrent,
        handleOpenAlert,
        handleShowOrHideResumeTerms,
        handleChangeTermoUso,
        handleAceiteTermoUso,
        handleNoAceiteTermoUso,
        handleCloseModal,
        handleClearSignature,
        handleSendSignature,
        handleChangeSlider,
        translate,
        Utils,
    };
};

export default useTermsOfUse;
