import React from "react";
import { Container } from "@material-ui/core";
import Loading from "components/loading";
import ButtonCustom from "components/buttonCustom";
import useMessagesSendSuccess from "./useMessagesSendSuccess";

import {
    Main,
    Transition,
    Inner,
    Title,
    TitleIcon,
    Text,
    BtnGroup,
} from "./Styled";

import ComunImg from "./images/tick.png";

const MessageSendSuccess = () => {
    const { transitionIn, loadingPage, hadleGoback, translate } =
        useMessagesSendSuccess();

    if (loadingPage) {
        return <Loading />;
    }

    return (
        <React.Fragment>
            <Main>
                <Container>
                    <Inner>
                        <Title transition={transitionIn}>
                            <TitleIcon src={ComunImg} />
                            {translate("messageSendSuccess.title")}
                        </Title>
                        <Transition transitionIn={transitionIn}>
                            <Text>{translate("messageSendSuccess.desc")}</Text>
                        </Transition>
                        <BtnGroup transition={transitionIn}>
                            <ButtonCustom
                                color="default"
                                type="button"
                                size="large"
                                className="btn-blue"
                                onClick={() =>
                                    hadleGoback("/adicionar-mensagem")
                                }
                                fullWidth
                            >
                                {translate("messageSendSuccess.btnNewMessage")}
                            </ButtonCustom>
                            <ButtonCustom
                                color="default"
                                type="button"
                                size="large"
                                className="btn-home"
                                onClick={() => hadleGoback("/")}
                                fullWidth
                            >
                                {translate("messageSendSuccess.btnGoHome")}
                            </ButtonCustom>
                        </BtnGroup>
                    </Inner>
                </Container>
            </Main>
        </React.Fragment>
    );
};

export default MessageSendSuccess;
