import styled from "styled-components";
import InputText from "components/form/InputText";

import Theme from "Theme";

export const Title = styled.div`
    color: ${Theme.colorWhite};
    text-align: center;
    width: 100%;
    font-size: 1.25rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1.6;
    letter-spacing: 0.0075em;
`;

export const GroupField = styled.div`
    margin-bottom: 15px;
`;

export const Input = styled(InputText)`
    && {
        .MuiInputLabel-shrink,
        .MuiInputLabel-root.Mui-focused {
            color: ${(props) =>
                props.labeldark ? Theme.colorWhite : Theme.colorWhite};
            top: 0;

            &.Mui-error,
            .MuiFormLabel-asterisk.Mui-error {
                color: ${Theme.formText.label.error};
            }
        }
    }
`;

export const InputFileContainer = styled.label`
    width: 100%;
    background: transparent;
    border: 1px solid white;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px 0;
    margin-top: 16px;
    cursor: pointer;
`;

export const InputFileText = styled.span`
    color: white;
    margin-left: 8px;
`;
