import React, { memo } from "react";
import styled from "styled-components";

import Theme from "Theme";

const Loading = ({ info }) => (
    <Main>
        <Spinner />
        {info && <Info>{info}</Info>}
    </Main>
);

const Main = styled.section`
    width: 100%;
    height: 100%;
    background-color: ${Theme.colorLightBgCommunication};
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 100;
`;

const Spinner = styled.section`
    width: 50px;
    height: 50px;
    border: 4px solid rgba(60, 60, 60, 0.3);
    border-left-color: ${Theme.colorPrimary};
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -40px;
    margin-left: -25px;
    animation: loadingPage 1.1s infinite linear;
    z-index: 15;

    @keyframes loadingPage {
        to {
            transform: rotate(360deg);
        }
    }
`;

const Info = styled.span`
    width: 100%;
    max-width: 210px;
    display: table;
    margin: auto;
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    line-height: 1.3;
    color: ${Theme.colorWhite};
    transform: translateY(60px);
`;

export default memo(Loading);
