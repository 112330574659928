import styled from "styled-components";
import Theme from "Theme";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: #DCDCDC;
`;

export const Main = styled.main`
  display: flex;
  max-width: 960px;
  min-width: 300px;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

export const Header = styled.header`
  display: flex;
  height: 280px;
  background-color: transparent;
  margin-bottom: 20px;
  justify-content: center;
`;

export const Logo = styled.img`
  display: flex;
`;

export const Mensagem = styled.div`
  display: block;
  padding: 10px;
  margin-top: 20px;
  white-space: pre-wrap;

  & > p {
    font-size: 16px;
    color: #000000;
  }

  &.justify {
    text-align: center;
  }
`;

export const ContainerButton = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  background-color: transparent;
  border: none;
  color: #ffffff;
  margin-bottom: 50px;
  padding: 5px;
  margin-top: 50px;
  border-top: #ffffff 1px solid;
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 150px;
  width: 25%;
  height: 40px;
  background-color: ${(props) =>
    props.color === "grey" ? "#4F4F4F" : "#0000FF"};
  //border: 1px solid #ffffff;
  color: #ffffff;
  margin-top: 10px;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: ${(props) =>
      props.color === "grey" ? "#696969" : "#4169E1"};
  }
  & > span {
    font-size: 16px;
  }
  /* Use the disabled prop to change the styles when the button is disabled */
  opacity: ${(props) => (props.disabled ? "0.6" : "1")};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
`;

export const ButtonSubmit = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 150px;
  width: 50%;
  height: 40px;
  background-color: green;
  //border: 1px solid #ffffff;
  color: #ffffff;
  margin-top: 10px;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #3cb371;
  }
  & > span {
    font-size: 16px;
  }
  /* Use the disabled prop to change the styles when the button is disabled */
  opacity: ${(props) => (props.disabled ? "0.6" : "1")};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
`;

export const ContainerField = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 140px;
  background-color: transparent;
  border: none;
  color: #000000;
  margin-top: 10px;
  padding: 5px;
`;

export const InputVerificationCode = styled.input`
  width: 50%;
  height: 40px;
  font-size: 25px;
  background-color: #ffffff;
  border: 1px solid #ffffff;
  color: #000000;
  margin-top: 10px;
  padding: 5px;
  border-radius: 5px;
  text-align: center;
  &::placeholder {
    color: #ffffff;
  }
`;

export const InputVerificationCodeLabel = styled.label`
  color: #000000;
  font-size: 16px;
  margin-top: 10px;
`;

export const LinkChangeEmail = styled.a`
  color: ${Theme.colorBlack};
  font-size: 16px;
  font-weight: 300;
  text-align: center;
  display: table;
 
  margin: auto;
  cursor: pointer;
  &:hover {
    color: #a0a0a0;
  }
  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;
