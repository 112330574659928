import { useCallback, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useWorkWithUs } from "contexts/WorkWithUs.contexts";
import countries from "utils/countries.json";
import listHeight from "utils/listHeigh.json";
import listSizesShirts from "utils/listSizesShirtsV2.json";
import listSizesTshirts from "utils/listSizesShirtsV2.json";
import listSizesShoes from "utils/listSizesShoes.json";
import listSizesPants from "utils/listSizesPants.json";
import listStates from "utils/brazilianStates.json";
import listIssuingStates from "utils/brazilianStates.json";
import Api from "service/api";
import { InputMaskCpf, InputMaskCellphone } from "components/form";

const useBasicInformation = () => {
  const {
    dataFields,
    errors,
    updateDataField,
    updateErrors,
    updateStatusError,
  } = useWorkWithUs();

  const { t: translate } = useTranslation();
  const [driverExpirationDate, setDriverExpirationDate] = useState(null);
  const [isCnhRequired, setCnhRequired] = useState(
    dataFields?.basicInformation?.driverLicenceCategory &&
      dataFields?.basicInformation?.driverLicenceCategory !== "NT"
      ? true
      : false
  );
  const [birthDate, setBirthDate] = useState(null);
  const [timeCourse, setTimeCourse] = useState(null);
  const [ctpsDispatchDate, setCtpsDispatchDate] = useState(null);
  const [driverFirstLicenceDate, setDriverFirstLicenceDate] = useState(null);

  const [nationalities, setNationalities] = useState([]);
  const [listCities, setListCities] = useState([]);
  const [listIssuingCities, setListIssuingCities] = useState([]);
  const [isVigilant] = useState(() => dataFields.typeOfVacancy.type === "1");

  const setMaskPixKey = useCallback((value) => {
    if (value === "cpf") {
      return InputMaskCpf;
    } else if (value === "telefone") {
      return InputMaskCellphone;
    } else {
      return;
    }
  }, []);

  /*
  const pixKeyTypes = [
    { label: "CPF", value: "cpf" },
    { label: "E-mail", value: "email" },
    { label: "Telefone", value: "telefone" },
    { label: "Chave aleatória", value: "chave_aleatoria" },
  ];
*/
  const getOptionsMaritalStatus = [
    {
      label: translate("workWithUs.form.step3.maritalStatusOptions.single"),
      value: "Solteiro(a)",
    },
    {
      label: translate("workWithUs.form.step3.maritalStatusOptions.married"),
      value: "Casado(a)",
    },
    {
      label: translate("workWithUs.form.step3.maritalStatusOptions.divorced"),
      value: "Divorciado(a)",
    },
    {
      label: translate("workWithUs.form.step3.maritalStatusOptions.widower"),
      value: "Viúvo(a)",
    },
    {
      label: translate(
        "workWithUs.form.step3.maritalStatusOptions.stableUnion"
      ),
      value: "União Estável",
    },
    {
      label: translate("workWithUs.form.step3.maritalStatusOptions.separated"),
      value: "Separado(a)",
    },
    {
      label: translate(
        "workWithUs.form.step3.maritalStatusOptions.concubinage"
      ),
      value: "Concubinato",
    },
    {
      label: translate("workWithUs.form.step3.maritalStatusOptions.others"),
      value: "Outros",
    },
  ];

  const getOptionsGender = [
    {
      label: translate("workWithUs.form.step3.genderOptions.masculine"),
      value: "Masculino",
    },
    {
      label: translate("workWithUs.form.step3.genderOptions.feminine"),
      value: "Feminino",
    },
  ];

  const yesNoOptions = [
    { label: "NÃO", value: "0" },
    { label: "SIM", value: "1" },
  ];

  const getOptionsBloodType = [
    { label: "A+", value: "A+" },
    { label: "A-", value: "A-" },
    { label: "B+", value: "B+" },
    { label: "B-", value: "B-" },
    { label: "AB+", value: "AB+" },
    { label: "AB-", value: "AB-" },
    { label: "O+", value: "O+" },
    { label: "O-", value: "O-" },
    { label: "Não informado", value: "não informado" },
  ];

  const getOptionsEducationLevel = [
    { label: "Analfabeto", value: "Analfabeto" },
    { label: "1º Grau Completo", value: "1º Grau Completo" },
    { label: "2º Grau Completo", value: "2º Grau Completo" },
    { label: "2º Grau Incompleto", value: "2º Grau Incompleto" },
    { label: "4ª Série Completa", value: "4ª Série Completa" },
    { label: "4ª Série Incompleta", value: "4ª Série Incompleta" },
    { label: "5ªa 8ª Série Completa", value: "5ªa 8ª Série Completa" },
    { label: "Doutorado", value: "Doutorado" },
    { label: "Mestrado", value: "Mestrado" },
    { label: "Ph.D.", value: "Ph.D." },
    { label: "Pós-Graduação", value: "Pós-Graduação" },
    { label: "Superior Completo", value: "Superior Completo" },
    { label: "Superior Incompleto", value: "Superior Incompleto" },
  ];

  const getDriveLicenceCategories = [
    { label: "Não Tem", value: "NT" },
    { label: "A", value: "A" },
    { label: "B", value: "B" },
    { label: "C", value: "C" },
    { label: "D", value: "D" },
    { label: "E", value: "E" },
    { label: "AB", value: "AB" },
    { label: "AC", value: "AC" },
    { label: "AD", value: "AD" },
    { label: "AE", value: "AE" },
  ];

  const getColorRace = [
    //  { label: "Não Informado", value: "0"},
    { label: "Branca", value: "1" },
    { label: "Preta", value: "2" },
    { label: "Amarela", value: "3" },
    { label: "Parda", value: "4" },
    { label: "Indigena", value: "5" },
    // { label: "Mameluco", value: "6" },
    // { label: "Mulato", value: "7" },
    //{ label: "Cafuzo", value: "8" },
  ];

  const handleChangeText = useCallback(
    ({ target }, item, fn = () => {}) => {
      updateDataField(item, {
        ...{ ...dataFields[item], [target.name]: target.value },
      });

      updateErrors(item, {
        ...{ ...errors[item], [target.name]: false },
      });
      updateStatusError(false);
      fn();
    },
    [dataFields, errors, updateDataField, updateErrors, updateStatusError]
  );

  const handleStateChangeSelect = useCallback(
    ({ target }, related, item, callFunc) => {
      updateDataField(item, {
        ...{ ...dataFields[item], [target.name]: target.value, [related]: "" },
      });

      updateErrors(item, {
        ...{ ...errors[item], [target.name]: false },
      });

      updateStatusError(false);

      if (callFunc === "fetchCities") {
        fetchCities(target.value).catch(console.error);
      } else {
        fetchIssuingCities(target.value).catch(console.error);
      }
    },
    [dataFields, errors, updateDataField, updateErrors, updateStatusError]
  );

  const handleChangeSelect = useCallback(
    ({ target }, item) => {
      updateDataField(item, {
        ...{ ...dataFields[item], [target.name]: target.value },
      });

      updateErrors(item, {
        ...{ ...errors[item], [target.name]: false },
      });
      updateStatusError(false);
    },
    [dataFields, errors, updateDataField, updateErrors, updateStatusError]
  );

  const handleChangeAutocomplete = useCallback(
    ({ target }, selected, name, item) => {
      updateDataField(item, {
        ...{ ...dataFields[item], [name]: selected },
      });

      updateErrors(item, {
        ...{ ...errors[item], [name]: false },
      });
      updateStatusError(false);
    },
    [dataFields, errors, updateDataField, updateErrors, updateStatusError]
  );

  const handleChangeCheckbox = useCallback(
    ({ target }, item, fn = () => {}) => {
      const checked = !dataFields?.basicInformation?.[target.name];

      updateDataField(item, {
        ...{
          ...dataFields[item],
          [target.name]: checked,
        },
      });

      fn();
    },
    [dataFields, updateDataField]
  );

  const handleChangeRadio = useCallback(
    ({ target }, item, fn = () => {}) => {
      let checked = target.value;
      console.log(target);
      updateDataField(item, {
        ...{
          ...dataFields[item],
          [target.name]: checked,
        },
      });
    },
    [dataFields, updateDataField]
  );

  const handleChangeDate = useCallback(
    (date, item, name, fn = () => {}) => {
      updateDataField(item, {
        ...{
          ...dataFields[item],
          [name]: date,
        },
      });

      updateErrors(item, {
        ...{ ...errors[item], [name]: false },
      });
      updateStatusError(false);
      fn();
    },
    [dataFields, updateDataField, updateErrors, updateStatusError, errors]
  );

  const setCountriesNationalities = useCallback(() => {
    let allNationalities = countries.map((country) => {
      return country.gentilico;
    });

    allNationalities = allNationalities.sort();

    allNationalities = allNationalities.map((nationality) => {
      return {
        label: nationality,
        value: nationality,
      };
    });

    setNationalities(allNationalities);
  }, []);

  useEffect(() => {
    setCountriesNationalities();
  }, [setCountriesNationalities]);

  const fetchCities = async (uf) => {
    if (uf) {
      const res = await Api.get(`cities-ibge/${uf}`);
      if (res.data.cidades.length > 0) {
        const cities = res.data.cidades.map((item) => {
          return {
            label: item.nome,
            value: item.nome,
          };
        });
        setListCities(cities);
      }
    }
  };

  const fetchIssuingCities = async (uf) => {
    if (uf) {
      const res = await Api.get(`cities-ibge/${uf}`);
      if (res.data.cidades.length > 0) {
        const cities = res.data.cidades.map((item) => {
          return {
            label: item.nome,
            value: item.nome,
          };
        });
        setListIssuingCities(cities);
      }
    }
  };

  useEffect(() => {
    if (dataFields.basicInformation?.naturalnessState) {
      fetchCities(dataFields.basicInformation?.naturalnessState).catch(
        console.error
      );
    }
  }, []);

  useEffect(() => {
    if (dataFields.basicInformation?.rgIssuingState) {
      fetchIssuingCities(dataFields.basicInformation?.rgIssuingState).catch(
        console.error
      );
    }
  }, []);

  return {
    translate,
    dataFields,
    nationalities,
    errors,
    getOptionsMaritalStatus,
    getOptionsGender,
    getOptionsBloodType,
    getOptionsEducationLevel,
    getDriveLicenceCategories,
    getColorRace,
    isVigilant,
    handleChangeText,
    handleChangeSelect,
    handleChangeAutocomplete,
    handleStateChangeSelect,
    handleChangeCheckbox,
    handleChangeRadio,
    handleChangeDate,
    listHeight,
    listSizesShoes,
    listSizesShirts,
    listSizesTshirts,
    listSizesPants,
    listStates,
    listIssuingStates,
    listCities,
    listIssuingCities,
    yesNoOptions,
    driverExpirationDate,
    setDriverExpirationDate,
    isCnhRequired,
    setCnhRequired,
    birthDate,
    setBirthDate,
    timeCourse,
    setTimeCourse,
    ctpsDispatchDate,
    setCtpsDispatchDate,
    driverFirstLicenceDate,
    setDriverFirstLicenceDate,
    //pixKeyTypes,
    //setMaskPixKey,
  };
};

export default useBasicInformation;
