import React, { useCallback, useState } from "react";
import { Grid } from "@material-ui/core";
import ButtonCustom from "components/buttonCustom";
import ItemExperience from "components/itemExperience";
import useProfessionalExperience from "./useProfessionalExperience";
import { WorkWithUSAddProfessionExperience } from "components/WorkWithUSAddProfessionExperience";

import {
    Subtitle,
    GroupCouses,
    GroupField,
    InputUploadDoc,
    Icon,
} from "../../../Styled";

const ProfessionalExperience = () => {
    const {
        translate,
        dataFields,
        handleRemoveCourse,
        handleChangeFile,
        handleRemoveFile,
    } = useProfessionalExperience();

    const [showAddProfessionExperience, setShowAddProfessionExperience] =
        useState(false);

    const handleShowProfessionExperience = useCallback(() => {
        setShowAddProfessionExperience(true);
    }, []);

    const handleProfessionExperienceSave = useCallback(() => {
        setShowAddProfessionExperience(false);
    }, []);

    return (
        <React.Fragment>
            {showAddProfessionExperience && (
                <WorkWithUSAddProfessionExperience
                    handleSave={handleProfessionExperienceSave}
                />
            )}

            {!showAddProfessionExperience && (
                <GroupCouses>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12} lg={12} xl={12}>
                            <GroupField>
                                <Subtitle>
                                    {translate(
                                        "workWithUs.form.step6.professionalExperience"
                                    )}
                                </Subtitle>
                                {dataFields?.experience?.map((item, index) => (
                                    <ItemExperience
                                        key={index}
                                        company={item.company}
                                        office={item.office}
                                        startDate={item.startDate}
                                        endDate={item.endDate}
                                        onRemove={() =>
                                            handleRemoveCourse(
                                                "experience",
                                                item
                                            )
                                        }
                                    />
                                ))}
                                <ButtonCustom
                                    color="default"
                                    type="button"
                                    size="large"
                                    className="btn-dark"
                                    startIcon={
                                        <Icon className="icon icon-plus" />
                                    }
                                    onClick={() =>
                                        handleShowProfessionExperience()
                                    }
                                    fullWidth
                                >
                                    {translate(
                                        "workWithUs.form.step6.addExperience"
                                    )}
                                </ButtonCustom>
                            </GroupField>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12} xl={12}>
                            <GroupField>
                                <InputUploadDoc
                                    className="btn-dark"
                                    icon="icon-document-edit"
                                    name="curriculum"
                                    label={translate(
                                        "workWithUs.form.step6.attachResume"
                                    )}
                                    file={dataFields?.curriculum?.name}
                                    onChange={handleChangeFile}
                                    onRemove={() =>
                                        handleRemoveFile("curriculum")
                                    }
                                />
                            </GroupField>
                        </Grid>
                    </Grid>
                </GroupCouses>
            )}
        </React.Fragment>
    );
};

export default ProfessionalExperience;
