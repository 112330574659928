import headerPage from "./headerPage.json";
import selectLang from "./selectLang.json";
import modalCustom from "./modalCustom.json";
import login from "./login.json";
import updatePassword from "./updatePassword.json";
import home from "./home.json";
import messages from "./messages.json";
import addMessage from "./addMessage.json";
import messageSendSuccess from "./messageSendSuccess.json";
import termsOfUse from "./termsOfUse.json";
import documents from "./documents.json";
import workWithUs from "./workWithUs.json";
import logout from "./logout.json";
import holerite from "./holerite.json";
import informeRendimento from "./informeRendimento.json";
import updateEmail from "./updateEmail.json";
import pendingEmailConfirmation from "./pendingEmailConfirmation.json";
import page404 from "./page404.json";

export default {
    headerPage,
    selectLang,
    modalCustom,
    login,
    updatePassword,
    home,
    messages,
    addMessage,
    messageSendSuccess,
    termsOfUse,
    documents,
    workWithUs,
    logout,
    holerite,
    informeRendimento,
    updateEmail,
    pendingEmailConfirmation,
    page404,
};
