import React from "react";

import {
  Main,
  Title,
  Container,
  ButtonNota,
  ButtonAvaliar,
  BtnGroup,
} from "./Styled";

const AvaliarAtendimento = ({
  rateAvaliable,
  nota,
  handleChangeNota,
  handleSaveAvaliacao,
  messagemAgradecimento,
}) => {
  if (!rateAvaliable) return null;

  if (messagemAgradecimento) {
    return (
      <Main>
        <Title>{messagemAgradecimento}</Title>
      </Main>
    );
  }

  return (
    <Main>
      <Title>Por favor, avalie o atendimento.</Title>
     
      <Container>
        {[...Array(5)].map((_, i) => (
          <ButtonNota
            type="button"
            onClick={(e) => {
              handleChangeNota(e);
            }}
            key={i + 1}
            backGround={i + 1 === parseInt(nota) ? "#11149f" : "#1e90ff"}
          >
            {i + 1}
          </ButtonNota>
        ))}
      </Container>

      <BtnGroup>
        <ButtonAvaliar
          type="button"
          onClick={() => {
            handleSaveAvaliacao();
          }}
        >
          Enviar Avaliação
        </ButtonAvaliar>
      </BtnGroup>

    </Main>
  );
};

export default AvaliarAtendimento;
