import styled from "styled-components";

import {
  TextField,
  IconButton,
  RadioGroup,
  FormControlLabel,
  FormControl,
  InputLabel,
  Checkbox as InputCheckboxCustom,
  Select,
  MenuItem,
  Button,
} from "@material-ui/core";

import Autocomplete from "@mui/material/Autocomplete";

import ButtonCustom from "components/buttonCustom";

import Theme from "Theme";

import Loading from "./images/loading.svg";

export const FormCustom = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  position: relative;
  transition: all 0.3s ease-in-out;
  opacity: ${(props) => (props.loadingForm ? 0.4 : 1)};
  transform: scale("${(props) => (props.loadingForm ? 0.9 : 1)}");

  &:before {
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("${Loading}");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 80px;
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.3s ease-in-out;
    opacity: ${(props) => (props.loadingForm ? 1 : 0)};
    z-index: ${(props) => (props.loadingForm ? 5 : -1)};
  }
`;

export const GroupPassword = styled.div`
  position: relative;
`;

export const TField = styled(TextField)`
  .MuiInputBase-root {
    background-color: ${(props) =>
      props?.transparent ? "transparent" : Theme.formText.bg.default};
    font-weight: 300;
    border-radius: 6px;
    padding: 10px 20px;
    padding-right: 50px;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    border: 1px solid
      ${(props) =>
        props?.transparent ? Theme.colorWhite : Theme.formText.border.default};

    &:hover {
      background-color: ${(props) =>
        props?.transparent ? "transparent" : Theme.formText.bg.hover};
    }

    &.Mui-focused {
      border-bottom-color: ${Theme.colorGreen};
    }
    &.Mui-error {
      border-color: ${Theme.formText.border.error};
    }
  }

  .MuiInputBase-input {
    color: ${(props) =>
      props?.transparent ? Theme.colorWhite : Theme.colorBlack};
  }

  .MuiFormLabel-root {
    color: ${Theme.formText.label.default};
    font-weight: 400;
    padding: 0 20px;
    top: 15%;
    z-index: 1;

    &.Mui-error,
    .MuiFormLabel-asterisk.Mui-error {
      color: ${Theme.formText.label.error};
    }
  }

  .MuiInputLabel-shrink,
  .MuiInputLabel-root.Mui-focused {
    color: ${(props) =>
      props.labeldark
        ? Theme.formText.label.focusDark
        : Theme.formText.label.focusDark};
    top: 0;

    &.Mui-error,
    .MuiFormLabel-asterisk.Mui-error {
      color: ${Theme.formText.label.error};
    }
  }

  .MuiInput-underline {
    &:before {
      border-bottom: 0;
    }
    &:after {
      border-bottom: 2px solid ${Theme.formText.border.focus};
    }
    &:hover {
      &:not(.Mui-disabled) {
        &:before {
          border-bottom: 0;
        }
      }
    }
    &.Mui-error {
      &:after {
        border-bottom-color: ${Theme.formText.border.error};
      }
    }
  }
`;

export const Label = styled(IconButton)`
  &.MuiIconButton-root {
    position: absolute;
    top: 60%;
    transform: translateY(-50%);
    right: 12px;
  }
`;

export const Icon = styled.span`
  font-size: 30px;
  color: ${Theme.colorDarkGrey};
  transition: all 0.3s ease-in-out;

  &.icon-hide-view {
    color: ${Theme.colorDarkGrey};
  }
`;

export const GroupChecked = styled.div`
  display: flex;
  align-items: center;

  margin-top: ${(props) => `${props.marginTop}px`};

  label {
    color: white !important;
  }
`;

export const Checkbox = styled(InputCheckboxCustom)`
  && {
    &.MuiCheckbox-root {
      color: ${Theme.colorWhite};

      &.MuiCheckbox-colorSecondary:hover {
        background-color: rgba(0, 141, 37, 0.2);
      }

      &.Mui-checked {
        color: ${Theme.colorGreen};

        &:before {
          width: 15px;
          height: 15px;
          content: "";
          background-color: ${Theme.colorWhite};
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        .MuiIconButton-label {
          z-index: 1;
        }
      }
    }
  }
`;

export const GroupRadios = styled(RadioGroup)`
  &.MuiFormGroup-root {
    flex-direction: column;
    margin-top: 10px;
  }

  &[error="true"] {
    .MuiSvgIcon-root:first-child {
      fill: ${Theme.colorError};
    }
    .MuiTypography-root {
      color: ${Theme.colorError};
    }
  }
  .MuiRadio-colorSecondary {
    &.Mui-checked {
      color: ${Theme.colorSecondary};
      background-color: ${Theme.colorGreen};
      &:hover {
        background-color: transparent;
      }
    }
    &:hover {
      background-color: rgba(3, 229, 72, 0.055);
    }
  }
  label {
    color: #ffffff;
  }
`;

export const LabelRadio = styled(FormControlLabel)`
  .MuiSvgIcon-root:first-child {
    fill: ${Theme.colorDark};
  }
  .MuiSvgIcon-root:last-child {
    fill: ${Theme.colorPrimary};
  }
`;

export const FormControlSelect = styled(FormControl)`
  && {
    width: 100%;

    .MuiInputBase-root {
      background-color: ${(props) =>
        props.transparent !== "false"
          ? "transparent"
          : Theme.formText.bg.default};
      color: ${(props) =>
        props.transparent !== "false" ? Theme.colorWhite : Theme.colorBlack};
      font-weight: 300;
      border-radius: 6px;
      overflow: hidden;
      transition: all 0.3s ease-in-out;
      border: 1px solid
        ${(props) =>
          props.transparent !== "false"
            ? Theme.colorWhite
            : Theme.formText.border.default};

      .MuiSelect-root {
        z-index: 2;
        cursor: default;
        padding: 16px 20px;
        padding-right: 30px;
      }

      &:hover {
        background-color: ${(props) =>
          props.transparent !== "false"
            ? "transparent"
            : Theme.formText.bg.hover};
      }

      &.Mui-focused {
        border-bottom-color: ${Theme.colorGreen};
      }
      &.Mui-error {
        border-color: ${Theme.formText.border.error};

        .MuiSvgIcon-root {
          color: ${Theme.formText.border.error};
        }
      }
    }

    .MuiInput-underline {
      &:before {
        border-bottom: 0;
      }
      &:after {
        border-bottom: 2px solid ${Theme.formText.border.focus};
      }
      &:hover {
        &:not(.Mui-disabled) {
          &:before {
            border-bottom: 0;
          }
        }
      }
      &.Mui-error {
        &:after {
          border-bottom-color: ${Theme.formText.border.error};
        }
      }
    }

    .MuiSvgIcon-root {
      color: ${(props) =>
        props.transparent !== "false" ? Theme.colorWhite : Theme.colorBlack};
    }
  }
`;

export const LabelSelect = styled(InputLabel)`
  && {
    &.MuiFormLabel-root {
      color: ${Theme.formText.label.default};
      font-weight: 400;
      padding: 0 20px;
      top: 15%;
      z-index: 1;

      &.Mui-error,
      .MuiFormLabel-asterisk.Mui-error {
        color: ${Theme.formText.label.error};
      }
    }

    &.MuiInputLabel-shrink,
    &.MuiInputLabel-root.Mui-focused {
      color: ${(props) =>
        props.labeldark ? Theme.colorBlack : Theme.colorWhite};
      top: 0;

      &.Mui-error,
      .MuiFormLabel-asterisk.Mui-error {
        color: ${Theme.formText.label.error};
      }
    }
  }
`;

export const TAutocomplete = styled(Autocomplete)`
  && {
    .MuiAutocomplete-root {
      width: 100%;
    }

    .MuiInputBase-root {
      &,
      &:focus {
        background-color: transparent;
      }
      fieldset {
        border-color: ${(props) => (props.error ? "red" : "#FFFFFF")};
      }
    }
    .MuiFormLabel-root {
      color: ${(props) => (props.error ? "red" : "#9E9E9E")};
      margin-top: -10px;
      margin-left: -15px;
    }
    .MuiSvgIcon-root {
      fill: #fff;
    }
    .MuiInputBase-input {
      color: #ffffff;
    }
  }
`;

export const TSelect = styled(Select)`
  && {
    .MuiSelect-select {
      &,
      &:focus {
        background-color: transparent;
      }
    }
  }
`;

export const TSelectItem = styled(MenuItem)`
  && {
    font-size: 14px;

    &.Mui-selected {
      color: ${Theme.colorWhite};
      background-color: ${Theme.colorBlue};

      &:hover {
        color: ${Theme.colorWhite};
        background-color: ${Theme.colorBlueDark};
      }
    }
  }
`;

export const MultipleSelectLabel = styled.div`
  && {
    display: flex;
    flex-wrap: wrap;
    margin: 10px 0 0;
  }
`;

export const SelectedLabelItem = styled.span`
  && {
    font-size: 14px;
    line-height: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    color: ${Theme.colorWhite};
    display: inline-block;
    border: 1px solid ${Theme.colorWhite};
    border-radius: 10px;
    padding: 5px 10px;
    margin: 5px;

    .icon {
      font-size: 10px;
      margin-left: 5px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;

      &:hover {
        opacity: 0.7;
      }
    }
  }
`;

export const TBtnUpload = styled(Button)`
  && {
    width: 100%;
    height: 100%;
    color: #fff;
    font-weight: 600;
    text-transform: none;
    color: ${Theme.colorWhite};
    background-color: ${Theme.buttonAnexo.default[0]};
    background-image: linear-gradient(
      to right,
      ${Theme.buttonAnexo.default[0]},
      ${Theme.buttonAnexo.default[1]}
    );
    border: 0;
    border-radius: 6px;
    transition: all 0.6s linear;
    overflow: hidden;

    &:hover {
      background-color: ${Theme.buttonAnexo.hover[0]};
      background-image: linear-gradient(
        to right,
        ${Theme.buttonAnexo.hover[0]},
        ${Theme.buttonAnexo.hover[1]}
      );
    }
  }
`;

export const TFile = styled.input`
  && {
    width: 800px;
    height: 100%;
    cursor: pointer;
    position: absolute;
    left: -100px;
    top: 0;
    opacity: 0;
  }
`;

export const ViewFileDoc = styled.div`
  width: 100%;
  height: 60px;
  position: relative;
  display: flex;
  align-items: center;
  padding: 8px 11px;
  background-color: ${Theme.colorGreen};
  border: 2px solid #999;
  border-radius: 6px;
`;

export const ViewFileDocInner = styled.div`
  width: calc(100% - 60px);
  font-size: 14px;
  font-weight: 500;
  color: ${Theme.colorWhite};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const ButtonClose = styled(ButtonCustom)`
  && {
    width: 20px;
    height: 100%;
    background: transparent;
    color: ${Theme.colorWhite};
    position: absolute;
    right: 0;

    &:hover {
      background: transparent;
    }

    .icon {
      color: ${Theme.colorWhite};
      font-size: 20px;
    }
  }
`;

export const PreviewFt = styled.div`
  && {
    cursor: pointer;

    height: 50px;
    position: relative;
    display: inline-block;
    overflow: hidden;
    vertical-align: text-top;
    margin: 5px;
    &:hover {
      &:after {
        width: 100%;
        background-color: rgba(30, 30, 30, 0.4);
        position: absolute;
        top: 0;
        left: 0;
        transition: all 0.4s ease-in-out;
        opacity: 0;
      }

      img {
        max-width: 220px;
        height: auto;
      }

      &:after,
      .icon-close {
        opacity: 1;
        width: 30px;
        height: 25px;
      }
    }
    span {
      display: block;
      padding: 5px;
      height: 50px;
      width: 100%;
      color: #000000;
      border: 1px solid grey;
    }
  }
`;

export const IconRemove = styled.span`
  && {
    width: 28px;
    height: 28px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: normal;
    text-align: center;
    color: ${Theme.colorRed};
    position: absolute;
    top: 10px;
    right: 10px;
    transition: all 0.3s ease-in-out;
    opacity: 0;
    border: 2px solid ${Theme.colorRed};
    border-radius: 14px;
  }
`;

export const RadioCustom = styled.label`
  && {
    width: 240px;
    height: 150px;
    display: flex;
    position: relative;
    overflow: hidden;

    button {
      width: 100%;
      height: 100%;
    }

    input {
      width: 100%;
      height: 100%;
      cursor: pointer;
      position: absolute;
      opacity: 0;
      z-index: 1;

      &:checked ~ .bg {
        background-color: ${Theme.colorGreen};
      }
    }

    .bg {
      width: 100%;
      height: 100%;
      padding: 20px;
      border: 1px solid ${Theme.colorWhite};
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      left: 0;
    }

    i {
      font-size: 60px;
      color: ${Theme.colorWhite};
      margin-bottom: 10px;
    }

    span {
      font-size: 16px;
      font-weight: 600;
      color: ${Theme.colorWhite};
      line-height: 1.4;
    }
  }
`;

export const RadioCustomDefault = styled.label`
  && {
    width: 40px;
    height: 40px;
    display: flex;
    position: relative;
    overflow: hidden;
    border-radius: 8px button {
      width: 100%;
      height: 100%;
    }

    input {
      width: 100%;
      height: 100%;
      cursor: pointer;
      position: absolute;
      opacity: 0;
      z-index: 1;

      &:checked ~ .bg {
        background-color: ${Theme.colorGreen};
      }
    }

    .bg {
      width: 100%;
      height: 100%;
      padding: 20px;
      border: 1px solid ${Theme.colorWhite};
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      left: 0;
    }

    i {
      font-size: 60px;
      color: ${Theme.colorWhite};
      margin-bottom: 10px;
    }

    span {
      font-size: 16px;
      font-weight: 600;
      color: ${Theme.colorWhite};
      line-height: 1.4;
    }
  }
`;

export const CheckboxCustom = styled.label`
  && {
    width: 100%;
    height: 40px;
    display: flex;
    position: relative;
    overflow: hidden;

    button {
      width: 100%;
      height: 100%;
      border-radius: 10px;
    }

    input {
      width: 100%;
      height: 100%;
      cursor: pointer;
      position: absolute;
      opacity: 0;
      z-index: 1;

      &:checked ~ .bg {
        background-color: ${Theme.colorGreen};
      }
    }

    .bg {
      width: 100%;
      height: 100%;
      padding: 5px 15px;
      background-color: ${Theme.colorBlack};
      border: 1px solid ${Theme.colorBlack};
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      left: 0;
    }

    span {
      font-size: 16px;
      font-weight: 500;
      color: ${Theme.colorWhite};
      line-height: 1.4;
    }
  }
`;

export const DataListLabel = styled.label`
  && {
    color: white;
  }
`;

export const DataListInput = styled.input`
  && {
    color: white;
    width: 100%;
    height: 40px;
  }
`;

export const DataListSelect = styled.datalist`
  && {
    width: 100%;
    padding: 5px 15px;
    background-color: black;
    border: 1px solid black;
    border-radius: 10px;
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
  }
`;
