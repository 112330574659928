import React, { memo } from "react";

import { Item, ButtonClose, Icon } from "./Styled";

const ItemCourse = ({ onRemove, children }) => {
    return (
        <Item>
            {children}
            <ButtonClose onClick={onRemove}>
                <Icon className="icon icon-close" />
            </ButtonClose>
        </Item>
    );
}

export default memo(ItemCourse);
