import React from "react";
import { withRouter, Switch, Route, Redirect } from "react-router-dom";
import { Utils } from "utils";

// Páginas
import UpdatePassword from "screens/updatePassword";
import PendingEmailConfimation from "screens/pendingEmailConfirmation";

const ChangePassword = () => (
  <React.Fragment>
    <Switch>
      <Route
        exact
        path={Utils.setPrefix("/atualizar-senha")}
        component={UpdatePassword}
      />
      <Route
        exact
        path={Utils.setPrefix("/pending-email-confirmation")}
        component={PendingEmailConfimation}
      />
      <Redirect to={Utils.setPrefix("/atualizar-senha")} />
    </Switch>
  </React.Fragment>
);

export default withRouter(ChangePassword);
