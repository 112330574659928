import styled from "styled-components";
import Form from "components/form";
import { InputUpload } from "components/form";
export const Container = styled.div`
  display: flex;
  min-height: 100vh;
  background: rgb(33, 75, 138);
  background: linear-gradient(
    139deg,
    rgba(33, 75, 138, 1) 25%,
    rgba(0, 145, 67, 1) 100%
  );
  background-size: cover;
  background-position: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const Main = styled.main`
  display: flex;
  max-width: 1280px;
  min-width: 300px;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

export const Header = styled.header`
  display: flex;
  height: 280px;
  background-color: transparent;
  margin-bottom: 20px;
  justify-content: center;
`;

export const Logo = styled.img`
  display: flex;
`;

export const Mensagem = styled.div`
  display: block;
  padding: 10px;
  margin-top: 20px;
  white-space: pre-wrap;

  & > p {
    font-size: 16px;
    color: #000000;
  }

  &.justify {
    text-align: center;
  }
`;

export const ContainerButton = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  background-color: transparent;
  border: none;
  color: #ffffff;
  margin-top: 10px;
  padding: 5px;
  margin-top: 50px;
  border-top: #ffffff 1px solid;
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 150px;
  width: 25%;
  height: 40px;
  background-color: ${(props) =>
    props.color === "grey" ? "#4F4F4F" : "#0000FF"};
  //border: 1px solid #ffffff;
  color: #ffffff;
  margin-top: 10px;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: ${(props) =>
      props.color === "grey" ? "#696969" : "#4169E1"};
  }
  & > span {
    font-size: 16px;
  }
  /* Use the disabled prop to change the styles when the button is disabled */
  opacity: ${(props) => (props.disabled ? "0.6" : "1")};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
`;

export const FormPage = styled(Form)`
  width: 50%;
`;

export const GroupField = styled.div`
  margin-bottom: 5px;
`;

export const GroupBtn = styled.div`
  margin: 22px 0 30px;
`;

export const BoxComment = styled.textarea`
  margin-top: 5px;
  width: 100%;
  height: 100px;
  padding: 10px;
  font-size: 14px;
  border-radius: 5px;
  border: 1px solid #000000;
  resize: none;
`;

export const InputFileAttachment = styled(InputUpload)`
  margin-top: 5px;
  background-color: transparent;
  color: aliceblue;
`;

export const MessageSuccess = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  background-color: #008000;
  color: #ffffff;
  font-size: 16px;
  border-radius: 5px;
  padding: 10px;
  & > h2 {
    font-size: 16px;
    padding: 3px;
  }
  & > p {
    font-size: 14px;
    padding: 3px;
  }
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #ffffff;
  margin-bottom: 20px;

  & > h1 {
    font-weight: bold;
    font-size: 18px;
  }
`;
