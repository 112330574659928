import styled from "styled-components";
import Theme from "Theme";
import Form from "components/form";
import InputText from "components/form/InputText";
import Text from "components/text/Text";
import ButtonCustom from "components/buttonCustom";
import { IconButton } from "@material-ui/core";

import Loading from "./images/loading.svg";

export const Main = styled.main`
  margin-top: 150px;
  position: relative;
  overflow-x: hidden;

  @media (max-width: 768px) {
    margin-top: 180px;
  }

  &:before {
    width: 100%;
    height: 100%;
    content: "";
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: ${Theme.colorLightBgCommunication};
  }

  & > div {
    position: relative;
  }
`;

export const Title = styled.h1`
  font-size: 22px;
  font-weight: bold;
  color: ${Theme.colorBlack};
  text-align: center;
  transition: all 0.3s ease-in-out;
  opacity: ${(props) => (props.transition ? 1 : 0)};
  transform: translateX(${(props) => (props.transition ? 0 : "-30px")});
  margin: 20px auto 30px;
`;

export const BtnGroup = styled.div`
  && {
    transition: all 0.3s ease-in-out;
    opacity: ${(props) => (props.transition ? 1 : 0)};
    transform: translateX(${(props) => (props.transition ? 0 : "-30px")});
    margin-top: 18px;
    margin-left: 4px;
    height: 50px;
  }
`;

export const Transition = styled.div`
  transition: all 0.4s ease-in-out;
  opacity: ${(props) => (props.transitionIn ? 1 : 0)};
  transform: translateY(${(props) => (props.transitionIn ? "0" : "30px")});
`;

export const FormPage = styled(Form)`
  width: 100%;
  margin-bottom: 20px;
`;

export const GroupField = styled.div`
  margin-bottom: 5px;
  display: flex;
  align-content: flex-start;
  justify-content: space-between;
`;

export const LabelField = styled.div`
  font-size: 14px;
  color: ${Theme.colorBlack};
  flex: 0 0 auto;
  margin-top: 15px;
  margin-right: 20px;
  margin-bottom: 10px;
`;

export const InputDate = styled(InputText)`
  && {
    width: 100%;
    .MuiInputLabel-shrink,
    .MuiInputLabel-root.Mui-focused {
      color: ${(props) =>
        props.labeldark ? Theme.colorBlack : Theme.colorBlack};
      top: 0;

      &.Mui-error,
      .MuiFormLabel-asterisk.Mui-error {
        color: ${Theme.formText.label.error};
      }
    }
  }
`;

export const ViewPdf = styled.object`
  margin-bottom: 30px;
  display: ${(props) => (props.display ? "block" : "none")};
`;

export const ViewPdfFrame = styled.iframe`
  margin-bottom: 30px;
  display: ${(props) => (props.display ? "block" : "none")};
`;

export const ViewMessageNoPdf = styled(Text)`
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 8px;
  color:#fff;
  background-color: ${Theme.colorRed};
  display: ${(props) => (props.show ? "block" : "none")};
`;

export const WrapDocuments = styled.div`
  width: 100%;
  min-height: 80vh;

  button {
    margin-bottom: 10px;

    &.btn-align-left {
      .MuiButton-label {
        padding-right: 40px;

        .icon {
          &.icon-document-edit {
            margin-right: 10px;
          }

          &.icon-view {
            position: absolute;
            top: 50%;
            right: 20px;
            transform: translateY(-50%);
          }
        }
      }
    }
  }
`;

export const Document = styled(ButtonCustom).attrs({
  color: "primary",
  type: "button",
  size: "large",
  className: "btn-align-left",
  fullWidth: true,
})``;

export const Icon = styled.span`
  && {
    font-size: 22px;
    font-weight: normal;
    color: ${Theme.colorBlueDark};
  }
`;

export const ContainerModal = styled.div`
  && {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const ContentModal = styled.div`
  && {
    width: 100%;
    width: 640px;
    background-color: ${Theme.colorWhite};
    border-radius: 10px;
    position: relative;
    transition: all 0.3s ease-in-out;
    transform: scale(${(props) => (props.loadingModal ? 0.93 : 1)});
    overflow: hidden;

    @media (max-width: 768px) {
      width: 95%;
    }

    &:before {
      width: 100%;
      height: 100%;
      content: "";
      background-color: rgba(255, 255, 255, 0.6);
      background-image: url("${Loading}");
      background-position: center;
      background-repeat: no-repeat;
      border-radius: 10px;
      background-size: 60px;
      position: absolute;
      top: 0;
      left: 0;
      transition: all 0.3s ease-in-out;
      opacity: ${(props) => (props.loadingModal ? 1 : 0)};
      z-index: ${(props) => (props.loadingModal ? 5 : -1)};
    }
  }
`;

export const HeaderModal = styled.div`
  && {
    width: 100%;
    height: 60px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 25px;
  }
`;

export const BtnCloseModal = styled(IconButton)`
  && {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1;

    .icon-close {
      font-size: 12px;
    }
  }
`;

export const ScrollY = styled.div`
  && {
    width: 100%;
    height: 85%;
    margin: 10px 0 30px;
    padding: 0;
    border-bottom: 5px solid #555;

    &.scroll__inner-html {
      height: 86%;
    }

    object {
      width: 100%;
      height: 100%;
    }
  }
`;

export const TextHTML = styled.div`
  && {
    width: 100%;
    height: 95%;
    max-height: 80vh;
    overflow-x: hidden;
    overflow-y: auto;
    margin: 40px 0 0;
    padding: 0 20px;
  }
`;

export const BodyModal = styled.div`
  && {
    width: 100%;
    min-height: 300px;
    max-height: 85vh;
    height: auto;
    padding: 10px 20px;
  }
`;

export const Inner = styled.div`
  && {
    width: 100%;

    &.inner__pdf {
      height: 65vh;

      object,
      iframe {
        width: 100%;
        height: calc(100% - 120px);
      }
    }

    .view__pdf--link {
      display: table;
      margin: 20px auto;
      color: green;
    }

    &.inner__text--html {
      height: 65vh;
    }
  }
`;

export const TitleModal = styled.h3`
  && {
    color: ${Theme.colorBlueDark};
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 0;
  }
`;

export const TextModal = styled.p`
  && {
    color: ${Theme.colorBlack};
    font-size: 14px;
    line-height: 1.5;
  }
`;

export const FooterModal = styled.div`
  && {
    width: 100%;
    min-height: 120px;
    padding: 20px;
    background-color: ${Theme.colorGrayLight};
    text-align: center;

    p {
      line-height: 1.4;
      font-size: 12px;
    }
  }
`;

export const BtnGroups = styled.div`
  && {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;

    button {
      max-width: 160px;
      height: 40px;
      padding: 10px 15px;
      margin: 0 10px;
      text-transform: uppercase;
    }
  }
`;

export const NoContent = styled.div`
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  text-align: center;
  color: ${Theme.colorGray};
`;
