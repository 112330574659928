import styled from "styled-components";
import { Link } from "react-router-dom";
import Theme from "Theme";

export const BtnDefault = styled(Link)`
    & {
        width: 100%;
        padding: 10px 15px;
        color: #fff;
        font-weight: 600;
        text-transform: none;
        background-color: ${Theme.buttonMain.default[0]};
        background-image: linear-gradient(
            to right,
            ${Theme.buttonMain.default[0]},
            ${Theme.buttonMain.default[1]}
        );
        border: 0;
        border-radius: 6px;
        transition: all 0.6s linear;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;

        &:hover {
            background-color: #cd1a66;
            background-image: linear-gradient(
                to right,
                ${Theme.buttonMain.hover[0]},
                ${Theme.buttonMain.hover[1]}
            );
        }
    }
`;

export const BtnDark = styled(Link)`
    & {
        width: 100%;
        height: 100%;
        color: #fff;
        font-weight: 600;
        text-transform: none;
        background-color: ${Theme.buttonDark.default[0]};
        background-image: linear-gradient(
            to right,
            ${Theme.buttonDark.default[0]},
            ${Theme.buttonDark.default[1]}
        );
        border: 0;
        border-radius: 6px;
        transition: all 0.6s linear;

        &:hover {
            background-color: #cd1a66;
            background-image: linear-gradient(
                to right,
                ${Theme.buttonDark.hover[0]},
                ${Theme.buttonDark.hover[1]}
            );
        }
    }
`;
