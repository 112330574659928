import React, { memo } from "react";
import { Modal, Backdrop, Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import {
  ContainerModal,
  ContentModal,
  HeaderModal,
  TitleModal,
  BodyModal,
  Inner,
  BtnCloseModal,
  Icon,
} from "./Styled";

const RenderPDF = ({ urlPdf }) => {

  const { t: translate } = useTranslation();

  if (urlPdf === "") {
    return null;
  }

  return (
    <Inner className="inner__pdf">
      <object
        aria-labelledby={translate("modalCustom.viewPdf")}
        data={urlPdf}
        type="application/pdf"
      ></object>
      <a
        className="view__pdf--link"
        href={urlPdf}
        target="_blank"
        rel="noopener noreferrer"
      >
        {translate("modalCustom.viewPdf")}
      </a>
    </Inner>
  );
};


const ModalCustom = (props) => {
  return (
    <Modal
      open={props.open}
      onClose={props.onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <ContainerModal>
        <ContentModal loadingModal={props.loadingModal}>
          <HeaderModal>
            {props.content.title !== undefined && !props.loadingModal && (
              <TitleModal>{props.content.title}</TitleModal>
            )}
            <BtnCloseModal onClick={props.onClose}>
              <Icon className="icon icon-close" />
            </BtnCloseModal>
          </HeaderModal>
          <BodyModal>
            {!props.loadingModal && <RenderPDF urlPdf={props?.content?.urlPdf} onClose={props.onClose} />}
          </BodyModal>
        </ContentModal>
      </ContainerModal>
    </Modal>
  );
};

export default memo(ModalCustom);
