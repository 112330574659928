import React from "react";
import { Grid } from "@material-ui/core";

import { InputMaskCpf, InputMaskCellphone } from "components/form";
import useContact from "./useContact";

import { Subtitle, GroupField, Input, Small} from "../../../Styled";

const Contact = () => {
  const { translate, dataFields, errors, handleChangeText } = useContact();

  return (
    <React.Fragment>
      <Subtitle>
        {translate("workWithUs.form.step2.title")}<br/>
        <Small>{translate("workWithUs.requiredFields")}</Small> 
      </Subtitle>
     
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={12} xl={12}>
          <GroupField>
            <Input
              fullWidth
              transparent="true"
              type="tel"
              id="cpf"
              name="cpf"
              label={translate("workWithUs.form.step2.cpf") + ' (*)'}
              autoComplete="off"
              value={dataFields?.contact?.cpf || ""}
              onChange={(props) => handleChangeText(props, "contact")}
              error={errors?.contact?.cpf}
              InputProps={{
                inputComponent: InputMaskCpf,
              }}
            />
          </GroupField>
        </Grid>
        <Grid item xs={12} md={12} lg={12} xl={12}>
          <GroupField>
            <Input
              fullWidth
              transparent="true"
              id="name"
              name="name"
              label={translate("workWithUs.form.step2.name") + ' (*)'}
              autoComplete="off"
              value={dataFields?.contact?.name || ""}
              onChange={(props) => handleChangeText(props, "contact")}
              error={errors?.contact?.name}
            />
          </GroupField>
        </Grid>
        <Grid item xs={12} md={12} lg={12} xl={12}>
          <GroupField>
            <Input
              fullWidth
              transparent="true"
              type="tel"
              id="cellphone"
              name="cellphone"
              label={`${translate(
                "workWithUs.form.step2.cellphone"
              )} (WhatsApp) (*)`}
              autoComplete="off"
              value={dataFields?.contact?.cellphone || ""}
              onChange={(props) => handleChangeText(props, "contact")}
              error={errors?.contact?.cellphone}
              InputProps={{
                inputComponent: InputMaskCellphone,
              }}
            />
          </GroupField>
        </Grid>
        <Grid item xs={12} md={12} lg={12} xl={12}>
          <GroupField>
            <Input
              fullWidth
              transparent="true"
              type="tel"
              id="cellphone2"
              name="cellphone2"
              label={`${translate(
                "workWithUs.form.step2.confirmCellphone"
              )} (WhatsApp)`}
              autoComplete="off"
              value={dataFields?.contact?.cellphone2 || ""}
              onChange={(props) => handleChangeText(props, "contact")}
              error={errors?.contact?.cellphone2}
              InputProps={{
                inputComponent: InputMaskCellphone,
              }}
            />
          </GroupField>
        </Grid>
        <Grid item xs={12} md={12} lg={12} xl={12}>
          <GroupField>
            <Input
              fullWidth
              transparent="true"
              type="email"
              id="email"
              name="email"
              label={translate("workWithUs.form.step2.email") + ' (*)'}
              autoComplete="off"
              value={dataFields?.contact?.email || ""}
              onChange={(props) => handleChangeText(props, "contact")}
              error={errors?.contact?.email}
            />
          </GroupField>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default Contact;
