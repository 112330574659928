import React from "react";
import { withRouter, Switch, Route } from "react-router-dom";
import { Utils } from "utils";

// Páginas
import Menu from "screens/menu";
import TermsOfUse from "screens/termsOfUse";
import Documents from "screens/documents";
import Holerite from "screens/holerite";
import InformeRendimento from "screens/informeRendimento";
import Logout from "screens/logout";
import Page404 from "screens/404";

import HeaderPage from "components/headerPage";

const FiredPrivate = () => {

  return (
    <React.Fragment>
      <HeaderPage />
      <Switch>
        <Route exact path={Utils.setPrefix("/")} component={Menu} />
        <Route
          exact
          path={Utils.setPrefix("/termos-de-uso")}
          component={TermsOfUse}
        />
        <Route
          exact
          path={Utils.setPrefix("/documentos")}
          component={Documents}
        />

        <Route
          exact
          path={Utils.setPrefix("/informe-rendimentos")}
          component={InformeRendimento}
        />
        <Route
          exact
          path={Utils.setPrefix("/holerites")}
          component={Holerite}
        />
        <Route exact path={Utils.setPrefix("/logout")} component={Logout} />
        <Route component={Page404} />
      </Switch>
    </React.Fragment>
  );
};

export default withRouter(FiredPrivate);