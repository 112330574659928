import React, { memo } from "react";
import { TBtnUpload, TFile, Icon, ViewFileDoc, ViewFileDocInner, ButtonClose } from "./Styled";

const InputUploadDoc = (props) => {

    if (props.file) {
        return (
            <ViewFileDoc>
                <ViewFileDocInner>
                    {props.file}
                </ViewFileDocInner>
                <ButtonClose onClick={props.onRemove}>
                    <Icon className="icon icon-delete" />
                </ButtonClose>
            </ViewFileDoc>
        );
    }

    return (
        <TBtnUpload
            className={props.className}
            color="default"
            type="button"
            size="large"
            fullWidth
        >
            {props.icon && <Icon className={`icon ${props.icon}`} />}
            {props.label}
            <TFile
                title={props.labelSelect}
                onChange={props.onChange}
                name={props.name}
                type="file"
                accept=".pdf,.doc,.docx"
            />
        </TBtnUpload>
    );
}

export default memo(InputUploadDoc);
