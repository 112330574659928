import { useState, useEffect } from "react";
import Api from "service/api";
import { set } from "store";

const usePasswordRecovery = () => {
  const [name, setName] = useState("");
  const [cpf, setCpf] = useState("");
  const [email, setEmail] = useState("");
  const [whatsApp, setWhatsApp] = useState("");
  const [matricula, setMatricula] = useState("");
  const [empresa, setEmpresa] = useState("");
  const [comentario, setComentario] = useState("");
  const [loading, setLoading] = useState(true);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [showMessageSuccess, setShowMessageSuccess] = useState(false);
  const [openMessage, setOpenMessage] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState({
    name: true,
    cpf: true,
    email: true,
    whatsApp: true,
    matricula: true,
    empresa: true,
    comentario: true,
  
  });

  const listEmpresas = [
    { value: "Quality", label: "Quality Inteligência" },
    { value: "Adservi", label: "Adservi" },
    { value: "Adservig", label: "Adservig" },
    { value: "Master Vigilancia", label: "Master Vigilância"},
    { value: "Master Adminstração", label: "Master Administração"},
    { value: "Tecnoseg", label: "Tecnoseg" },
    { value: "Tecnoguarda", label: "Tecnoguarda" },
    { value: "5 Estreleas", label: "5 Estrelas" },
    { value: "Fiel", label: "Fiel" },
    { value: "Total", label: "Total" },
  ]


  const onChangeMatricula = async (event) => {
    setError({ ...error, matricula: event.target.value.length < 3 });
    setMatricula(event.target.value);
    await enableButton();
  };

  const onChangeEmpresa = async (event) => {
    setError({ ...error, empresa: event.target.value.length === 0});
    setEmpresa(event.target.value);
    await enableButton();
  };

  const onChangeName = async (event) => {
    setName(event.target.value);
    setError({...error, name:event.target.value.length < 3});
    await enableButton();
  };

  const onChangeCpf = async (event) => {
    const textCpf = event.target.value;
    const onlyNums = textCpf.replace(/[^0-9]/g, "");
    setError({ ...error, cpf: onlyNums.length < 11 });
    setCpf(event.target.value);
    await enableButton();
  };

  const onChangeEmail = async (event) => {
    setError({ ...error, email: event.target.value.length === 0 });
    setEmail(event.target.value);
    await enableButton();
  };

  const onChangeWhatsApp = async (event) => {
    setError({ ...error, whatsApp: event.target.value.length < 11 });
    setWhatsApp(event.target.value);
    await enableButton();
  };

  const onChangeComentario = async (event) => {
    setComentario(event.target.value);
    await enableButton();
  };

  const handleSubmitPasswordRecovery = async () => {
    const regex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
    const isValid = regex.test(email);
    setError({...error, email: !isValid})
    if (!isValid) {
      return;
    }

    setLoading(true);
    const data = {
      cpf,
      email,
      whatsApp,
      comentario,
      name,
      matricula,
      empresa,
      // fileAttachment: fileAttachment?.img ?? null,
    };

    await Api.post("/grupo-altum/password-recovery", data)
      .then((response) => {
        console.log(response);
        if (response.data.success) {
          setLoading(false);
          setShowMessageSuccess(true);
        }
      })
      .catch((error) => {
        setLoading(false);
        setAlertSeverity("error");
        setOpenMessage(true);
        setMessage("Erro ao enviar solicitação de recuperação de senha");
        //console.log(error);
      });
  };

  const enableButton = async () => {
    setButtonDisabled(!(!error.cpf && !error.whatsApp && !error.email && !error.name));
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const paramCpf = params.get("cpf");
    setCpf(paramCpf);
    setLoading(false);
  }, []);

  return {
    name,
    onChangeName,
    email,
    onChangeEmail,
    cpf,
    onChangeCpf,
    whatsApp,
    onChangeWhatsApp,
    comentario,
    onChangeComentario,
    matricula,
    onChangeMatricula,
    empresa,
    onChangeEmpresa,
    loading,
    handleSubmitPasswordRecovery,
    buttonDisabled,
    showMessageSuccess,
    setOpenMessage,
    openMessage,
    alertSeverity,
    message,
    error,
    listEmpresas,
  };
};

export default usePasswordRecovery;
