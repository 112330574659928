import styled from "styled-components";

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 150px;
  justify-content: center;
  align-items: center;
  justify-items: center;
  background-color: #f5f5f5;
  border-radius: 5px;
  padding: 10px;
  margin-top: 35px;
`;

export const Title = styled.h1`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  color: #000000;
  margin-bottom: 10px;
  width: 100%;
`;
export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: auto;
`;

export const ButtonNota = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  background-color: ${(props) => props.backGround ?? "#000000"};
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 2px;
`;

export const ButtonAvaliar = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 30px;
  padding: 10px;
  background-color: #32cd32; /* Green color */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  &:hover {
    background-color: #228b22; /* Darker green color */
  }
`;

export const ContainerTextArea = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

export const TextAreaComentario = styled.textarea`
  width: 100%;
  height: 100px;
  margin-top: 10px;
  padding: 10px;
  border: 1px solid #1e90ff;
  border-radius: 5px;
`;

export const BtnGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;

  margin-top: 10px;
  margin-bottom: 15px;
`;
