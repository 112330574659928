import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  createContext,
} from "react";
import Store from "store";

const WorkWithUsContext = createContext({
  dataFields: {
    typeOfVacancy: {
      type: null,
    },
  },
  errors: {},
  statusError: false,
  openModal: { name: null, extra: null },
  openAlert: { status: false, message: "" },
  updateDataField: () => {},
  clearDataField: () => {},
  updateErrors: () => {},
  updateStatusError: () => {},
  handleOpenModal: () => {},
  handleCloseModal: () => {},
  handleOpenAlert: () => {},
  handleCloseAlert: () => {},
});


export const WorkWithUsProvider = ({ children }) => {
  const [dataFields, setDataFields] = useState({});
  const [errors, setErrors] = useState({});
  const [statusError, setStatusError] = useState(false);
  const [openModal, setOpenModal] = useState({ name: null, extra: null });
  const [openAlert, setOpenAlert] = useState({ status: false, message: "" });

  useEffect(() => {
      setDataFields(
        Store.get("work_with_us") ? JSON.parse(Store.get("work_with_us")) : {}
      );
  }, []);

  const updateDataField = useCallback(
    (name, data) => {
      setDataFields({ ...dataFields, [name]: data });
      if (name[0] !== "curriculum") {
        Store.set(
          "work_with_us",
          JSON.stringify({ ...dataFields, [name]: data })
        );
      }
    },
    [dataFields]
  );

  const clearDataField = useCallback(() => {
    setDataFields({});
    Store.remove("work_with_us");
  }, []);

  const updateErrors = useCallback(
    (name, error) => {
      setErrors({ ...errors, [name]: error });
    },
    [errors]
  );

  const updateStatusError = useCallback((error) => {
    setStatusError(error);
  }, []);

  const handleOpenModal = useCallback(
    (name, extra = null) => setOpenModal({ name, extra }),
    []
  );

  const handleCloseModal = useCallback(
    () => setOpenModal({ name: null, extra: null }),
    []
  );

  const handleOpenAlert = useCallback((message) => {
    setOpenAlert({ status: true, message });
  }, []);

  const handleCloseAlert = useCallback(() => {
    setOpenAlert({ ...openAlert, status: false });
  }, [openAlert]);

  return (
    <WorkWithUsContext.Provider
      value={{
        dataFields,
        errors,
        statusError,
        openModal,
        openAlert,
        updateDataField,
        clearDataField,
        updateErrors,
        updateStatusError,
        handleOpenModal,
        handleCloseModal,
        handleOpenAlert,
        handleCloseAlert,
      }}
    >
      {children}
    </WorkWithUsContext.Provider>
  );
};

export const useWorkWithUs = () => useContext(WorkWithUsContext);
