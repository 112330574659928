import { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useWorkWithUs } from "contexts/WorkWithUs.contexts";
import Api from "service/api";
import { Utils } from "utils";

const useSelectTypeOfVacancy = () => {
  const [loadingPage, setLoadingPage] = useState(true);
  const [transitionIn, setTransitionIn] = useState(false);
  const [typeOfVacancy, setTypeOfVacancy] = useState("");
  const [typeOfContract, setTypeOfContract] = useState([]);
  const [search] = useState();
  const [professionsAll, setProfessionsAll] = useState([]);
  const [professions, setProfessions] = useState([]);

  const {
    errors,
    dataFields,
    openModal,
    openAlert,
    updateDataField,
    updateErrors,
    updateStatusError,
    handleOpenModal,
    handleOpenAlert,
    handleCloseAlert,
  } = useWorkWithUs();

  const history = useHistory();
  const { t: translate } = useTranslation();

  document.title = translate("workWithUs.head.selectTypeOfVacancy.title");
  const getProfessions = useCallback(async (type) => {
    await Api.get("/trabalhe-conosco/profissoes/" + type)
      .then((response) => {
        const list = response.data.profissao.map((item) => ({
          label: item.trpr_nome,
          value: item.trpr_id_trabalhe_profissao,
        }));
        setProfessionsAll(list);
        setProfessions(list);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  const hadleGoback = () => {
    history.push(Utils.setPrefix(`/trabalhe-conosco`));
  };

  const hadleGoLink = (link) => {
    if (
      JSON.stringify(dataFields) === "{}" ||
      (dataFields?.typeOfVacancy?.type !== "3" &&
        dataFields?.typeOfVacancy?.options?.length === 0) ||
        dataFields?.typeOfContract?.length === 0 || dataFields?.typeOfContract === undefined
    ) {
      handleOpenAlert(
        translate("workWithUs.selectTypeOfVacancy.validated.selectAnOption")
      );
      return;
    }
    history.push(Utils.setPrefix(`${link}`));
  };

  const getProfessionType = useCallback((type) => {
   // console.log("getProfessionType", type);
    setProfessions([]);
    (async () => {
      await getProfessions(type);
    })();
    handleOpenModal("ModalSelectOptions");
  }, []);

  const getValueChecked = (name, value) => dataFields[name]?.type === value;

  const handleSelectTypeOfVacancy = useCallback(
    (name, value) => {
      const options =
        (dataFields[name]?.type === value && dataFields[name]?.options) || [];
      const data = { type: value, options };
      setTypeOfVacancy(name);
      updateDataField(name, data);

      if (value === "1" || value === "2" || value === "3") {
        getProfessionType(value);
      }
    },
    [dataFields, updateDataField]
  );

  const handleSearch = useCallback(
    ({ target }) => {
      const query = target.value.trim().toLowerCase();
      setProfessions(
        professionsAll.filter(
          (item) => item.label.toLowerCase().search(query) > -1
        )
      );
    },
    [professionsAll]
  );

  const handleChangeTypeContract = useCallback(
    ({ target }) => {
      setTypeOfContract(target.value);
      updateDataField("typeOfContract", target.value);
    },
    [updateDataField, updateErrors, updateStatusError]
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    setLoadingPage(false);
    setTimeout(() => {
      setTransitionIn(true);
    }, 100);
  }, []);

  return {
    errors,
    translate,
    transitionIn,
    loadingPage,
    search,
    typeOfVacancy,
    professions,
    openModal,
    openAlert,
    handleSearch,
    handleOpenAlert,
    handleCloseAlert,
    hadleGoback,
    hadleGoLink,
    getValueChecked,
    handleSelectTypeOfVacancy,
    typeOfContract,
    handleChangeTypeContract,
    dataFields,
  };
};

export default useSelectTypeOfVacancy;
