import { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useWorkWithUs } from "contexts/WorkWithUs.contexts";

export const useAddProfessionExperience = () => {
  const [fieldsModal, setFieldsModal] = useState({});
  const [errorModal, setErrorModal] = useState({});
  const { dataFields, openModal, updateDataField, handleCloseModal } =
    useWorkWithUs();
  const { t: translate } = useTranslation();

  const onValidate = useCallback(() => {
    const newErrors = {
      compay: false,
      office: false,
      startDate: false,
      endDate: false,
    };
    let status = false;

    if (!fieldsModal?.company || fieldsModal?.company.length < 2) {
      newErrors.company = true;
      status = true;
    }
    if (!fieldsModal?.office || fieldsModal?.office.length < 2) {
      newErrors.office = true;
      status = true;
    }
    if (!fieldsModal?.startDate) {
      newErrors.startDate = true;
      status = true;
    }
    if (
      fieldsModal?.actualjob === undefined ||
      fieldsModal?.actualjob === false
    ) {
      if (!fieldsModal?.endDate) {
        newErrors.endDate = true;
        status = true;
      }
    }

    setErrorModal({ ...newErrors });
    return status;
  }, [
    fieldsModal.actualjob,
    fieldsModal.company,
    fieldsModal.endDate,
    fieldsModal.office,
    fieldsModal.startDate
  ]);

  const handleChangeText = useCallback(
    ({ target }) => {
      setFieldsModal({ ...fieldsModal, [target.name]: target.value });
      setErrorModal({ ...errorModal, [target.name]: false });
    },
    [fieldsModal, errorModal]
  );

  const handleChangeDate = useCallback(
    (date, name, fn = () => {}) => {
      setFieldsModal({ ...fieldsModal, [name]: date });
      setErrorModal({ ...errorModal, [name]: false });

      fn();
    },
    [fieldsModal, errorModal]
  );

  const handleChangeCheckbox = useCallback(
    ({ target }) => {
      setFieldsModal(() => {
        if (target.checked) {
          delete fieldsModal.endDate;
          return { ...fieldsModal, [target.name]: target.checked };
        }

        return { ...fieldsModal, [target.name]: target.checked };
      });
    },
    [fieldsModal]
  );

  const handleAddCourse = useCallback(
    (fn = () => {}) => {
      const extra = "experience";

      const courses =
        dataFields[extra] !== undefined ? [...dataFields[extra]] : [];

      if (onValidate()) {
        return;
      }

      const startDate = fieldsModal?.startDate
        ? `${fieldsModal.startDate.getDate().toString().padStart(2, "0")}/${(
            fieldsModal.startDate.getMonth() + 1
          )
            .toString()
            .padStart(2, "0")}/${fieldsModal.startDate.getFullYear()}`
        : "";

      const endDate = fieldsModal?.endDate
        ? `${fieldsModal.endDate.getDate().toString().padStart(2, "0")}/${(
            fieldsModal.endDate.getMonth() + 1
          )
            .toString()
            .padStart(2, "0")}/${fieldsModal.endDate.getFullYear()}`
        : "Atualmente";

      courses.push({
        company: fieldsModal?.company,
        office: fieldsModal?.office,
        startDate,
        endDate,
      });

      updateDataField(extra, courses);

      fn();
    },
    [
      dataFields,
      onValidate,
      fieldsModal.company,
      fieldsModal.office,
      fieldsModal.startDate,
      fieldsModal.endDate,
      updateDataField,
    ]
  );

  return {
    translate,
    fieldsModal,
    dataFields,
    errorModal,
    openModal,
    handleCloseModal,
    handleChangeText,
    handleChangeDate,
    handleChangeCheckbox,
    handleAddCourse,
  };
};
