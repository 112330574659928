import React, { memo } from "react";
import { Modal, Backdrop, Grid } from "@material-ui/core";
import SignatureCanvas from "react-signature-canvas";
import { useTranslation } from "react-i18next";
import ButtonCustom from "components/buttonCustom";
import {
    ContainerModal,
    ContentModal,
    HeaderModal,
    BodyModal,
    BoxSignature,
    BtnCloseModal,
    Icon,
    FooterModal,
    BtnGroups,
} from "./Styled";

const ModalCustom = (props) => {
    const { t: translate } = useTranslation();

    return (
        <Modal
            open={props.open}
            onClose={props.onClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <ContainerModal>
                <ContentModal loadingModal={props.loadingModal}>
                    <HeaderModal>
                        <BtnCloseModal onClick={props.onClose}>
                            <Icon className="icon icon-close" />
                        </BtnCloseModal>
                    </HeaderModal>
                    <BodyModal>
                        <BoxSignature>
                            <SignatureCanvas
                                penColor="black"
                                backgroundColor="white"
                                ref={props.refSignature}
                                canvasProps={{ className: "sigCanvas" }}
                            />
                        </BoxSignature>
                        <FooterModal>
                            <Grid container justifyContent="center">
                                <Grid item xs={11} md={10}>
                                    <p>
                                        {translate(
                                            "modalCustom.helperAssinatura"
                                        )}
                                    </p>
                                    <BtnGroups>
                                        <ButtonCustom
                                            color="default"
                                            type="button"
                                            size="large"
                                            className="btn-cancel"
                                            onClick={props.handleClearSignature}
                                            fullWidth
                                        >
                                            {translate("modalCustom.btnClear")}
                                        </ButtonCustom>
                                        <ButtonCustom
                                            color="default"
                                            type="button"
                                            size="large"
                                            className="btn-green"
                                            onClick={props.handleSendSignature}
                                            fullWidth
                                        >
                                            {translate("modalCustom.btnSend")}
                                        </ButtonCustom>
                                    </BtnGroups>
                                </Grid>
                            </Grid>
                        </FooterModal>
                    </BodyModal>
                </ContentModal>
            </ContainerModal>
        </Modal>
    );
};

export default memo(ModalCustom);
