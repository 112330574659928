import axios from "axios";
import objectToFormData from "utils/objtof2";

const API_URL = "https://api-compliance.sagris.com.br/api";

const ApiEad = axios.create({
    baseURL: API_URL,
    transformRequest: [
        (data, headers) => {
            return objectToFormData(data);
        },
    ],
    transformResponse: [
        function (data, headers) {
            data = JSON.parse(data);
            return data.status_code >= 300 ? data.errors : data.data;
        },
    ],
});

export default ApiEad;