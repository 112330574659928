import React, { useState, useEffect } from "react";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import MaskedInput from "react-text-mask";
import { Utils } from "utils";

import Api from "service/api";

const TextMaskCustom = (props) => {
  const { inputRef, ...other } = props;
  const currentLang = window.localStorage.i18nextLng;
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={
        currentLang === "en"
          ? [/\d/, /\d/, /\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/]
          : [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]
      }
      placeholderChar={"\u2000"}
    />
  );
};

const useMessages = () => {
  const [loadingPage, setLoadingPage] = useState(true);
  const [transitionIn, setTransitionIn] = useState(false);
  const [loadingForm, setLoadingForm] = useState(false);
  const [hasSelectMultiple, setHasSelectMultiple] = useState(false);
  const [area, setArea] = useState("");
  const [tipo, setTipo] = useState("");
  const [regional, setRegional] = useState("");
  const [data, setData] = useState("");
  const [previewFotos, setPreviewFotos] = useState([]);
  const [filesFotos, setFileFotos] = useState([]);
  const [comentario, setComentario] = useState("");
  const [fieldErrors, setFieldErrors] = useState([]);
  const [openAlert, setOpenAlert] = useState(false);
  const [messageAlert, setMessageAlert] = useState("");
  const [optionsArea, setOptionsArea] = useState([]);
  const [optionsCategories, setOptionsCategories] = useState([]);
  const [optionsTipo, setOptionsTipo] = useState([]);
  const [optionsRegional, setOptionsRegional] = useState([]);
  const history = useHistory();
  const { t: translate } = useTranslation();
  const [anexoRequiredList, setAnexoRequiredList] = useState([]);

  document.title = translate("addMessage.head.title");

  const hadleGoback = () => {
    history.push(Utils.setPrefix(`/comunicacao-interna`));
  };

  const handleChangeArea = (event) => {
    getFilteredCategories(event.target.value);
    setArea(event.target.value);
    setTipo(event.target.value === "Pagamentos Fixos e Variáveis" ? [] : "");
    setFieldErrors(fieldErrors.filter((inp) => inp.name !== "area"));
    setHasSelectMultiple(
      event.target.value === "Pagamentos Fixos e Variáveis" ? true : false
    );
  };

  const handleChangeTipo = (event) => {
    setTipo(event.target.value);
    setFieldErrors(fieldErrors.filter((inp) => inp.name !== "tipo"));
  };

  const handleRemoveItemSelect = (value) => {
    setTipo(tipo.filter((item) => item !== value));
  };

  const handleChangeRegional = (event) => {
    setRegional(event.target.value);
    setFieldErrors(fieldErrors.filter((inp) => inp.name !== "regional"));
  };

  const handleChangeData = (event) => {
    console.log(event.target.value)
    setData(event.target.value);
    setFieldErrors(fieldErrors.filter((inp) => inp.name !== "data"));
  };

  const handleChangeComentario = (event) => {
    setComentario(event.target.value);
    setFieldErrors(fieldErrors.filter((inp) => inp.name !== "comentario"));
  };

  const handleChangeFotos = (event) => {
    let input = event.currentTarget;
    let validImageTypes = [
      "image/jpg",
      "image/jpeg",
      "image/png",
      "application/pdf",
    ];

    let img = URL.createObjectURL(input.files[0]);
    let fileName = input.files[0].name;
    if (validImageTypes.indexOf(input.files[0]["type"]) !== -1) {
      setPreviewFotos([...previewFotos, { img, fileName }]);
      setFileFotos([...filesFotos, input.files]);
      setFieldErrors(fieldErrors.filter((inp) => inp.name !== "fotos"));
    }
  };

  const handleRemoveFoto = (foto) => {
    setPreviewFotos(previewFotos.filter((item) => item !== foto));
    document.querySelector("button [type=file]").value = null;
  };

  const validateForm = () => {
    let errors = 0;
    let filelds = [];
    document.getElementById("msg-anexo").innerText = "";
    if (area === "") {
      filelds.push({ name: "area", error: true });
      errors++;
    }

    if (tipo === "") {
      filelds.push({ name: "tipo", error: true });
      errors++;
    }

    if (regional === "") {
      filelds.push({ name: "regional", error: true });
      errors++;
    }

    if (anexoRequiredList.includes(area) && data === '') {
      filelds.push({ name: "data", error: true });
      errors++;
    }


    if (data !== '' && (!moment(data, "YYYY-MM-DD").isValid())) {
      filelds.push({ name: "data", error: true });
      errors++;
    }

    if (comentario === "") {
      filelds.push({ name: "comentario", error: true });
      errors++;
    }

    if (anexoRequiredList.includes(area) && filesFotos.length === 0) {
      document.getElementById("msg-anexo").innerText = `Anexo Obrigatório para ${area}`;
      errors++;
    }

    setFieldErrors(filelds);
    return { errors, filelds };
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { errors } = validateForm();
    if (errors > 0) {
      setMessageAlert(translate("addMessage.formValidade"));
      setOpenAlert(true);
      return false;
    }

    setLoadingForm(true);

    let arquivos = [];

    filesFotos.forEach((file) => {
      arquivos.push(file[0]);
    });

    Api.post("comunicacao-altum", {
      area,
      regional,
      comentario,
      arquivos,
      tipo: Array.isArray(tipo) ? tipo.join("/") : tipo,
      mes_referencia: data,
    })
      .then((response) => {
        console.log(response.data);
        history.push(Utils.setPrefix("/mensagem-enviada-com-sucesso"));
      })
      .catch((error) => {
        setLoadingForm(false);
      });

  };

  const listAreas = async () => {
    const resp = await Api.get("client/settings/areas");
    let requiredList = [];
    if (resp.data.areas.length > 0) {
      const list = resp.data.areas.map((item) => {
        if (item.attachment_is_required) {
          requiredList.push(item.name)
        }
        return {
          value: item.name,
          label: item.name,
        };
      });
      setOptionsArea(list);
      setAnexoRequiredList(requiredList)
    }
  };

  const listCategorias = async () => {
    const resp = await Api.get("client/settings/categories");
    if (resp.data.categories.length > 0) {
      const list = resp.data.categories.map((item) => {
        return {
          value: item.name,
          label: item.name,
          areaName: item.area.name,
        };
      });
      setOptionsCategories(list);
    }
  };

  const listRegional = async () => {
    const resp = await Api.get("client/settings/locations");
    if (resp.data.locations.length > 0) {
      const list = resp.data.locations.map((item) => {
        return {
          value: item.name,
          label: item.name,
        };
      });
      setOptionsRegional(list);
    }
  };

  function getFilteredCategories(area) {
    const list = optionsCategories.filter((item) => item.areaName === area);
    setOptionsTipo(list);
  }

  useEffect(() => {
    window.scrollTo(0, 0);

    setTimeout(() => {
      setLoadingPage(false);
    }, 200);

    setTimeout(() => {
      setTransitionIn(true);
    }, 300);

    listAreas();
    listCategorias();
    listRegional();
  }, []);

  return {
    transitionIn,
    loadingPage,
    loadingForm,
    hasSelectMultiple,
    optionsArea,
    optionsRegional,
    area,
    tipo,
    regional,
    data,
    previewFotos,
    comentario,
    fieldErrors,
    openAlert,
    messageAlert,
    optionsTipo,
    setOpenAlert,
    handleChangeArea,
    handleChangeTipo,
    handleRemoveItemSelect,
    handleChangeRegional,
    handleChangeData,
    handleChangeFotos,
    handleRemoveFoto,
    handleChangeComentario,
    TextMaskCustom,
    handleSubmit,
    hadleGoback,
    translate,
  };
};

export default useMessages;
