import React, { memo, useState } from "react";
import { GroupPassword, TField, Label, Icon } from "./Styled";

const InputPass = (props) => {
    const [password, setPassword] = useState(true);

    const handleChangeField = () => {
        setPassword(!password);
    };

    return (
        <GroupPassword>
            <TField {...props} type={password ? "password" : "text"} />
            <Label htmlFor={props.name} onClick={handleChangeField}>
                <Icon
                    className={
                        password ? "icon icon-hide-view" : "icon icon-view"
                    }
                />
            </Label>
        </GroupPassword>
    );
};

export default memo(InputPass);
