import React from "react";
import { Container } from "@material-ui/core";

import Loading from "components/loading";
import AlertCustom from "components/alertCustom";
import { InputText, InputMaskCpf, InputPass } from "components/form";
import ButtonCustom from "components/buttonCustom";

import {
  Main,
  Inner,
  Logo,
  FormPage,
  AnimeForm,
  GroupField,
  GroupBtn,
  A,
  B,
  Register,
  MessageSuccess,
} from "./Styled";

import useLogin from "./useLogin";

import LogoImg from "./images/logo.png";

const Login = () => {
  const {
    transitionIn,
    loadingPage,
    loadingForm,
    renderPage,
    openMessage,
    login,
    password,
    loginError,
    passwordError,
    buttonDisabled,
    message,
    messageSuccess,
    setRenderPage,
    setOpenMessage,
    onChangeLogin,
    onChangePassword,
    onSubmitLogin,
    onSubmitPasword,
    goLink,
    translate,
  } = useLogin();

  if (loadingPage) {
    return <Loading />;
  }

  const hasRenderPage = (renderPage) => {
    if (renderPage === "esqueci-minha-senha") {
      return (
        <FormPage
          novalidate
          method="post"
          autoComplete="off"
          loadingForm={loadingForm}
          onSubmit={onSubmitPasword}
        >
          <AnimeForm transition={transitionIn}>
            <GroupField>
              <B>{translate("login.form.labelNewPassword")}</B>
            </GroupField>
            <GroupField>
              <InputText
                fullWidth
                type="tel"
                id="login"
                name="login"
                label={translate("login.form.inputLogin.label")}
                autoComplete="off"
                value={login}
                error={loginError}
                onChange={onChangeLogin}
                InputProps={{
                  inputComponent: InputMaskCpf,
                }}
              />
            </GroupField>
            <GroupBtn>
              <ButtonCustom
                color="default"
                type="submit"
                size="medium"
                disabled={loginError}
                fullWidth
              >
                {translate("login.form.btnNewPassword")}
              </ButtonCustom>
            </GroupBtn>
            <GroupField>
              <A onClick={() => setRenderPage("login")}>
                {translate("login.form.returnLogin")}
              </A>
            </GroupField>
          </AnimeForm>
        </FormPage>
      );
    }
    return (
      <FormPage
        novalidate
        method="post"
        autoComplete="off"
        loadingForm={loadingForm}
        onSubmit={onSubmitLogin}
      >
        <AnimeForm transition={transitionIn}>
          {messageSuccess !== "" && (
            <MessageSuccess>{messageSuccess}</MessageSuccess>
          )}
          <GroupField>
            <InputText
              fullWidth
              type="tel"
              id="login"
              name="login"
              label={translate("login.form.inputLogin.label")}
              autoComplete="off"
              value={login}
              error={loginError}
              onChange={onChangeLogin}
              InputProps={{
                inputComponent: InputMaskCpf,
              }}
            />
          </GroupField>
          <GroupField>
            <InputPass
              fullWidth
              id="senha"
              name="senha"
              label={translate("login.form.inputPassword.label")}
              autoComplete="new-password"
              value={password}
              error={passwordError}
              onChange={onChangePassword}
            />
          </GroupField>
          <GroupBtn>
            <ButtonCustom
              color="default"
              type="submit"
              size="medium"
              disabled={buttonDisabled}
              fullWidth
            >
              {translate("login.form.btnLogin")}
            </ButtonCustom>
          </GroupBtn>
          <GroupField>
            <A onClick={() => setRenderPage("esqueci-minha-senha")}>
              {translate("login.form.forgotMypassword")}
            </A>
          </GroupField>
        </AnimeForm>
      </FormPage>
    );
  };

  return (
    <React.Fragment>
      <Main>
        <Container>
          <Inner>
            <Logo src={LogoImg} transition={transitionIn} />
            {hasRenderPage(renderPage)}
          </Inner>
          {renderPage !== "esqueci-minha-senha" && (
            <Register>
              <AnimeForm transition={transitionIn}>
                <A onClick={() => goLink("/trabalhe-conosco")}>
                  <u>{translate("login.workWithUs")} {translate("login.register")}</u>
                </A>
                <A
                  href="http://grupoaltum.com.br/denuncia/"
                  target="_blank"
                >
                  <u>{translate("login.denuncia")}</u>
                </A>
              </AnimeForm>
            </Register>
          )}
        </Container>
      </Main>
      <AlertCustom
        message={message}
        openMessage={openMessage}
        closeMessage={() => setOpenMessage(false)}
        transition="down"
        origin={{ vertical: "top", horizontal: "right" }}
      />
    </React.Fragment>
  );
};

export default Login;
