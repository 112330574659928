import styled from "styled-components";
import { IconButton } from "@material-ui/core";

import ButtonCustom from "components/buttonCustom";

import Theme from "Theme";

import Loading from "./images/loading.svg";

const minSizeScreen = "431px";

export const Main = styled.main`
  margin-top: 100px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  padding-top: 15px;

  &:before {
    width: 100%;
    height: 100%;
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    background-color: #eeeeee;
  }

  & > div {
    position: relative;
  }
`;

export const ContentPage = styled.div`
  position: relative;
  transition: all 0.3s ease-in-out;
  opacity: ${(props) => (props.loadingForm ? 0.6 : 1)};
  transform: scale(${(props) => (props.loadingForm ? 1.1 : 1)});

  &:before {
    width: 100%;
    height: 100%;
    content: "";
    transition: all 0.3s ease-in-out;
    background-image: url("${Loading}");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 60px;
    position: fixed;
    top: 0;
    left: 0;
    visibility: ${(props) => (props.loadingForm ? "visible" : "hidden")};
    z-index: ${(props) => (props.loadingForm ? 1233434 : -5)};
  }
`;

export const Transition = styled.div`
  transition: all 0.4s ease-in-out;
  opacity: ${(props) => (props.transitionIn ? 1 : 0)};
  transform: translateX(${(props) => (props.transitionIn ? "0" : "30px")});
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 40px 0;
  margin-bottom: 20px;
  z-index: 9999;

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

export const Title = styled.h1`
  font-size: 22px;
  font-weight: bold;
  color: ${Theme.colorBlack};
  text-align: center;
  transition: all 0.3s ease-in-out;
  opacity: ${(props) => (props.transition ? 1 : 0)};
  transform: translateX(${(props) => (props.transition ? 0 : "-30px")});
  margin: 30px auto 30px;
  @media (max-width: ${minSizeScreen}) {
    font-size: 0.9rem;
  }
`;

export const Logo = styled.img`
  margin: 0;
  transition: all 0.3s ease-in-out;
  opacity: ${(props) => (props.transition ? 1 : 0)};
  transform: translateX(${(props) => (props.transition ? 0 : "30px")});

  @media (max-width: 768px) {
    max-width: 160px;
    margin-bottom: 20px;
  }
`;

export const WrapDocuments = styled.div`
  width: 100%;
  min-height: 80vh;

  button {
    margin-bottom: 10px;

    &.btn-align-left {
      .MuiButton-label {
        padding-right: 40px;

        .icon {
          &.icon-document-edit {
            margin-right: 10px;
          }

          &.icon-view {
            position: absolute;
            top: 50%;
            right: 20px;
            transform: translateY(-50%);
          }
        }
      }
    }
  }
`;

export const Document = styled(ButtonCustom).attrs({
  color: "primary",
  type: "button",
  size: "large",
  className: "btn-align-left",
  fullWidth: true,
  
})`
&& {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}`;

export const GroupBtns = styled.div`
  width: 100%;
  padding: 20px 0;
  transition: all 0.4s ease-in-out;
  opacity: ${(props) => (props.transition ? 1 : 0)};

  button {
    margin-bottom: 5px;

    .MuiButton-label {
      line-height: 1;
      text-align: left;
    }
  }
`;

export const Icon = styled.span`
  && {
    font-size: 22px;
    font-weight: normal;
    color: ${Theme.colorBlueDark};
  }
`;

export const ContainerModal = styled.div`
  && {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const ContentModal = styled.div`
  && {
    width: 100%;
    width: 640px;
    background-color: ${Theme.colorWhite};
    border-radius: 10px;
    position: relative;
    transition: all 0.3s ease-in-out;
    transform: scale(${(props) => (props.loadingModal ? 0.93 : 1)});
    overflow: hidden;

    @media (max-width: 768px) {
      width: 95%;
    }

    &:before {
      width: 100%;
      height: 100%;
      content: "";
      background-color: rgba(255, 255, 255, 0.6);
      background-image: url("${Loading}");
      background-position: center;
      background-repeat: no-repeat;
      border-radius: 10px;
      background-size: 60px;
      position: absolute;
      top: 0;
      left: 0;
      transition: all 0.3s ease-in-out;
      opacity: ${(props) => (props.loadingModal ? 1 : 0)};
      z-index: ${(props) => (props.loadingModal ? 5 : -1)};
    }
  }
`;

export const HeaderModal = styled.div`
  && {
    width: 100%;
    height: 60px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 25px;
  }
`;

export const BtnCloseModal = styled(IconButton)`
  && {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1;

    .icon-close {
      font-size: 12px;
    }
  }
`;

export const ScrollY = styled.div`
  && {
    width: 100%;
    height: 85%;
    margin: 10px 0 30px;
    padding: 0;
    border-bottom: 5px solid #555;

    &.scroll__inner-html {
      height: 86%;
    }

    object {
      width: 100%;
      height: 100%;
    }
  }
`;

export const TextHTML = styled.div`
  && {
    width: 100%;
    height: 95%;
    max-height: 80vh;
    overflow-x: hidden;
    overflow-y: auto;
    margin: 40px 0 0;
    padding: 0 20px;
  }
`;

export const BodyModal = styled.div`
  && {
    width: 100%;
    min-height: 300px;
    max-height: 85vh;
    height: auto;
    padding: 10px 20px;
  }
`;

export const Inner = styled.div`
  && {
    width: 100%;

    &.inner__pdf {
      height: 65vh;

      object,
      iframe {
        width: 100%;
        height: calc(100% - 120px);
      }
    }

    .view__pdf--link {
      display: table;
      margin: 20px auto;
      color: green;
    }

    &.inner__text--html {
      height: 65vh;
    }
  }
`;

export const TitleModal = styled.h3`
  && {
    color: ${Theme.colorBlueDark};
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 0;
  }
`;

export const TextModal = styled.p`
  && {
    color: ${Theme.colorBlack};
    font-size: 14px;
    line-height: 1.5;
  }
`;

export const FooterModal = styled.div`
  && {
    width: 100%;
    min-height: 120px;
    padding: 20px;
    background-color: ${Theme.colorGrayLight};
    text-align: center;

    p {
      line-height: 1.4;
      font-size: 12px;
    }
  }
`;

export const BtnGroups = styled.div`
  && {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;

    button {
      max-width: 160px;
      height: 40px;
      padding: 10px 15px;
      margin: 0 10px;
      text-transform: uppercase;
    }
  }
`;

export const ButtonAccept = styled(ButtonCustom).attrs({
  type: "button",
  size: "small",
})``;

export const BtnDownload = styled.a`
  & {
    width: 100%;
    max-width: 160px;
    height: 40px;
    padding: 10px 15px;
    color: #fff;
    font-weight: 600;
    text-transform: uppercase;
    color: ${Theme.colorBlueDark};
    background-color: transparent;
    border: 1px solid ${Theme.colorBlueDark};
    border-radius: 6px;
    transition: all 0.6s linear;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    margin: 0 10px;

    .icon-download {
      margin-right: 10px;
      font-size: 14px;
      color: ${Theme.colorBlueDark};
      transition: all 0.6s linear;
    }

    &:hover {
      color: ${Theme.colorWhite};
      background-color: ${Theme.colorBlueDark};

      .icon-download {
        color: ${Theme.colorWhite};
      }
    }
  }
`;

export const BoxSignature = styled.div`
  && {
    width: calc(100% - 60px);
    height: 300px;
    position: relative;
    margin: auto;

    &:before {
      width: calc(100% - 40px);
      height: 1px;
      content: "";
      border-bottom: 3px dashed ${Theme.colorGray};
      position: absolute;
      top: 60%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;

export const DocumentAccept = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${Theme.colorBlueDark};
  font-size: 18px;
  font-weight: 500;

  .icon {
    color: ${Theme.colorGreen};
    margin-right: 20px;
    position: relative;

    &:after {
      width: 30px;
      height: 30px;
      content: "";
      border: 2px solid ${Theme.colorBlueDark};
      border-radius: 5px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: -1;
    }
  }
`;

export const NoContent = styled.div`
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  text-align: center;
  color: ${Theme.colorGray};
`;
