import { useEffect, useState, useRef } from "react";
import Api from "../../service/api";
import { useAuth } from "../../contexts/Auth.contexts";
import { useHistory } from "react-router-dom";

import { Utils } from "utils";
import { useTranslation } from "react-i18next";

const usePendingEmailConfirmation = () => {
  const isMounted = useRef(true);
  const history = useHistory();
  const { profile, isPendingEmailConfirmation, setIsPendingEmailConfirmation, emailPending } =
    useAuth();

  const [messageDefault] = useState(
    `Digite o código de verificação enviado para o e-mail ${emailPending} para confirmar o seu cadastro.`
  );
  const [message, setMessage] = useState("");

  const [resendEmail, setResendEmail] = useState(false);
  const [emailConfirmed, setEmailConfirmed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [codeVerification, setCodeVerification] = useState("");
  const [disabledButton, setDisabledButton] = useState(true);
  const [openMessage, setOpenMessage] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState(false);
  const { t: translate } = useTranslation();
  const handleChangeCodeVerification = (e) => {
    const value = e.target.value;
    const onlyNums = value.replace(/[^0-9]/g, "");
    if (onlyNums.length <= 6) {
      setCodeVerification(onlyNums);
    }
    setDisabledButton(onlyNums.length < 6);
  };

  const handleSendEmailConfimation = async () => {
    setLoading(true);
    await Api.post("/email-confirmation/send-email").then((response) => {
      if (response.status === 200 && isMounted.current) {
        const message = response.data.success
          ? "Email enviado com sucesso!"
          : "Erro ao enviar email";
        setAlertSeverity(response.data.success ? "success" : "error");
        setMessage(message);
        setResendEmail(true);
      }
      if (response.status === 400 && isMounted.current) {
        setMessage("Erro ao enviar email");
        setAlertSeverity("error");
      }
      setOpenMessage(true);
      setLoading(false);
    });
  };

  const handleCodeVerification = async () => {
    setLoading(true);
    await Api.post("/email-confirmation/verify-email", {
      codeVerification,
    }).then((response) => {
      if (response.status === 200 && isMounted.current) {
        const message = response.data.message;
        setMessage(message);
        setResendEmail(true);
        if (response.data.isValidated) {
          setEmailConfirmed(true);
          setMessage(response.data.message);
          setIsPendingEmailConfirmation(false);
        }
        {
          //setEmailConfirmed(false);
          setMessage(response.data.message ?? "Erro ao verificar email");
          setAlertSeverity("error");
          setOpenMessage(true);
        }
        setLoading(false);
      }
    });
  }

  const handleLogout = () => {
    history.push(Utils.setPrefix("/logout"));
  }


  const goLink = (link) => history.push(Utils.setPrefix(link));

  useEffect(() => {
    let timeoutId = setTimeout(() => {
      setLoading(false);
      if (!isPendingEmailConfirmation) {
        history.push(Utils.setPrefix("/"));
      }
    }, 3000);

    return () => {
      clearTimeout(timeoutId);
      isMounted.current = false;
    };
  }, [emailConfirmed]);

  return {
    message,
    messageDefault,
    handleSendEmailConfimation,
    resendEmail,
    loading,
    handleLogout,
    profile,
    handleChangeCodeVerification,
    codeVerification,
    disabledButton,
    handleCodeVerification,
    emailConfirmed,
    setOpenMessage,
    openMessage,
    alertSeverity,
    isPendingEmailConfirmation,
    translate,
    goLink
  };
};

export default usePendingEmailConfirmation;
