import styled from "styled-components";
import Text from "components/text";
import Theme from "Theme";
import Bg from "./images/bg.png";

export const Main = styled.main`
    width: 100%;
    min-height: 100vh;
    background-image: url("${Bg}");
    position: absolute;
    top: 0;
    left: 0;

    &:before {
        width: 100%;
        height: 100%;
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        background-image: linear-gradient(
            139deg,
            rgba(33, 75, 138, 1) 25%,
            rgba(0, 145, 67, 1) 100%
        );
    }

    & > div {
        position: relative;
    }
`;

export const Transition = styled.div`
    transition: all 0.4s ease-in-out;
    opacity: ${(props) => (props.transitionIn ? 1 : 0)};
    transform: translateX(${(props) => (props.transitionIn ? "0" : "30px")});
`;

export const GroupInfo = styled.div`
    margin: auto;
    width: 100%;
    max-width: 500px;
    display: table;
    margin: 200px auto auto;
`;

export const Image = styled.img`
    margin: auto;
    width: 100%;
    max-width: 500px;
    height: auto;
`;

export const Title = styled(Text)`
    font-size: 20px;
    font-weight: 300;
    line-height: 1;
    font-family: "Roboto", sans-serif;
    text-align: center;
    color: ${Theme.colorWhite};
    margin-top: 30px;
    margin-bottom: 30px;
    display: block;
`;

export const Description = styled(Text)`
    font-size: 16px;
    font-weight: 300;
    line-height: 1;
    font-family: "Roboto", sans-serif;
    text-align: center;
    color: ${Theme.colorWhite};
    margin: 25px 0;
    display: block;
`;
