import styled from "styled-components";
import { Snackbar } from "@material-ui/core";
import Theme from "Theme";

const colorAlert = (severity) => {
    if (severity === "success") {
        return {
            bg: Theme.alert.success[0],
            gradient: {
                one: Theme.alert.success[0],
                two: Theme.alert.success[0],
            },
        };
    }

    return {
        bg: Theme.alert.error[0],
        gradient: {
            one: Theme.alert.error[0],
            two: Theme.alert.error[0],
        },
    };
};

export const Message = styled(Snackbar)`
    .MuiPaper-root {
        background-color: ${({ severity }) => colorAlert(severity).bg};
        background-image: linear-gradient(
            to right,
            ${({ severity }) => colorAlert(severity).gradient.one},
            ${({ severity }) => colorAlert(severity).gradient.two}
        );
        .MuiSnackbarContent-message {
            display: table;
            margin: auto;
        }
    }
`;
