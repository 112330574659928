import React, { memo } from "react";
import PropTypes from "prop-types";

const Text = ({ as, children }) => {
  let element = "";
  switch (as) {
    case "h1":
      element = <h1>{children}</h1>;
      break;
    case "h2":
      element = <h2>{children}</h2>;
      break;
    case "h3":
      element = <h3>{children}</h3>;
      break;
    case "h4":
      element = <h4>{children}</h4>;
      break;
    case "h5":
      element = <h5>{children}</h5>;
      break;
    case "h6":
      element = <h6>{children}</h6>;
      break;
    case "span":
      element = <span>{children}</span>;
      break;
    case "p":
      element = <p>{children}</p>;
      break;
    default:
      element = <span>{children}</span>;

      return element;
  }
};

Text.propTypes = {
  as: PropTypes.string.isRequired,
};

export default memo(Text);
