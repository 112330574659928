import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";
import Theme from "Theme";
export default createGlobalStyle`
    ${reset}

    * {
        margin: auto;
        padding: 0;
        border: 0;
        outline: none;
    }

    *, *::before, *::after {
        box-sizing: border-box;
    }

    html, body #root {
        width: 100%;
        height: 100%;
        background-color: ${Theme.colorBodyDefault};
    }

    body {
        width: 100%;
        height: 100%;
        overflow-x: hidden;
        font-family: 'Roboto', sans-serif;
        font-weight: 300;
    }

    #root {
        position: absolute;
    }

    .m-top-10 {
        && {
            margin-top: 10px;
        }
    }

    .m-top-20 {
        && {
            margin-top: 20px;
        }
    }

    .m-top-30 {
        && {
            margin-top: 30px;
        }
    }

    .m-top-40 {
        && {
            margin-top: 40px;
        }
    }

    .m-top-50 {
        && {
            margin-top: 50px;
        }
    }

    .m-top-60 {
        && {
            margin-top: 60px;
        }
    }

    .m-bot-10 {
        && {
            margin-bottom: 10px;
        }
    }

    .m-bot-20 {
        margin-bottom: 20px;
    }

    .m-bot-30 {
        && {
            margin-bottom: 30px;
        }
    }

    .m-bot-40 {
        && {
            margin-bottom: 40px;
        }
    }

    .m-bot-50 {
        && {
            margin-bottom: 50px;
        }
    }

    .m-bot-60 {
        && {
            margin-bottom: 60px;
        }
    }

    .sigCanvas {
        width: 100%;
        height: 300px;
    }

    .justify-content-center {
        justify-content: center;
    }

    .display-none {
        display: none;
    }
`;
