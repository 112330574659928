import React from "react";
import { Container, Grid } from "@material-ui/core";
import useHolerite from "./useHolerite";
import InputSelect from "components/form/inputSelect";
import ModalCustom from "./modalCustom";

import {
  Main,
  Title,
  Transition,
  GroupField,
  FormPage,
  ViewMessageNoPdf,
  WrapDocuments,
  Document,
  Icon,
} from "./Styled";

const Holerite = () => {
  const {
    transitionIn,
    listAnos,
    listHolerites,
    handleChangeAno,
    fieldErrors,
    loadingForm,
    ano,
    messageAlertDownload,
    openMessage,
    translate,
    handleOpenModal,
    handleCloseModal,
    contentModal,
    openModal,
    modalType,
    loadingModal,
  } = useHolerite();

  const ButtonDocument = ({ label, onClick }) => (
    <Document onClick={onClick}>
      <Icon className="icon icon-document-edit" />
      {label}
      <Icon className="icon icon-view" />
    </Document>
  );

  return (
    <Main>
      <Container>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={6}>
            <Title transition={transitionIn}>
              {translate("holerite.headerPage")}
            </Title>
            <Transition transitionIn={transitionIn}>
              <FormPage
                transition={transitionIn}
                method="post"
                autoComplete="off"
                loadingForm={loadingForm}
              >
                <GroupField>
                  <InputSelect
                    transparent="false"
                    name="ano"
                    id="ano"
                    labeldark="true"
                    label={translate("holerite.selecionaAno")}
                    value={ano}
                    onChange={handleChangeAno}
                    options={listAnos}
                    error={
                      fieldErrors.find(
                        (inp) => inp.name === "ano" && inp.error === true
                      )
                        ? true
                        : false
                    }
                  />
                </GroupField>
              </FormPage>
              {listHolerites.length > 0 ? (
                <WrapDocuments>
                  {listHolerites?.map((item, index) => (
                    <ButtonDocument
                      key={index}
                      label={item.descricao}
                      url={item.urlFile}
                      fileName={item.fileName}
                      onClick={() =>
                        handleOpenModal(item.urlFile, item.fileName)
                      }
                    />
                  ))}
                </WrapDocuments>
              ) : (
                <ViewMessageNoPdf as="p" show={openMessage}>
                  {messageAlertDownload}
                </ViewMessageNoPdf>
              )}
            </Transition>
          </Grid>
        </Grid>
      </Container>
      <ModalCustom
        loadingModal={loadingModal}
        open={openModal}
        onClose={handleCloseModal}
        content={contentModal}
        modalType={modalType}
      />
    </Main>
  );
};

export default Holerite;
