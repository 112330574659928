import styled from "styled-components";
import { SwipeableDrawer, List, ListItem } from "@material-ui/core";

import ButtonCustom from "components/buttonCustom";
import SelectLang from "components/SelectLan";
import Theme from "Theme";

export const Header = styled.header`
  width: ${(props) => (props.pageTermOfUse === true ? "100px" : "100%")};
  height: 144px;
  background-color: ${(props) =>
    props.pageTermOfUse === true
      ? "transparent"
      : Theme.colorLightBgCommunication};
  position: fixed;
  top: 0;
  right: 0;
  margin-bottom: ${(props) => (props.pageTermOfUse === true ? "0" : "20px")};
  transition: all 0.3s ease-in-out;
  opacity: ${(props) => (props.transition ? 1 : 0)};
  transform: translateY(${(props) => (props.transition ? 0 : "-10px")});
  z-index: 10;

  @media (max-width: 768px) {
    justify-content: center;
    position: absolute;
  }
`;

export const Inner = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    justify-content: flex-start;
  }
`;

export const Logo = styled.img`
  margin: 0;
  transition: all 0.3s ease-in-out;
  opacity: ${(props) => (props.transition ? 1 : 0)};
  transform: translateX(${(props) => (props.transition ? 0 : "30px")});

  @media (max-width: 768px) {
    max-width: 160px;
    margin-left: 20px;
    margin-bottom: 20px;
  }
`;

export const Drawer = styled(SwipeableDrawer)``;

export const MenuList = styled(List)`
  && {
    width: 300px;

    @media (max-width: 768px) {
      width: 270px;
    }
  }
`;

export const MenuListItem = styled(ListItem)`
  && {
    padding: 15px 20px;

    .MuiFormControl-root {
      width: 300px;
      margin: 30px auto;

      @media (max-width: 768px) {
        width: 270px;
      }
    }
  }
`;

export const BtnOpenMenu = styled(ButtonCustom)`
  && {
    width: 30px;
    height: 25px;
    position: absolute;
    right: 30px;
    padding: 0;

    .MuiButton-label {
      span {
        width: 30px;
        height: 2px;
        border-radius: 1px;
        content: "";
        background-color: ${Theme.colorBlueDark};
        position: relative;
        top: -7px;
        left: 0;

        &:before,
        &:after {
          width: 30px;
          height: 2px;
          border-radius: 1px;
          content: "";
          background-color: ${Theme.colorBlueDark};
          position: absolute;
          left: 0;
        }

        &:before {
          top: 8px;
        }

        &:after {
          top: 16px;
        }
      }
    }
  }
`;

export const BtnOpenMenuIcon = styled.span`
  && {
    transition: all 0.3s ease-in-out;
    opacity: ${(props) => (props.transition ? 1 : 0)};
    transform: translateX(${(props) => (props.transition ? 0 : "10px")});
  }
`;

export const SelectLangMenu = styled(SelectLang)`
  && {
    .MuiFormLabel-root {
      color: ${Theme.formText.label.default};
      font-weight: 400;
      padding: 0 20px;
      top: 15%;
      z-index: 1;

      &.Mui-error,
      .MuiFormLabel-asterisk.Mui-error {
        color: ${Theme.formText.label.error};
      }
    }

    .MuiInputLabel-shrink,
    .MuiInputLabel-root.Mui-focused {
      color: ${(props) =>
        props.labeldark ? Theme.colorBlueDark : Theme.colorBlueDark};
      top: 0;
    }
  }
`;

export const MenuContainerProfile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
`;

export const ProfileImage = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 10px;
`;

export const ProfileName = styled.span`
  font-size: 16px;
  font-weight: 600;
  color: ${Theme.colorBlueDark};
`;

export const LinkHome = styled.a`
  text-decoration: none;
  cursor: pointer;
`;
