import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useWorkWithUs } from "contexts/WorkWithUs.contexts";

const useContact = () => {
  const { t: translate } = useTranslation();
  const {
    dataFields,
    errors,
    updateDataField,
    updateErrors,
    updateStatusError,
  } = useWorkWithUs();

  const handleChangeText = useCallback(
    ({ target }, item) => {
      updateDataField(item, {
        ...{ ...dataFields[item], [target.name]: target.value },
      });

      updateErrors(item, {
        ...{ ...errors[item], [target.name]: false },
      });
      updateStatusError(false);
    },
    [dataFields, errors, updateDataField, updateErrors, updateStatusError]
  );

  return {
    dataFields,
    errors,
    translate,
    handleChangeText,
  };
};

export default useContact;
