import React, { memo } from "react";
import { TBtnUpload, TFile } from "./Styled";

const InputUpload = (props) => (
    <TBtnUpload
        className={props.className}
        color="default"
        type="button"
        size="large"
        fullWidth
    >
        {props.label}
        <TFile
            title={props.labelSelect}
            onChange={props.onChange}
            name={props.name}
            type="file"
            accept="image/x-png,image/gif,image/jpeg"
        />
    </TBtnUpload>
);

export default memo(InputUpload);
