import React, { memo } from "react";
import { Grid } from "@material-ui/core";

import Form, { InputDate, InputCheckbox } from "components/form";
import ButtonCustom from "components/buttonCustom";

import { Modal, ModalTitle, ModalBody, GroupField, Input } from "./Styled";

import useModalProfessionExperience from "./useModalProfessionExperience";

const ModalProfessionExperience = () => {
    const {
        translate,
        fieldsModal,
        openModal,
        errorModal,
        handleCloseModal,
        handleChangeText,
        handleChangeCheckbox,
        handleAddCourse
    } = useModalProfessionExperience();

    return (
        <Modal onClose={handleCloseModal} open={openModal?.name === "ModalProfessionExperience"}>
            <ModalTitle>
                {translate("workWithUs.modalProfessionExperience.title")}
            </ModalTitle>
            <ModalBody>
                <Form>
                    <Grid container justifyContent="center" spacing={2}>
                        <Grid item xs={12} md={10}>
                            <GroupField>
                                <Input
                                    fullWidth
                                    transparent="true"
                                    id="company"
                                    name="company"
                                    label={translate(
                                        "workWithUs.modalProfessionExperience.form.company"
                                    )}
                                    autoComplete="off"
                                    value={fieldsModal?.company || ""}
                                    onChange={(props) =>
                                        handleChangeText(props, "experience")
                                    }
                                    error={errorModal?.company}
                                />
                            </GroupField>
                        </Grid>
                        <Grid item xs={12} md={10}>
                            <GroupField>
                                <Input
                                    fullWidth
                                    transparent="true"
                                    id="office"
                                    name="office"
                                    label={translate(
                                        "workWithUs.modalProfessionExperience.form.office"
                                    )}
                                    autoComplete="off"
                                    value={fieldsModal?.office || ""}
                                    onChange={(props) =>
                                        handleChangeText(props, "experience")
                                    }
                                    error={errorModal?.office}
                                />
                            </GroupField>
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <GroupField>
                                <Input
                                    fullWidth
                                    transparent="true"
                                    id="startDate"
                                    name="startDate"
                                    label={translate(
                                        "workWithUs.modalProfessionExperience.form.startDate"
                                    )}
                                    autoComplete="off"
                                    value={
                                        fieldsModal?.startDate || ""
                                    }
                                    onChange={(props) =>
                                        handleChangeText(props, "experience")
                                    }
                                    error={errorModal?.startDate}
                                    InputProps={{
                                        inputComponent: InputDate,
                                    }}
                                />
                            </GroupField>
                        </Grid>
                        {!fieldsModal?.actualjob &&
                            <Grid item xs={12} md={5}>
                                <GroupField>
                                    <Input
                                        fullWidth
                                        transparent="true"
                                        id="endDate"
                                        name="endDate"
                                        label={translate(
                                            "workWithUs.modalProfessionExperience.form.endDate"
                                        )}
                                        autoComplete="off"
                                        value={
                                            fieldsModal?.endDate || ""
                                        }
                                        onChange={(props) =>
                                            handleChangeText(props, "experience")
                                        }
                                        error={errorModal?.endDate}
                                        InputProps={{
                                            inputComponent: InputDate,
                                        }}
                                    />
                                </GroupField>
                            </Grid>
                        }
                        <Grid item xs={12} md={10}>
                            <InputCheckbox
                                label={translate("workWithUs.modalProfessionExperience.form.Actualjob")}
                                id="actualjob"
                                name="actualjob"
                                value={fieldsModal?.actualjob || ""}
                                checked={fieldsModal?.actualjob || false}
                                onChange={handleChangeCheckbox}
                            />
                        </Grid>
                    </Grid>
                </Form>
            </ModalBody>
            <ButtonCustom className="btn-green" onClick={handleAddCourse}>
                {translate("workWithUs.save")}
            </ButtonCustom>
        </Modal>
    );
};

export default memo(ModalProfessionExperience);
