import { useState, useEffect, useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useWorkWithUs } from "contexts/WorkWithUs.contexts";
import { Utils } from "utils";
import api from "service";

function getSteps() {
  return [
    "Região",
    "Contato",
    "Informações Básicas",
    "Endereço",
    "Cursos Profissionalizantes",
    "Experiência Profissional",
  ];
}

const useRegister = () => {
  const [loadingPage, setLoadingPage] = useState(true);
  const [transitionIn, setTransitionIn] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState([]);
  const [loading, setLoading] = useState(false);
  const steps = getSteps();
  const history = useHistory();

  const { t: translate } = useTranslation();

  const {
    dataFields,
    statusError,
    openModal,
    openAlert,
    handleOpenAlert,
    handleCloseAlert,
    updateErrors,
    updateStatusError,
    clearDataField,
  } = useWorkWithUs();

  document.title = translate("workWithUs.head.register.title");

  const isVigilant = useMemo(() => {
    return dataFields.typeOfVacancy?.type === "1";
  }, [dataFields]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setLoadingPage(false);
    setTimeout(() => {
      setTransitionIn(true);
    }, 100);
  }, []);

  const hadleGoback = () => {
    history.push(Utils.setPrefix(`/trabalhe-conosco/selecione-tipo-vaga`));
  };

  const onValidate = useCallback(
    (step) => {
      let status = statusError;

      const { contact, address, basicInformation, cnv_anexo } = dataFields;

      if (step === 2) {
        let errorContact = {
          cpf: false,
          name: false,
          cellphone: false,
          email: false,
        };
        if (!contact?.cpf || !Utils.validateCPF(contact?.cpf)) {
          errorContact.cpf = true;
          status = true;
        }
        if (!contact?.name || contact?.name.length < 4) {
          errorContact.name = true;
          status = true;
        }
        if (
          !contact?.cellphone ||
          !Utils.validateCellPhone(contact?.cellphone) ||
          Utils.clearMask(contact.cellphone) !==
            Utils.clearMask(contact.cellphone2)
        ) {
          errorContact.cellphone = true;
          status = true;
        }
        if (!contact?.email || !Utils.validateEmail(contact?.email)) {
          errorContact.email = true;
          status = true;
        }

        updateErrors("contact", errorContact);
      }

      if (step === 3) {
        let errorBasicInformation = {
          rg: false,
          motherName: false,
          fatherName: false,
          naturalness: false,
          birthDate: false,
          maritalStatus: false,
          gender: false,
          educationLevel: false,
          educationalInstitution: false,
          weight: false,
          height: false,
          reservist: false,
          pcdCid: false,
        };

        if (basicInformation?.isPcd && !basicInformation?.pcdCid) {
          errorBasicInformation.pcdCid = true;
          status = true;
        }

        if (
          !basicInformation?.rg ||
          Utils.clearMask(basicInformation?.rg).length < 5
        ) {
          errorBasicInformation.rg = true;
          status = true;
        }

        if (!basicInformation?.rgIssuingAgency) {
          errorBasicInformation.rrgIssuingAgencyg = true;
          status = true;
        }

        if (
          !basicInformation?.motherName ||
          Utils.clearMask(basicInformation?.motherName).length < 3
        ) {
          errorBasicInformation.motherName = true;
          status = true;
        }
        if (
          !basicInformation?.fatherName ||
          Utils.clearMask(basicInformation?.fatherName).length < 3
        ) {
          errorBasicInformation.fatherName = true;
          status = true;
        }
        if (!basicInformation?.naturalness) {
          errorBasicInformation.naturalness = true;
          status = true;
        }

        if (!basicInformation?.rgIssuingState) {
          errorBasicInformation.rgIssuingState = true;
          status = true;
        }

        if (!basicInformation?.rgIssuingCity) {
          errorBasicInformation.rgIssuingCity = true;
          status = true;
        }

        //basicInformation.rgIssuingCity
        if (!basicInformation?.naturalnessState) {
          errorBasicInformation.naturalnessState = true;
          status = true;
        }

        if (!basicInformation?.nationality) {
          errorBasicInformation.nationality = true;
          status = true;
        }

        if (!basicInformation?.birthDate) {
          errorBasicInformation.birthDate = true;
          status = true;
        }
        if (!basicInformation?.maritalStatus) {
          errorBasicInformation.maritalStatus = true;
          status = true;
        }
        if (!basicInformation?.gender) {
          errorBasicInformation.gender = true;
          status = true;
        }
        if (!basicInformation?.educationLevel) {
          errorBasicInformation.educationLevel = true;
          status = true;
        }

        /*
        if (isVigilant && !basicInformation?.reservist) {
          errorBasicInformation.reservist = true;
          status = true;
        }
        */

        if (
          isVigilant &&
          !basicInformation?.first_copy_cnv &&
          !basicInformation?.cnv
        ) {
          errorBasicInformation.cnv = true;
          status = true;
        }

        if (!basicInformation?.colorRace) {
          errorBasicInformation.colorRace = true;
          status = true;
        }

        if (
          isVigilant &&
          !basicInformation?.first_copy_cnv &&
          !basicInformation?.cnv
        ) {
          errorBasicInformation.cnv = true;
          status = true;
        }

        if (!basicInformation?.bloodType && isVigilant) {
          errorBasicInformation.bloodType = true;
          status = true;
        }

        if (!basicInformation?.firstJob && !basicInformation?.pis) {
          errorBasicInformation.pis = true;
          status = true;
        }

        if (!basicInformation?.ctpsDigital && !basicInformation?.ctpsNumber) {
          errorBasicInformation.ctpsNumber = true;
          status = true;
        }

        if (!basicInformation?.ctpsDigital && !basicInformation?.ctpsSerie) {
          errorBasicInformation.ctpsSerie = true;
          status = true;
        }

        if (!basicInformation?.ctpsDigital && !basicInformation?.ctpsUf) {
          errorBasicInformation.ctpsUf = true;
          status = true;
        }

        if (
          !basicInformation?.ctpsDigital &&
          !basicInformation?.ctpsDispatchDate
        ) {
          errorBasicInformation.ctpsDispatchDate = true;
          status = true;
        }

        if (
          basicInformation?.driverLicenceCategory &&
          basicInformation?.driverLicenceCategory !== "NT"
        ) {
          if (!basicInformation?.driverLicenceNumber) {
            errorBasicInformation.driverLicenceNumber = true;
            status = true;
          }

          if (!basicInformation?.driverExpirationDate) {
            errorBasicInformation.driverExpirationDate = true;
            status = true;
          }

          if (!basicInformation?.driverLicenceIssuingAgency) {
            errorBasicInformation.driverLicenceIssuingAgency = true;
            status = true;
          }

          if (!basicInformation?.driverLicenceUfIssuing) {
            errorBasicInformation.driverLicenceUfIssuing = true;
            status = true;
          }

          if (!basicInformation?.driverFirstLicenceDate) {
            errorBasicInformation.driverFirstLicenceDate = true;
            status = true;
          }
        }

        if (basicInformation?.typePixKey === "email") {
          if (!Utils.validateEmail(basicInformation?.pixKey)) {
            errorBasicInformation.pixKey = true;
            errorBasicInformation.typePixKey = true;
            status = true;
          }
        }

        updateErrors("basicInformation", errorBasicInformation);
      }

      if (step === 4) {
        let errorAddress = {
          cep: false,
          street: false,
          number: false,
          neighborhood: false,
          city: false,
          state: false,
          publicPlace: false,
        };
        if (!address?.cep || Utils.clearMask(address?.cep).length < 8) {
          errorAddress.cep = true;
          status = true;
        }
        if (!address?.street) {
          errorAddress.street = true;
          status = true;
        }
        if (!address?.publicPlace) {
          errorAddress.publicPlace = true;
          status = true;
        }
        if (!address?.number) {
          errorAddress.number = true;
          status = true;
        }
        if (!address?.neighborhood) {
          errorAddress.neighborhood = true;
          status = true;
        }
        if (!address?.city) {
          errorAddress.city = true;
          status = true;
        }
        if (!address?.state || Utils.clearMask(address?.state).length < 2) {
          errorAddress.state = true;
          status = true;
        }

        updateErrors("address", errorAddress);
      }

      if (step === 5) {
        //console.log([isVigilant , basicInformation?.cnv , cnv_anexo.name])
        let errorSpecializations = {
          cnv_anexo: false,
        };
        if (isVigilant && basicInformation?.cnv && !cnv_anexo?.name) {
          status = true;
          errorSpecializations.cnv_anexo = true;
        }

        updateErrors("specializations", errorSpecializations);
      }

      updateStatusError(status);
      return status;
    },
    [dataFields, isVigilant, statusError, updateErrors, updateStatusError]
  );

  const totalSteps = useCallback(() => {
    return getSteps().length;
  }, []);

  const isLastStep = useCallback(() => {
    return activeStep === totalSteps() - 1;
  }, [activeStep, totalSteps]);

  const onCompleted = useCallback(
    (step) => {
      if (activeStep === step - 1) {
        setCompleted([...completed, step]);
      }
    },
    [activeStep, completed]
  );

  const handleNext = useCallback(() => {
    const newActiveStep = isLastStep()
      ? steps.findIndex((step, i) => !completed.find((item) => item === i))
      : activeStep + 1;

    if (onValidate(newActiveStep)) {
      handleOpenAlert(
        translate("workWithUs.form.validated.fillInTheInformation")
      );
      return;
    }

    setActiveStep(newActiveStep);
    onCompleted(newActiveStep);
  }, [
    isLastStep,
    steps,
    activeStep,
    onValidate,
    onCompleted,
    completed,
    handleOpenAlert,
    translate,
  ]);

  const handleBack = useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    updateStatusError(false);
  }, [updateStatusError]);

  const handleOnRegister = useCallback(async () => {
    const {
      typeOfVacancy,
      typeOfContract,
      regions,
      vtBusLines,
      contact,
      address,
      basicInformation,
      guardTrainingCourse,
      lastRecycle,
      extensionCourse,
      experience,
      curriculum,
      cnv_anexo,
    } = dataFields;

    const newBirthDate = basicInformation?.birthDate
      ? new Date(basicInformation.birthDate)
      : null;
    const newTimeCourse = basicInformation.timeCourse
      ? new Date(basicInformation.timeCourse)
      : null;
    // const newDispatchDate = basicInformation.rgDispatchDate ? new Date(basicInformation.rgDispatchDate) : null;
    const newDriverExpirationDate = basicInformation.driverExpirationDate
      ? new Date(basicInformation.driverExpirationDate)
      : null;

    const newCnhPrimeiraHabilitacao = basicInformation.driverFirstLicenceDate
      ? new Date(basicInformation.driverFirstLicenceDate)
      : null;

    const newCtpsEmissaoDate = basicInformation.ctpsDispatchDate
      ? new Date(basicInformation.ctpsDispatchDate)
      : null;
    const data_nascimento = newBirthDate
      ? `${newBirthDate.getDate().toString().padStart(2, "0")}/${(
          newBirthDate.getMonth() + 1
        )
          .toString()
          .padStart(2, "0")}/${newBirthDate.getFullYear()}`
      : "";

    /*
const rg_data_expedicao = (newDispatchDate) ? `${newDispatchDate
  .getDate()
  .toString()
  .padStart(2, "0")}/${(newDispatchDate.getMonth() + 1)
    .toString()
    .padStart(2, "0")}/${newDispatchDate.getFullYear()}` : '';
*/
    const cnh_validade = newDriverExpirationDate
      ? `${newDriverExpirationDate.getDate().toString().padStart(2, "0")}/${(
          newDriverExpirationDate.getMonth() + 1
        )
          .toString()
          .padStart(2, "0")}/${newDriverExpirationDate.getFullYear()}`
      : "";

    const cnh_primeira_habilitacao = newCnhPrimeiraHabilitacao
      ? `${newCnhPrimeiraHabilitacao.getDate().toString().padStart(2, "0")}/${(
          newCnhPrimeiraHabilitacao.getMonth() + 1
        )
          .toString()
          .padStart(2, "0")}/${newCnhPrimeiraHabilitacao.getFullYear()}`
      : "";

    const ctps_emissao = newCtpsEmissaoDate
      ? `${newCtpsEmissaoDate.getDate().toString().padStart(2, "0")}/${(
          newCtpsEmissaoDate.getMonth() + 1
        )
          .toString()
          .padStart(2, "0")}/${newCtpsEmissaoDate.getFullYear()}`
      : "";

    const periodo = newTimeCourse
      ? `${newTimeCourse.getDate().toString().padStart(2, "0")}/${(
          newTimeCourse.getMonth() + 1
        )
          .toString()
          .padStart(2, "0")}/${newTimeCourse.getFullYear()}`
      : "";

    const info_basica = {
      cpf: Utils.clearMask(contact.cpf),
      rg: basicInformation.rg,
      rg_orgao_emissor: basicInformation.rgIssuingAgency,
      estado_emissao_rg: basicInformation.rgIssuingState,
      cidade_emissao_rg: basicInformation.rgIssuingCity.value,
      nome_mae: basicInformation.motherName,
      nome_pai: basicInformation.fatherName,
      nacionalidade: basicInformation.nationality.value,
      naturalidade: basicInformation.naturalness.value,
      estado_natal: basicInformation.naturalnessState,
      data_nascimento,
      estado_civil: basicInformation.maritalStatus,
      sexo: basicInformation.gender,
      tipo_sanguineo: basicInformation.bloodType,
      grau_instrucao: basicInformation.educationLevel,
      instituicao_ensino: basicInformation.educationalInstitution,
      curso: basicInformation.course,
      periodo,
      covid_declaration: basicInformation.covid_declaration ? 1 : 2,
      cnv: basicInformation.cnv,
      first_copy_cnv: basicInformation.first_copy_cnv,
      voter_registration: basicInformation.voter_registration,
      voter_registration_zone: basicInformation.voter_registration_zone,
      voter_registration_section: basicInformation.voter_registration_section,
      pis: basicInformation.pis ? Utils.clearMask(basicInformation.pis) : "",
      ctps_numero: basicInformation.ctpsNumber,
      ctps_serie: basicInformation.ctpsSerie,
      ctps_uf: basicInformation.ctpsUf,
      ctps_data_expedicao: ctps_emissao,
      cnh_numero: basicInformation.driverLicenceNumber,
      cnh_categoria: basicInformation.driverLicenceCategory,
      cnh_orgao_emissor: basicInformation.driverLicenceIssuingAgency,
      cnh_validade,
      cnh_primeira_habilitacao,
      cnh_uf_emissor: basicInformation.driverLicenceUfIssuing,
      peso: basicInformation.weight,
      altura: basicInformation.height,
      tamanho_calca: basicInformation.sizePant,
      tamanho_camisa: basicInformation.sizeShirt,
      tamanho_camisete: basicInformation.sizeTshirt,
      tamanho_jaqueta: basicInformation.sizeJacket,
      tamanho_calcado: basicInformation.sizeShoe,
      disponibilidade_turno: basicInformation.availabilityPeriod,
      disponibilidade_escala: basicInformation.availabilityTimetable,
      reservista: basicInformation.reservist,
      primeiro_emprego: basicInformation.firstJob ? "S" : "N",
      ctps_digital: basicInformation.ctpsDigital ? "S" : "N",
      candidato_pcd: basicInformation.isPcd ? 1 : 0,
      candidato_vitima_violencia_medida_protetiva: basicInformation.vvdmp
        ? 1
        : 0,
      cor_raca: basicInformation.colorRace,
      pcd_cid: (basicInformation.isPcd && basicInformation.pcdCid) || "",
    };

    const formData = new FormData();
    formData.append("is_web_encoded", true);
    formData.append("tipo_trabalho", typeOfVacancy.type);
    formData.append("tipo_vaga", typeOfContract);
    formData.append("demais_vagas", JSON.stringify(typeOfVacancy.options));
    formData.append("nome", contact.name);
    formData.append("cpf", Utils.clearMask(contact.cpf));
    formData.append("celular", Utils.clearMask(contact.cellphone));
    formData.append("email", contact.email);
    formData.append("cep", Utils.clearMask(address.cep));
    formData.append("endereco", address.street);
    formData.append("logradouro", address.publicPlace);
    formData.append("cidade", address.city);
    formData.append("uf", address.state);
    formData.append("numero", address.number);
    formData.append("bairro", address.neighborhood);
    formData.append("complemento", address.complement ?? "");
    formData.append("info_basica", JSON.stringify(info_basica));
    if (curriculum) formData.append("curriculo", curriculum.file);
    if (cnv_anexo) formData.append("cnv_anexo", cnv_anexo.file);

    const course1 =
      guardTrainingCourse?.map((item) => {
        const newDateOfTheConclusion = new Date(item.dateOfTheConclusion);

        const data_conclusao = `${newDateOfTheConclusion
          .getDate()
          .toString()
          .padStart(2, "0")}/${(newDateOfTheConclusion.getMonth() + 1)
          .toString()
          .padStart(2, "0")}/${newDateOfTheConclusion.getFullYear()}`;

        if (item.file && item.file.name)
          formData.append(
            `course_files[${item.name}-${item.academy}]`,
            item.file
          );
        return {
          curso: item.name,
          academia: item.academy,
          data_conclusao,
          tipo: "formacao",
        };
      }) || [];

    const course2 =
      lastRecycle?.map((item) => {
        const newDateOfTheConclusion = new Date(item.dateOfTheConclusion);
        const data_conclusao = `${newDateOfTheConclusion
          .getDate()
          .toString()
          .padStart(2, "0")}/${(newDateOfTheConclusion.getMonth() + 1)
          .toString()
          .padStart(2, "0")}/${newDateOfTheConclusion.getFullYear()}`;
        if (item.file && item.file.name)
          formData.append(
            `course_files[${item.name}-${item.academy}]`,
            item.file
          );
        return {
          curso: item.name,
          academia: item.academy,
          data_conclusao,
          tipo: "reciclagem",
        };
      }) || [];

    const course3 =
      extensionCourse?.map((item) => {
        const newDateOfTheConclusion = new Date(item.dateOfTheConclusion);
        const data_conclusao = `${newDateOfTheConclusion
          .getDate()
          .toString()
          .padStart(2, "0")}/${(newDateOfTheConclusion.getMonth() + 1)
          .toString()
          .padStart(2, "0")}/${newDateOfTheConclusion.getFullYear()}`;
        if (item.file && item.file.name)
          formData.append(
            `course_files[${item.name}-${item.academy}]`,
            item.file
          );
        return {
          curso: item.name,
          academia: item.academy,
          data_conclusao,
          tipo: "extensao",
        };
      }) || [];
    formData.append(
      "formacao",
      JSON.stringify([...course1, ...course2, ...course3])
    );

    const experiencia =
      experience?.map((item) => ({
        empresa: item.company,
        cargo: item.office,
        data_inicio: item.startDate,
        data_termino: item.endDate !== "Atualmente" ? item.endDate : "",
        emprego_atual: item.endDate !== "Atualmente" ? "N" : "S",
      })) || [];

    formData.append("experiencia", JSON.stringify(experiencia));

    onCompleted(5);

    setLoading(true);

    const regioes =
      regions && regions.options && regions.options.length
        ? regions.options.map((region) => region.label)
        : [];

    const vt_bus_lines =
      vtBusLines && vtBusLines.options && vtBusLines.options.length
        ? vtBusLines.options.map((bus_line) => bus_line.label)
        : [];

    formData.append("regioes", JSON.stringify(regioes));
    formData.append("vt_bus_lines", JSON.stringify(vt_bus_lines));

    try {
      await api.post("/trabalhe-conosco/registrar", formData).then((data) => {
        const response = JSON.parse(data.request.response);
        if (response.status === false) {
          setLoading(false);
          handleOpenAlert(response.message);
          return;
        }
        setLoading(false);
        clearDataField();
        history.push(Utils.setPrefix("/trabalhe-conosco/cadastro/finalizado"));
      });
    } catch (error) {
      error.response.data.map((item) => handleOpenAlert(item));
      setLoading(false);
    }
  }, [clearDataField, dataFields, handleOpenAlert, history, onCompleted]);

  return {
    translate,
    transitionIn,
    loadingPage,
    steps,
    activeStep,
    completed,
    openModal,
    openAlert,
    loading,
    handleCloseAlert,
    hadleGoback,
    handleNext,
    handleBack,
    handleOnRegister,
  };
};

export default useRegister;
