import React from "react";
import { Container, Grid } from "@material-ui/core";

import useAddMessage from "./useAddMessage";
import Loading from "components/loading";
import ButtonCustom from "components/buttonCustom";
import AlertCustom from "components/alertCustom";
import InputSelect from "components/form/inputSelect";

import {
  Main,
  Title,
  TitleIcon,
  Icon,
  BtnGroup,
  Transition,
  FormPage,
  GroupField,
  LabelField,
  InputDate,
  InputTextArea,
  InputBtnUpload,
  ListPreviewFotos,
  PreviewFoto,
  MessageAnexo,
} from "./Styled";

import ComunImg from "./images/talk.png";

const Home = () => {
  const {
    transitionIn,
    loadingPage,
    loadingForm,
    optionsArea,
    optionsRegional,
    hasSelectMultiple,
    area,
    tipo,
    regional,
    data,
    previewFotos,
    comentario,
    fieldErrors,
    openAlert,
    messageAlert,
    optionsTipo,
    setOpenAlert,
    handleChangeArea,
    handleChangeTipo,
    handleRemoveItemSelect,
    handleChangeRegional,
    handleChangeData,
    handleChangeFotos,
    handleRemoveFoto,
    handleChangeComentario,
    handleSubmit,
    hadleGoback,
    translate,
  } = useAddMessage();

  if (loadingPage) {
    return <Loading />;
  }

  return (
    <React.Fragment>
      <Main>
        <Container>
          <Title transition={transitionIn}>
            <TitleIcon src={ComunImg} />
            {translate("addMessage.title")}
          </Title>

          <Grid container justifyContent="center">
            <Grid item xs={11} md={5}>
              <BtnGroup transition={transitionIn}>
                <ButtonCustom
                  color="default"
                  type="button"
                  size="large"
                  className="btn-goback"
                  startIcon={<Icon className="icon icon-arrow" color="black" />}
                  onClick={hadleGoback}
                  fullWidth
                >
                  {translate("addMessage.btnGoBack")}
                </ButtonCustom>
              </BtnGroup>

              <Transition transitionIn={transitionIn}>
                <FormPage
                  transition={transitionIn}
                  encType="multipart/form-data"
                  method="post"
                  autoComplete="off"
                  loadingForm={loadingForm}
                  onSubmit={handleSubmit}
                >
                  <GroupField>
                    <InputSelect
                      transparent="false"
                      name="area"
                      id="area"
                      labeldark="true"
                      label={translate("addMessage.selectArea")}
                      value={area}
                      onChange={handleChangeArea}
                      options={optionsArea}
                      error={
                        fieldErrors.find(
                          (inp) => inp.name === "area" && inp.error === true
                        )
                          ? true
                          : false
                      }
                    />
                  </GroupField>
                  <GroupField>
                    <InputSelect
                      transparent="false"
                      multiple={hasSelectMultiple}
                      name="tipo"
                      id="tipo"
                      labeldark="true"
                      label={translate("addMessage.selectTipo")}
                      value={tipo}
                      onRemoveItemSelectMultiple={handleRemoveItemSelect}
                      onChange={handleChangeTipo}
                      options={optionsTipo}
                      error={
                        fieldErrors.find(
                          (inp) => inp.name === "tipo" && inp.error === true
                        )
                          ? true
                          : false
                      }
                    />
                  </GroupField>
                  <GroupField>
                    <InputSelect
                      transparent="false"
                      name="regional"
                      id="regional"
                      labeldark="true"
                      label={translate("addMessage.selectRegional")}
                      value={regional}
                      onChange={handleChangeRegional}
                      options={optionsRegional}
                      error={
                        fieldErrors.find(
                          (inp) => inp.name === "regional" && inp.error === true
                        )
                          ? true
                          : false
                      }
                    />
                  </GroupField>
                  <GroupField>
                    <LabelField>{translate("addMessage.labelDataRef")}</LabelField>
                    <InputDate
                      fullWidth
                      type="date"
                      id="data"
                      name="data"
                      autoComplete="off"
                      value={data}
                      onChange={handleChangeData}
                      error={
                        fieldErrors.find(
                          (inp) => inp.name === "data" && inp.error === true
                        )
                          ? true
                          : false
                      }
                    />
                  </GroupField>
                  <GroupField>
                    <InputTextArea
                      fullWidth
                      multiline
                      minRows={6}
                      maxRows={10}
                      id="comentario"
                      name="comentario"
                      label={translate("addMessage.textareaComentario")}
                      autoComplete="off"
                      value={comentario}
                      onChange={handleChangeComentario}
                      error={
                        fieldErrors.find(
                          (inp) =>
                            inp.name === "comentario" && inp.error === true
                        )
                          ? true
                          : false
                      }
                    />
                  </GroupField>
                  <GroupField flex button>
                    {previewFotos.length > 0 && (
                      <ListPreviewFotos>
                        {previewFotos.map((item, index) => (
                          <PreviewFoto
                            key={index}
                            src={item.img}
                            fileName={item.fileName}
                            onClick={() => handleRemoveFoto(item)}
                          />
                        ))}
                      </ListPreviewFotos>
                    )}

                    <InputBtnUpload
                      name="fotos"
                      label={translate("addMessage.btnAnexo")}
                      className="btn-anexar"
                      labelSelect={translate("addMessage.titleSelectFoto")}
                      onChange={handleChangeFotos}
                      error={
                        fieldErrors.find(
                          (inp) => inp.name === "fotos" && inp.error === true
                        )
                          ? true
                          : false
                      }
                    />
                    <ButtonCustom
                      color="default"
                      type="submit"
                      size="large"
                      className="btn-green"
                      fullWidth
                    >
                      {translate("addMessage.btnSend")}
                    </ButtonCustom>
                    <MessageAnexo id="msg-anexo" />
                  </GroupField>
                </FormPage>
              </Transition>
            </Grid>
          </Grid>
        </Container>
      </Main>
      <AlertCustom
        message={messageAlert}
        openMessage={openAlert}
        closeMessage={() => setOpenAlert(false)}
        transition="down"
        origin={{ vertical: "top", horizontal: "right" }}
      />
    </React.Fragment>
  );
};

export default Home;
