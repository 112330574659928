import styled from "styled-components";
import Form from "components/form";
import InputText from "components/form/InputText";
import InputUpload from "components/form/InputUpload";
import PreviewImage from "components/form/PreviewImage";
import Theme from "Theme";

export const Main = styled.main`
  margin-top: 250px;
  position: relative;
  overflow-x: hidden;

  @media (max-width: 768px) {
    margin-top: 180px;
  }

  &:before {
    width: 100%;
    height: 100%;
    content: "";
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: ${Theme.colorLightBgCommunication};
  }

  & > div {
    position: relative;
  }
`;

export const Title = styled.h1`
  font-size: 22px;
  font-weight: bold;
  color: ${Theme.colorBlack};
  text-align: center;
  transition: all 0.3s ease-in-out;
  opacity: ${(props) => (props.transition ? 1 : 0)};
  transform: translateX(${(props) => (props.transition ? 0 : "-30px")});
  margin: 20px auto 30px;
`;

export const TitleIcon = styled.img`
  max-width: 50px;
  height: auto;
  margin: auto auto 15px;
  display: table;
`;

export const Icon = styled.span`
  && {
    font-size: 22px;
    font-weight: normal;
    color: ${(props) => props.color ?? Theme.colorWhite};
  }
`;

export const BtnGroup = styled.div`
  && {
    transition: all 0.3s ease-in-out;
    opacity: ${(props) => (props.transition ? 1 : 0)};
    transform: translateX(${(props) => (props.transition ? 0 : "-30px")});
    margin: 40px 0 15px;

    .btn-goback {
      margin: 0;
    }
  }
`;

export const Transition = styled.div`
  transition: all 0.4s ease-in-out;
  opacity: ${(props) => (props.transitionIn ? 1 : 0)};
  transform: translateY(${(props) => (props.transitionIn ? "0" : "30px")});
`;

export const FormPage = styled(Form)`
  width: 100%;
  margin-bottom: 80px;
`;

export const GroupField = styled.div`
  margin-bottom: 10px;
  display: ${(props) => (props.flex ? "flex" : "block")};
  flex-wrap: ${(props) => (props.flex ? "wrap" : "no-row")};
  align-items: ${(props) => (props.flex ? "center" : "top")};
  justify-content: ${(props) => (props.button ? "space-between" : "start")};

  .btn-anexar,
  .btn-green {
    max-width: calc(50% - 10px);
    margin-top: 10px;
  }
`;

export const LabelField = styled.div`
  font-size: 14px;
  color: ${Theme.colorBlack};
  flex: 0 0 auto;
  margin-top: 15px;
  margin-right: 20px;
  margin-bottom: 10px;
`;

export const InputDate = styled(InputText)`
  && {
    width: 100%;
    .MuiInputLabel-shrink,
    .MuiInputLabel-root.Mui-focused {
      color: ${(props) =>
        props.labeldark ? Theme.colorBlack : Theme.colorBlack};
      top: 0;

      &.Mui-error,
      .MuiFormLabel-asterisk.Mui-error {
        color: ${Theme.formText.label.error};
      }
    }
  }
`;

export const InputTextArea = styled(InputText)`
  && {
    .MuiFormLabel-root {
      top: 6px;
    }

    .MuiInputLabel-shrink,
    .MuiInputLabel-root.Mui-focused {
      color: ${(props) =>
        props.labeldark ? Theme.colorBlack : Theme.colorBlack};
      top: 0;

      &.Mui-error,
      .MuiFormLabel-asterisk.Mui-error {
        color: ${Theme.formText.label.error};
      }
    }
  }
`;

export const ListPreviewFotos = styled.div`
  && {
    flex: 0 0 100%;
    width: 100%;
    min-height: 70px;
    max-height: 200px;
    margin-top: 10px;
    overflow-y: auto;
    
  }
`;

export const PreviewFoto = styled(PreviewImage)`

`;

export const InputBtnUpload = styled(InputUpload)``;

export const MessageAnexo = styled.p`
  color: red;
  margin-top: 10px;
`;
