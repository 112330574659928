import React from "react";
import { useHistory } from "react-router-dom";
import { Utils } from "utils";
import { MenuItem, Icon, ImageIcon, IconLabel } from "../Styled";

const ItemMenu = ({ label, route, iconImg, external = false, visible = true }) => {
  const history = useHistory();

  const handlePage = (routeName, external = false) => {
    !external
      ? history.push(Utils.setPrefix(`/${routeName}`))
      : window.open(routeName, "_blank");
  };

  if (!visible) return null;

  return (
    <MenuItem onClick={() => handlePage(route, external)}>
      <Icon>
        <ImageIcon src={iconImg} alt={label} />
        <IconLabel>{label}</IconLabel>
      </Icon>
    </MenuItem>
  );
};

export default ItemMenu;
