import React, { memo } from "react";
import ButtonBase from "@material-ui/core/ButtonBase";

import { RadioCustomDefault } from "./Styled";

const InputRadio = ({ icon, label, name, value, onChange, checked }) => (
    <RadioCustomDefault htmlFor={name}>
        <ButtonBase>
            <input
                type="radio"
                id={name}
                name={name}
                value={value}
                onClick={onChange}
                onChange={onChange}
                checked={checked}
            />
            <div className="bg">
                <i className={`icon ${icon}`} />
                <span>{label}</span>
            </div>
        </ButtonBase>
    </RadioCustomDefault>
);

export default memo(InputRadio);