import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Utils } from "utils";

const useHeaderPage = () => {
  const [transitionIn, setTransitionIn] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const { t: translate } = useTranslation();

  const { pathname } = location;
  const pagePathname = Utils.setPrefix("/termos-de-uso");

  useEffect(() => {
    setTimeout(() => {
      setTransitionIn(true);
    }, 500);
  }, []);

  const toggleDrawer = (open) => {
    setOpenMenu(open);
  };

  const hadleGoLink = (link) => {
    setOpenMenu(false);
    history.push(Utils.setPrefix(link));
  };

  return {
    transitionIn,
    openMenu,
    pathname,
    pagePathname,
    toggleDrawer,
    hadleGoLink,
    translate,
  };
};

export default useHeaderPage;
