import React from "react";
import { Container, Grid } from "@material-ui/core";
import Loading from "components/loading";
import ButtonCustom from "components/buttonCustom";
import useRegistrationCompleted from "./useRegistrationCompleted";
import { Utils } from "utils";

import {
    Main,
    HeaderPage,
    BtnGroupGoBack,
    Anime,
    GroupSend,
    IconSend,
    Title,
    Text,
    Icon,
} from "../Styled";

const RegistrationCompleted = () => {
    const { transitionIn, loadingPage, translate, hadleGoLink } =
        useRegistrationCompleted();

    if (loadingPage) {
        return <Loading />;
    }

    return (
        <React.Fragment>
            <Main>
                <HeaderPage>
                    <BtnGroupGoBack transition={transitionIn}>
                        <ButtonCustom
                            color="default"
                            type="button"
                            size="large"
                            className="btn-goback"
                            onClick={() =>
                                hadleGoLink(Utils.setPrefix("/login"))
                            }
                            fullWidth
                        >
                            <Icon className="icon icon-arrow" />
                        </ButtonCustom>
                    </BtnGroupGoBack>
                </HeaderPage>
                <Container>
                    <Anime transition={transitionIn}>
                        <IconSend
                            transition={transitionIn}
                            className="icon icon-send"
                        />
                    </Anime>

                    <Grid container className="justify-content-center">
                        <Grid item xs={11} md={10} lg={10} xl={10}>
                            <GroupSend>
                                <Title transition={transitionIn}>
                                    {translate(
                                        "workWithUs.registrationCompleted.title"
                                    )}
                                </Title>

                                <Grid
                                    container
                                    className="justify-content-center"
                                >
                                    <Grid item xs={11} md={10} lg={6} xl={6}>
                                        <Anime transition={transitionIn}>
                                            <Text center>
                                                {translate(
                                                    "workWithUs.registrationCompleted.info"
                                                )}
                                            </Text>
                                        </Anime>
                                    </Grid>
                                </Grid>
                            </GroupSend>
                        </Grid>
                    </Grid>
                </Container>
            </Main>
        </React.Fragment>
    );
};

export default RegistrationCompleted;
