import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const usePage404 = () => {
    const [loadingPage, setLoadingPage] = useState(true);
    const [transitionIn, setTransitionIn] = useState(false);

    const { t: translate } = useTranslation();

    useEffect(() => {
        document.title = translate("page404.head.title");
        window.scrollTo(0, 0);

        setTimeout(() => {
            setLoadingPage(false);
        }, 50);

        setTimeout(() => {
            setTransitionIn(true);
        }, 100);

    });

    return {
        loadingPage,
        transitionIn,
        translate
    }
}

export default usePage404;