import styled from "styled-components";
import ButtonCustom from "components/buttonCustom";
import Theme from "Theme";

export const Item = styled.div`
    width: 100%;
    height: 40px;
    color: ${Theme.colorWhite};
    font-size: 14px;
    font-weight: 400;
    background-color: ${Theme.colorGreen};
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    padding-right: 20px;
    position: relative;

`;

export const ButtonClose = styled(ButtonCustom)`
   && {
        width: 20px;
        height: 100%;
        background: transparent;
        color: ${Theme.colorWhite};
        position: absolute;
        right: 0;

        &:hover {
            background: transparent;
        }
   }
`;


export const Icon = styled.span`
    color: ${Theme.colorWhite};
    font-size: 12px;
`;
