import styled from "styled-components";
import {
    Stepper as StepperCustom,
    Step as StepCustom,
    StepLabel as StepLabelCustom,
    StepConnector as StepConnectorCustom,
} from "@material-ui/core";

import {
    InputText,
    InputUploadDoc as InputUploadDocCustom,
} from "components/form";

import Theme from "Theme";

export const Small = styled.small`
    font-size: .7rem;
    color: ${Theme.colorYellow};
`;

export const Main = styled.main`
    height: 100%;
    position: relative;
    overflow-x: hidden;
    transition: all 0.3s ease-in-out;

    &.open-modal {
        filter: blur(3px);
    }

    &:before {
        width: 100%;
        height: 100%;
        content: "";
        position: fixed;
        bottom: 0;
        left: 0;
        background-color: ${Theme.colorBlack};
    }

    & > div {
        position: relative;
    }
`;

export const Anime = styled.div`
    transition: all 0.5s ease-in-out;
    opacity: ${(props) => (props.transition ? "1" : "0")};
    transform: translateX(${(props) => (props.transition ? "0" : "30px")});
`;

export const HeaderPage = styled.div`
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;

    @media (max-width: 768px) {
        justify-content: space-around;
        position: fixed;
        top: auto;

        h1 {
            margin-left: 20px;
        }
    }

    @media (max-width: 320px) {
        h1 {
            margin-top: 90px;
        }
    }
`;

export const BtnGroupGoBack = styled.div`
    && {
        transition: all 0.3s ease-in-out;
        opacity: ${(props) => (props.transition ? 1 : 0)};
        transform: translateX(${(props) => (props.transition ? 0 : "-30px")});
        position: fixed;
        top: auto;
        left: 20px;
        z-index: 5;

        .btn-goback {
            min-width: 40px;
            min-height: 40px;
            width: 40px;
            height: 40px;
            max-width: 40px;
            max-height: 40px;
            border-radius: 20px;
            color: ${Theme.colorBlack};
            font-weight: 500;
            background-color: ${Theme.colorYellow};
            padding: 0;

            .icon {
                color: ${Theme.colorBlack};
            }

            &:hover,
            &:focus {
                color: ${Theme.colorBlack};
                background-color: ${Theme.colorYellowDark};
            }
        }
    }
`;

export const Title = styled.h1`
    font-size: 22px;
    font-weight: 400;
    color: ${Theme.colorWhite};
    text-align: center;
    transition: all 0.3s ease-in-out;
    opacity: ${(props) => (props.transition ? 1 : 0)};
    transform: translateX(${(props) => (props.transition ? 0 : "-30px")});
`;

export const Subtitle = styled.h1`
    font-size: 18px;
    font-weight: 400;
    color: ${Theme.colorYellow};
    margin: 0 0 20px;
`;

export const Image = styled.img`
    max-width: ${(props) => props?.width || "auto"};
    height: auto;
    margin: auto auto 15px;
    display: table;
`;

export const GroupSend = styled.div`
    && {
        width: 500px;
        height: 100%;
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        h1 {
            font-size: 25px;
            margin-bottom: 20px;
        }

        p {
            font-size: 16px;
        }
    }
`;

export const IconSend = styled.span`
    width: 150px;
    height: 150px;
    background-color: ${Theme.colorYellow};
    color: ${Theme.colorBlack};
    font-size: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 100px auto 30px;
    border-radius: 50%;
`;

export const TitleIcon = styled.img`
    max-width: 50px;
    height: auto;
    margin: auto auto 15px;
    display: table;
`;

export const Icon = styled.span`
    && {
        font-size: 22px;
        font-weight: normal;
        color: ${Theme.colorWhite};
    }
`;

export const BtnGroup = styled.div`
    && {
        transition: all 0.3s ease-in-out;
        opacity: ${(props) => (props.transition ? 1 : 0)};
        transform: translateX(${(props) => (props.transition ? 0 : "-30px")});
        margin: 40px 0 15px;
    }
`;

export const Text = styled.p`
    && {
        font-size: 14px;
        font-weight: ${(props) => props?.bold || "300"};
        text-align: ${(props) => (props.center ? "center" : "left")};
        color: ${Theme.colorWhite};
        line-height: 1.6;
        display: block;
        margin-bottom: 20px;
    }
`;

export const Stepper = styled(StepperCustom)`
    && {
        background-color: transparent;
        padding: 10px 0;
    }
`;

export const StepConnector = styled(StepConnectorCustom)`
    && {
        &.MuiStepConnector-horizontal {
            top: 15px;
            left: 0;
            right: 0;

            .MuiStepConnector-line {
                border-color: #666;
                border-top-width: 8px;
                transition: all 0.3s ease-in-out;
            }

            &.MuiStepConnector-active,
            &.MuiStepConnector-completed {
                .MuiStepConnector-line {
                    border-color: ${Theme.colorGreen};
                }
            }
        }
    }
`;

export const Step = styled(StepCustom)`
    && {
        padding-left: 0;
        padding-right: 0;
        position: relative;

        &:before {
            width: 90%;
            height: 90%;
            content: "";
            background-color: ${Theme.colorWhite};
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border-radius: 50%;
            transition: all 0.3s ease-in-out;
        }
    }
`;

export const StepLabel = styled(StepLabelCustom)`
    && {
        .MuiStepLabel-iconContainer {
            padding-right: 0;
        }
        .MuiSvgIcon-root {
            width: 35px;
            height: 35px;
            color: ${Theme.colorGray};
            transition: all 0.3s ease-in-out;
            z-index: 1;
        }
        .MuiStepIcon-active,
        .MuiStepIcon-completed {
            color: ${Theme.buttonGreen.default[0]};

            .MuiStepIcon-text {
                fill: ${Theme.colorWhite};
            }

            path {
            }
        }
        .MuiStepIcon-text {
            font-weight: 700;
            fill: #c3c3c3;
        }
    }
`;

export const StepContent = styled.div`
    margin-top: 40px;
`;

export const GroupField = styled.div`
    margin-bottom: 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
`;

export const Input = styled(InputText)`
    && {
        .MuiInputLabel-shrink,
        .MuiInputLabel-root.Mui-focused {
            color: ${(props) =>
        props.labeldark ? Theme.colorWhite : Theme.colorWhite};
            top: 0;

            &.Mui-error,
            .MuiFormLabel-asterisk.Mui-error {
                color: ${Theme.formText.label.error};
            }
        }
    }
`;

export const InputTextArea = styled(InputText)`
    && {
        .MuiFormLabel-root {
            top: 6px;
        }

        .MuiInputLabel-shrink,
        .MuiInputLabel-root.Mui-focused {
            color: ${(props) =>
        props.labeldark ? Theme.colorWhite : Theme.colorWhite};
            top: 0;

            &.Mui-error,
            .MuiFormLabel-asterisk.Mui-error {
                color: ${Theme.formText.label.error};
            }
        }
    }
`;

export const InputUploadDoc = styled(InputUploadDocCustom)`
   
    && {
        color: ${(props) => (props.error ? "red" : "#ffffff")};
        .icon {
            font-size: 20px;
            margin-right: 8px;
        }

    }
`;

export const GroupRadiosCustom = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: 40px -20px 20px;

    label {
        margin: 20px 20px 0;
    }
`;

export const GroupCouses = styled.div`
    margin-top: 30px;

    .btn-dark {
        height: 60px;
        background-color: #121212;
        background-image: linear-gradient(to right, #121212, #121212);
        border: 2px solid #999;

        &:hover {
            background-color: #161616;
            background-image: linear-gradient(to right, #161616, #161616);
        }
    }
`;

export const Mb3 = styled.div`
    margin-bottom: 30px;
`;

export const VTBusLinesContainer = styled.div`
    margin: 16px 0;
`;
