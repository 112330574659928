import styled from "styled-components";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  TablePagination,
} from "@material-ui/core";
import Theme from "Theme";

import { Utils } from "utils";
const minSizeScreen = "431px";

export const Main = styled.main`
  margin-top: 120px;
  position: relative;
  overflow-x: hidden;

  &:before {
    width: 100%;
    height: 100%;
    content: "";
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: ${Theme.colorLightBgCommunication};
  }

  & > div {
    position: relative;
  }
`;

export const Title = styled.h1`
  font-size: 22px;
  font-weight: bold;
  color: ${Theme.colorBlack};
  text-align: center;
  transition: all 0.3s ease-in-out;
  opacity: ${(props) => (props.transition ? 1 : 0)};
  transform: translateX(${(props) => (props.transition ? 0 : "-30px")});
  margin: 30px auto 30px;
  @media (max-width: ${minSizeScreen}) {
    font-size: 0.9rem;
  }
`;

export const TitleIcon = styled.img`
  max-width: 50px;
  height: auto;
  margin: auto auto 15px;
  display: table;
`;

export const Inner = styled.div`
  transition: all 0.6s ease-in-out;
  opacity: ${(props) => (props.transition ? 1 : 0)};
`;

export const TbContainer = styled(TableContainer)`
  margin-bottom: 20px;
`;

export const TbCustom = styled(Table)`
  && {
    border-collapse: separate;
    border-spacing: 0 2px;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    background-color: ${Theme.colorWhite};
    padding: 8px;
  }
`;

export const TbHead = styled(TableHead)`
  && {
    tr {
      cursor: default;
    }
  }
`;

export const TbBody = styled(TableBody)`
  && {
    tr {
      transition: all 0.6s ease-in-out;

      &:hover {
        background-color: ${Theme.colorLightTableRowHover};
      }
    }
  }
`;

export const TbRow = styled(TableRow)`
  && {
    cursor: pointer;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  }
`;

export const TbCell = styled(TableCell)`
  && {
    @media (max-width: ${minSizeScreen}) {
      font-size: 0.7rem;
    }
    padding: 10px 3px;
    color: ${Theme.colorBlack};
    padding-left: 2px;
  }
`;

export const TbFooter = styled(TableFooter)`
  && {
    td {
      cursor: default;
      border-bottom: 0;
    }
  }
`;

export const TbPagination = styled(TablePagination)`
  && {
    color: ${Theme.colorTextTable};

    .MuiTablePagination-spacer {
      flex: 0 0 0;
    }

    .MuiSelect-icon,
    .Mui-disabled {
      color: ${Theme.colorTextTable};
      opacity: 0.6;
    }

    .MuiToolbar-root {
      display: flex;
      justify-content: center;
    }
  }
`;

export const Status = styled.span`
  width: 80px;
  height: 20px;
  border-radius: 6.5px;
  display: table;
  background-color: ${(props) => Utils.statusComunication(props.type)};
  margin: 0 auto;
  color: ${(props) =>
    props.currentStatus === "EM_ANALISE" ? "black" : "white"};

  @media screen and (max-width: ${minSizeScreen}) {
    width: 65px;
    height: 15px;
  }  
`;

export const Icon = styled.span`
  && {
    font-size: 22px;
    font-weight: normal;
    color: ${Theme.colorWhite};
  }
`;

export const BtnGroup = styled.div`
  && {
    margin: 40px 0 80px;
    display: flex;
    justify-content: center;

    .btn-green {
      max-width: 250px;
      margin: auto;
      transition: all 0.3s ease-in-out;
      opacity: ${(props) => (props.transition ? 1 : 0)};
      transform: translateX(${(props) => (props.transition ? 0 : "-30px")});
    }
  }
`;

export const GroupField = styled.div`
  margin-bottom: 10px;
  display: flex;
  flex-wrap: no-row;
  align-items: top;
  justify-content: center;

  .btn-anexar,
  .btn-green {
    max-width: calc(50% - 10px);
    margin-top: 10px;
  }
`;

export const HiddenRadioButton = styled.input.attrs({ type: "radio" })`
  position: absolute;
  opacity: 0;
  cursor: pointer;
`;

export const StyledRadioButton = styled.div`
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #343939;
  background-color: ${({ checked }) => (checked ? "#B4B4B4" : "transparent")};
  transition: background-color 0.3s;
  margin-right: 4px;
`;

export const RadioButtonLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 8px;
  color: ${Theme.colorBlack};
  width: 120px;
`;

export const BudgeMessages = styled.span`
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 0 5px;
`;
