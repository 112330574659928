import React, { memo } from "react";
import {
    FormControlSelect,
    LabelSelect,
    TSelect,
    TSelectItem
} from "./Styled";

const InputSelectAutoComplete = (props) => {

    return (
        <FormControlSelect transparent={props.transparent || false}>
            {props.label && (
                <LabelSelect error={props.error}>{props.label}</LabelSelect>
            )}
            <TSelect
                labelId={props.id}
                name={props.name}
                id={props.id}
                value={props.value ?? ''}
                onChange={props.onChange}
                label={props.label}
                error={props.error}
            >
                {props.options.map((item, index) => (
                    <TSelectItem key={index} value={item.value}>
                        {item.label}
                    </TSelectItem>
                ))}
            </TSelect>
        </FormControlSelect>
    );
};

export default memo(InputSelectAutoComplete);
