import React, { memo } from "react";
import { FormControlSelect, TSelect, TSelectItem } from "./Styled";
import useSelectLang from "./useSelectLang";

const SelectLang = () => {
    const { options, lang, handleChange } = useSelectLang();
    return (
        <FormControlSelect disabled>
            <TSelect
                labelId="selectLang"
                id="selectLang"
                value={lang.value}
                label={lang.value}
                onChange={handleChange}
            >
                {options.map((item, index) => (
                    <TSelectItem key={index} value={item.value}>
                        {item.label}
                    </TSelectItem>
                ))}
            </TSelect>
        </FormControlSelect>
    );
};

export default memo(SelectLang);
