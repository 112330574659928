import styled from "styled-components";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";

import Theme from "Theme";

export const FormControlSelect = styled(FormControl)`
    && {
        width: 100%;

        .MuiInputBase-root {
            background-color: ${Theme.formText.bg.default};
            font-weight: 300;
            border-radius: 6px;
            padding: 5px 20px;
            padding-right: 30px;
            overflow: hidden;
            transition: all 0.3s ease-in-out;
            border: 1px solid ${Theme.formText.border.default};
            opacity: ${(props) => (props.disabled ? 0.2 : 1)};

            &:hover {
                background-color: ${Theme.formText.bg.hover};
            }
        }

        .MuiInput-underline {
            &:before {
                border-bottom: 0;
            }
            &:after {
                border-bottom: 2px solid ${Theme.formText.border.focus};
            }
            &:hover {
                &:not(.Mui-disabled) {
                    &:before {
                        border-bottom: 0;
                    }
                }
            }
            &.Mui-error {
                &:after {
                    border-bottom-color: ${Theme.formText.border.error};
                }
            }
        }
    }
`;

export const LabelSelect = styled(InputLabel)`
    && {
        &.MuiFormLabel-root {
            color: ${Theme.formText.label.default};
            font-weight: 400;
            padding: 0 20px;
            top: 15%;
            z-index: 1;

            &.Mui-error,
            .MuiFormLabel-asterisk.Mui-error {
                color: ${Theme.formText.label.error};
            }
        }

        &.MuiInputLabel-shrink,
        &.MuiInputLabel-root.Mui-focused {
            color: ${(props) =>
                props.labeldark ? Theme.colorWhite : Theme.colorWhite};
            top: 0;

            &.Mui-error,
            .MuiFormLabel-asterisk.Mui-error {
                color: ${Theme.formText.label.error};
            }
        }
    }
`;

export const TSelect = styled(Select)`
    && {
        .MuiSelect-select {
            &,
            &:focus {
                background-color: transparent;
            }
        }
    }
`;

export const TSelectItem = styled(MenuItem)`
    && {
        font-size: 14px;
    }
`;
