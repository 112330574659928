import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { useWorkWithUs } from "contexts/WorkWithUs.contexts";

const useSpecializations = () => {
  const { t: translate } = useTranslation();
  const {
    dataFields,
    updateDataField,
    handleOpenModal,
    errors,
    updateErrors,
    updateStatusError,
  } = useWorkWithUs();

  const handleChangeFile = useCallback(
    ({ target }) => {
      if (!target.files[0]) {
        return;
      }
      const item = "specializations";
      updateDataField([target.name], {
        file: target.files[0],
        name: target.files[0].name,
      });
      updateErrors(item, {
        ...{ ...errors[item], [target.name]: false },
      });
      updateStatusError(false);
    },
    [updateDataField, updateStatusError, updateErrors]
  );

  const handleRemoveFile = useCallback(
    (name) => {
      updateDataField([name], {});
    },
    [updateDataField]
  );

  const handleRemoveCourse = useCallback(
    (area, name) => {
      const newCourses = dataFields[area]?.filter((item) => item.name !== name);
      updateDataField(area, newCourses);
    },
    [dataFields, updateDataField]
  );

  return {
    translate,
    dataFields,
    handleOpenModal,
    handleRemoveCourse,
    handleChangeFile,
    handleRemoveFile,
    errors,
  };
};

export default useSpecializations;
