import React, { memo } from "react";
import { Modal, Backdrop, Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import SignatureCanvas from "react-signature-canvas";

import ButtonCustom from "components/buttonCustom";

import {
    ContainerModal,
    ContentModal,
    HeaderModal,
    TitleModal,
    BodyModal,
    Inner,
    ScrollY,
    TextHTML,
    BtnGroups,
    BtnCloseModal,
    ButtonAccept,
    Icon,
    BoxSignature,
    FooterModal,
    DocumentAccept,
    NoContent,
} from "./Styled";

const RenderPDF = ({ urlPdf, status, onShowAccept, onClose }) => {
    const { t: translate } = useTranslation();

    if (urlPdf === "") {
        return null;
    }

    return (
        <Inner className="inner__pdf">

            <object aria-labelledby={translate("modalCustom.viewPdf")} data={urlPdf} type="application/pdf"></object>

            <a className="view__pdf--link" href={urlPdf} target="_blank" rel="noopener noreferrer">{translate("modalCustom.viewPdf")}</a>

            <BtnGroups className="btn__group--pdf">
                {status ? (
                    <>
                        <ButtonAccept color="primary" onClick={onClose}>
                            {translate("modalCustom.btnDoNotAccept")}
                        </ButtonAccept>
                        <ButtonAccept
                            color="default"
                            className="btn-green"
                            onClick={() => onShowAccept("signature")}
                        >
                            {translate("modalCustom.btnToAccept")}
                        </ButtonAccept>
                    </>
                ) : (
                    <DocumentAccept>
                        <Icon className="icon icon-check" />{" "}
                        {translate("modalCustom.termAlreadyAccepted")}
                    </DocumentAccept>
                )}
            </BtnGroups>
        </Inner>
    );
};

const RenderHTML = ({ textHtml, status, onShowAccept, onClose }) => {
    const { t: translate } = useTranslation();

    if (textHtml === "") {
        return null;
    }

    return (
        <Inner className="inner__text--html">
            <ScrollY className="scroll__inner-html">
                <TextHTML
                    dangerouslySetInnerHTML={{
                        __html: textHtml,
                    }}
                />
            </ScrollY>
            <BtnGroups className="btn__group--pdf">
                {status ? (
                    <>
                        <ButtonAccept color="primary" onClick={onClose}>
                            {translate("modalCustom.btnDoNotAccept")}
                        </ButtonAccept>
                        <ButtonAccept
                            color="default"
                            className="btn-green"
                            onClick={() => onShowAccept("signature")}
                        >
                            {translate("modalCustom.btnToAccept")}
                        </ButtonAccept>
                    </>
                ) : (
                    <DocumentAccept>
                        <Icon className="icon icon-check" />{" "}
                        {translate("modalCustom.termAlreadyAccepted")}
                    </DocumentAccept>
                )}
            </BtnGroups>
        </Inner>
    );
};

const RenderContentModal = ({ props }) => {
    const { t: translate } = useTranslation();
    if (props.modalType === "signature") {
        return (
            <>
                <BoxSignature>
                    <SignatureCanvas
                        penColor="black"
                        backgroundColor="white"
                        ref={props.refSignature}
                        canvasProps={{ className: "sigCanvas" }}
                    />
                </BoxSignature>
                <FooterModal>
                    <Grid container justify="center">
                        <Grid item xs={11} md={10}>
                            <p>{translate("modalCustom.helperAssinatura")}</p>
                            <BtnGroups>
                                <ButtonCustom
                                    color="primary"
                                    type="button"
                                    size="large"
                                    onClick={() => props.handleShowAccept("")}
                                    fullWidth
                                >
                                    {translate("modalCustom.btnGoBack")}
                                </ButtonCustom>
                                <ButtonCustom
                                    color="default"
                                    type="button"
                                    size="large"
                                    className="btn-cancel"
                                    onClick={props.handleClearSignature}
                                    fullWidth
                                >
                                    {translate("modalCustom.btnClear")}
                                </ButtonCustom>
                                <ButtonCustom
                                    color="default"
                                    type="button"
                                    size="large"
                                    className="btn-green"
                                    onClick={props.handleSendSignature}
                                    fullWidth
                                >
                                    {translate("modalCustom.btnSend")}
                                </ButtonCustom>
                            </BtnGroups>
                        </Grid>
                    </Grid>
                </FooterModal>
            </>
        );
    }

    return (
        <>
            {props?.content?.urlPdf === "" &&
                props?.content?.textHtml === "" && (
                    <NoContent>{translate("modalCustom.noContent")}</NoContent>
                )}
            <RenderPDF
                urlPdf={props?.content?.urlPdf}
                onShowAccept={props.handleShowAccept}
                status={props?.content?.status !== "ACEITO"}
                onClose={props.onClose}
            />
            <RenderHTML
                textHtml={props?.content?.textHtml}
                status={props?.content?.status !== "ACEITO"}
                onShowAccept={props.handleShowAccept}
                onClose={props.onClose}
            />
        </>
    );
};

const ModalCustom = (props) => {
    return (
        <Modal
            open={props.open}
            onClose={props.onClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <ContainerModal>
                <ContentModal loadingModal={props.loadingModal}>
                    <HeaderModal>
                        {props.content.title !== undefined &&
                            !props.loadingModal && (
                                <TitleModal>{props.content.title}</TitleModal>
                            )}
                        <BtnCloseModal onClick={props.onClose}>
                            <Icon className="icon icon-close" />
                        </BtnCloseModal>
                    </HeaderModal>
                    <BodyModal>
                        {!props.loadingModal && (
                            <RenderContentModal {...{ props }} />
                        )}
                    </BodyModal>
                </ContentModal>
            </ContainerModal>
        </Modal>
    );
};

export default memo(ModalCustom);
