import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Utils } from "utils";
import mqtt from "mqtt";

import Api from "service/api";

import { useAuth } from "../../contexts/Auth.contexts";

const data = [];

const useHome = () => {
  const [loadingPage, setLoadingPage] = useState(false);
  const [loadClosedMessages, setLoadClosedMessages] = useState(false);
  const [generalStatus, setGeneralStatus] = useState("opened");
  const [transitionIn, setTransitionIn] = useState(false);
  const [listMessages, setListMessages] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage] = useState(0);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalRows: 0,
    totalItems: 0,
    itemsPerPage: 10,
    totalPages: 0,
  });
  const { notificationIncoming } = useAuth();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const history = useHistory();
  const { t: translate } = useTranslation();

  const currentLang = window.localStorage.i18nextLng;

  document.title = translate("home.head.title");

  useEffect(() => {
    window.scrollTo(0, 0);
    getData(page);
  }, [loadClosedMessages, notificationIncoming]);

  const getData = async (currentPage = 1) => {
    setLoadingPage(true);
    const action = loadClosedMessages ? "closed" : "opened";
    await Api.get(
      `/comunicacao-altum/${action}?order=DESC&limit=10&page=${currentPage}`
    )
      .then((response) => {
        setListMessages(response.data.listagem);
        const paginationInfo = {
          currentPage: response.data.currentPage,
          totalRows: response.data.totalRows,
          totalItems: response.data.totalItems,
          itemsPerPage: response.data.itemsPerPage,
          totalPages: response.data.totalPages,
        };

        setPagination(paginationInfo);
        setLoadingPage(false);
        setTimeout(() => {
          setTransitionIn(true);
        }, 100);
      })
      .catch((error) => {
        setLoadingPage(true);
        setTimeout(() => {
          setTransitionIn(true);
        }, 100);
        console.log(error);
      });
  };

  const handleShowMessage = (id, nofificacoesIds) => {
    history.push(
      Utils.setPrefix(`/mensagem/${id}?nofificacoesIds=${nofificacoesIds}`)
    );
  };

  const handleShowAddMessage = () => {
    history.push(Utils.setPrefix(`/adicionar-mensagem`));
  };

  const handleChangePage = (event, newPage) => {
    getData(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);

    const newData =
      event.target.value > 0
        ? data.slice(
            page * event.target.value,
            page * event.target.value + event.target.value
          )
        : data;

    setListMessages(newData);
  };

  const handleLoadMessages = (event) => {
    setGeneralStatus(event.target.value);
    setLoadClosedMessages(event.target.value === "closed");
  };

  return {
    transitionIn,
    loadingPage,
    listMessages,
    page,
    totalPage,
    rowsPerPage,
    currentLang,
    handleChangePage,
    handleChangeRowsPerPage,
    handleShowMessage,
    handleShowAddMessage,
    handleLoadMessages,
    loadClosedMessages,
    generalStatus,
    translate,
    Utils,
    pagination,
  };
};

export default useHome;
