import { useState, useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useWorkWithUs } from "contexts/WorkWithUs.contexts";
import { Utils } from "utils";

const useModalAddCourse = () => {
  const [fieldsModal, setFieldsModal] = useState({});
  const [errorModal, setErrorModal] = useState({});
  const [attachCertificateText, setAttachCertificateText] =
    useState("Anexar certificado");
  const [certificateFile, setCertificateFile] = useState({});
  const { dataFields, openModal, updateDataField, handleCloseModal } =
    useWorkWithUs();
  const { t: translate } = useTranslation();

  const inputFileRef = useRef(null);

  const onValidate = useCallback(() => {
    const newErrors = {
      name: false,
      academy: false,
      dateOfTheConclusion: false,
    };
    let status = false;

    if (!fieldsModal?.name || fieldsModal?.name.length < 3) {
      newErrors.name = true;
      status = true;
    }
    if (!fieldsModal?.academy || fieldsModal?.academy.length < 3) {
      newErrors.academy = true;
      status = true;
    }
    if (
      !fieldsModal?.dateOfTheConclusion ||
      Utils.clearMask(fieldsModal?.dateOfTheConclusion).length < 8
    ) {
      newErrors.dateOfTheConclusion = true;
      status = true;
    }

    setErrorModal({ ...newErrors });
    return status;
  }, [fieldsModal.academy, fieldsModal.dateOfTheConclusion, fieldsModal.name]);

  const handleChangeText = useCallback(
    ({ target }) => {
      setFieldsModal({ ...fieldsModal, [target.name]: target.value });
      setErrorModal({ ...errorModal, [target.name]: false });
    },
    [fieldsModal, errorModal]
  );

  const handleChangeDate = useCallback(
    (date, name, fn = () => {}) => {
      setFieldsModal({ ...fieldsModal, [name]: date });
      setErrorModal({ ...errorModal, [name]: false });

      fn();
    },
    [fieldsModal, errorModal]
  );

  const handleAddCourse = useCallback(() => {
    const courses =
      dataFields[openModal.extra] !== undefined
        ? [...dataFields[openModal.extra]]
        : [];

    if (onValidate()) {
      return;
    }

    courses.push({
      name: fieldsModal?.name,
      academy: fieldsModal?.academy,
      dateOfTheConclusion: fieldsModal?.dateOfTheConclusion,
      file: certificateFile,
    });

    updateDataField(openModal.extra, courses);
    handleCloseModal(true);
    setFieldsModal({});

    setCertificateFile({});
    setAttachCertificateText("Adicionar certificado");
  }, [
    dataFields,
    openModal.extra,
    onValidate,
    fieldsModal.name,
    fieldsModal.academy,
    fieldsModal.dateOfTheConclusion,
    certificateFile,
    updateDataField,
    handleCloseModal,
  ]);

  const handleChangeFileCertificate = useCallback((e) => {
    setCertificateFile(e.target?.files[0] || {});

    setAttachCertificateText(e.target?.files[0]?.name || "Anexar certificado");
  }, []);

  return {
    translate,
    attachCertificateText,
    inputFileRef,
    fieldsModal,
    dataFields,
    errorModal,
    openModal,
    handleChangeFileCertificate,
    handleCloseModal,
    handleChangeText,
    handleChangeDate,
    handleAddCourse,
  };
};

export default useModalAddCourse;
