import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { useWorkWithUs } from "contexts/WorkWithUs.contexts";

const ProfessionalExperience = () => {
    const { t: translate } = useTranslation();
    const { dataFields, updateDataField, handleOpenModal } = useWorkWithUs();

    const handleRemoveCourse = useCallback(
        (area, obj) => {
            const newCourses = dataFields[area]?.filter((item) => item !== obj);
            updateDataField(area, newCourses);
        },
        [dataFields, updateDataField]
    );

    const handleChangeFile = useCallback(
        ({ target }) => {
            if (!target.files[0]) {
                return;
            }
            updateDataField([target.name], {
                file: target.files[0],
                name: target.files[0].name,
            });
        },
        [updateDataField]
    );

    const handleRemoveFile = useCallback(
        (name) => {
            updateDataField([name], {});
        },
        [updateDataField]
    );

    return {
        translate,
        dataFields,
        handleOpenModal,
        handleRemoveCourse,
        handleChangeFile,
        handleRemoveFile,
    };
};

export default ProfessionalExperience;
