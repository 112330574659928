import React from "react";
import Public from "./routes/Public.router";
import ChangePassword from "./routes/ChangePassword.router";
import PendingConfimationEmail from "./routes/PendingConfirmationEmail.router";
import PrivateTermsOfUse from "./routes/PrivateTermsOfUse.router";
import Private from "./routes/Private.router";
import FiredPrivate from "./routes/FiredPrivate.router";
import { useAuth } from "./contexts/Auth.contexts";

const Router = () => {
  const {
    signed,
    updatePassword,
    contractAccepted,
    isPendingEmailConfirmation,
    isFired
  } = useAuth();

  if (updatePassword === true) {
    return <ChangePassword />;
  }

  if (signed === true && isPendingEmailConfirmation === true) {
    return <PendingConfimationEmail />;
  }

  if (signed === true && contractAccepted === false) {
    return <PrivateTermsOfUse />;
  }

  if (signed === true && contractAccepted === true) {
    return isFired ? <FiredPrivate/> : <Private />;
  }

  return <Public />;
};

export default Router;
