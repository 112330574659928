import React, { memo } from "react";
import ButtonBase from "@material-ui/core/ButtonBase";

import { RadioCustom } from "./Styled";

const InputRadioCustom = ({ icon, label, name, value, onChange, checked }) => (
    <RadioCustom htmlFor={name}>
        <ButtonBase>
            <input
                type="radio"
                id={name}
                name={name}
                value={value}
                onClick={onChange}
                onChange={onChange}
                checked={checked}
            />
            <div className="bg">
                <i className={`icon ${icon}`} />
                <span>{label}</span>
            </div>
        </ButtonBase>
    </RadioCustom>
);

export default memo(InputRadioCustom);
