import React, { memo } from "react";
import {
    TAutocomplete,
    TField
} from "./Styled";

const InputAutoComplete = (props) => {
    return (
        <TAutocomplete
            fullWidth
            id={props.id}
            name={props.name}
            options={props.options}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => <TField error={props.error} {...params} label={props.label} variant="outlined" />}
            onChange={props.onChange}
            value={props.value}
            error={props.error}
        />
    );
};

export default memo(InputAutoComplete);
