import { useCallback } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useWorkWithUs } from "contexts/WorkWithUs.contexts";

const useAddress = () => {
  const { t: translate } = useTranslation();
  const {
    dataFields,
    errors,
    updateDataField,
    updateErrors,
    updateStatusError,
  } = useWorkWithUs();

  const logradouroList = [
    { label: "Rua", value: "R" },
    { label: "Avenida", value: "AV" },
    { label: "Alameida", value: "AL" },
    { label: "Destrito", value: "DT" },
    { label: "Estrada", value: "EST" },
    { label: "Lote", value: "LT" },
    { label: "Outros", value: "O" },
  ];

  const getAddressByCep = useCallback(
    async ({ name, value }, item) => {
      const cepNumber = value.replace("-", "").trim();

      if (name !== "cep" || cepNumber.length < 8) {
        return;
      }

      const { data } = await axios.get(
        `https://viacep.com.br/ws/${cepNumber}/json/`
      );

      if (data === undefined || data.erro === true) {
        return;
      }
      const [logradouroType] =
        data.logradouro !== undefined ? data.logradouro.split(" ", 2) : "";
      //const addressName = data.logradouro.replace(logradouroType,'').trim();
      const addressName = data.logradouro;
      const logradouroItem = logradouroList.filter(
        (logradouro) => logradouro.label === logradouroType
      );
      updateDataField(item, {
        ...{
          ...dataFields[item],
          cep: data.cep,
          street: addressName,
          neighborhood: data.bairro,
          city: data.localidade,
          state: data.uf,
          publicPlace: logradouroItem[0].value,
        },
      });

      if (data.logradouro) {
        document.getElementById("number").focus();
      }
    },
    [dataFields, updateDataField, logradouroList]
  );

  const handleChangeText = useCallback(
    ({ target }, item) => {
      updateDataField(item, {
        ...{ ...dataFields[item], [target.name]: target.value },
      });

      updateErrors(item, {
        ...{ ...errors[item], [target.name]: false },
      });
      updateStatusError(false);

      getAddressByCep(target, item);
    },

    [
      dataFields,
      errors,
      getAddressByCep,
      updateDataField,
      updateErrors,
      updateStatusError,
    ]
  );

  return {
    dataFields,
    errors,
    translate,
    handleChangeText,
    logradouroList,
  };
};

export default useAddress;
