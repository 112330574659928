import React, { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import ApiEad from "service/api_ead";
import { useAuth } from "contexts/Auth.contexts";
import { Utils } from "utils";
import Loading from "components/loading";

const URL_EAD = "https://compliance.sagris.com.br";

const Ead = () => {
  const history = useHistory();
  const { profile, setAlertMessageDetails, setGlobalAlert } = useAuth();

  const [loading, setLoading] = useState(true);
  const verifyToken = async (token) => {
    await ApiEad.get(`user`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (response.data.success) {
          console.log("usuario altenticado");
          window.open(`${URL_EAD}`, "_blank");
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        loginEad();
      });
  };

  const loginEad = async () => {
    const pass = localStorage.getItem("ead_pass").split("-")[1];
    await ApiEad.post(`login`, {
      email: profile.pess_usuario,
      password: pass,
    })
      .then((response) => {
        const token = response.data.user.access_token;
        localStorage.setItem("ead_token", token);
        window.open(`${URL_EAD}/altum-app/${token}`, "_blank");
        setLoading(false);
      })
      .catch((error) => {
        setAlertMessageDetails({
          title: "Erro",
          message: "Erro ao tentar acessar o EAD, procure a sua empresa.",
          severity: "error",
        });
        setGlobalAlert(true);
        setLoading(false);
      });
  };

  const init = useCallback(async () => {
    const tokenEad = localStorage.getItem("ead_token");
    if (!tokenEad) {
      loginEad();
      return;
    }
    await verifyToken(tokenEad);
  }, [verifyToken]);

  useEffect(() => {
    setTimeout(() => {
      init();
    }, 2000);
  }, []);

  if (!loading) {
    history.push(Utils.setPrefix(`/`));
  }

  return <Loading />;
};

export default Ead;
