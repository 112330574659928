import styled from "styled-components";
import Theme from "Theme";

export const Main = styled.main`
    margin-top: 180px;
    position: relative;
    overflow-x: hidden;

    &:before {
        width: 100%;
        height: 100%;
        content: "";
        position: fixed;
        bottom: 0;
        left: 0;
        background-color: ${Theme.colorGreen};
    }

    & > div {
        position: relative;
    }
`;

export const Transition = styled.div`
    transition: all 0.4s ease-in-out;
    opacity: ${(props) => (props.transitionIn ? 1 : 0)};
    transform: translateX(${(props) => (props.transitionIn ? "0" : "30px")});
`;

export const Header = styled.header`
    width: 100%;
    height: 144px;
    background-color: ${Theme.colorBgTermsOfUser};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 40px 0;
    margin-bottom: 20px;
    position: relative;

    @media (max-width: 768px) {
        justify-content: center;
    }
`;

export const Logo = styled.img`
    margin: 0;
    transition: all 0.3s ease-in-out;
    opacity: ${(props) => (props.transition ? 1 : 0)};
    transform: translateX(${(props) => (props.transition ? 0 : "30px")});

    @media (max-width: 768px) {
        max-width: 160px;
        margin-bottom: 20px;
    }
`;

export const Inner = styled.div`
    max-width: 300px;
    margin: auto;
`;

export const Title = styled.h1`
    font-size: 22px;
    font-weight: bold;
    color: ${Theme.colorWhite};
    text-align: center;
    transition: all 0.6s ease-in-out;
    opacity: ${(props) => (props.transition ? 1 : 0)};
    margin: 10vh auto 30px;
`;

export const TitleIcon = styled.img`
    max-width: 180px;
    height: auto;
    margin: auto auto 30px;
    display: table;

    @media (max-width: 768px) {
        max-width: 100px;
    }
`;

export const Icon = styled.span`
    && {
        font-size: 22px;
        font-weight: normal;
        color: ${Theme.colorWhite};
    }
`;

export const BtnGroup = styled.div`
    && {
        transition: all 0.3s ease-in-out;
        opacity: ${(props) => (props.transition ? 1 : 0)};
        transform: translateX(${(props) => (props.transition ? 0 : "-30px")});
        margin: 40px 0;

        button {
            margin-bottom: 10px;
        }
    }
`;

export const Text = styled.p`
    && {
        font-size: 16px;
        font-weight: ${(props) =>
            props.bold !== undefined ? props.bold : "400"};
        text-align: center;
        color: ${Theme.colorWhite};
        line-height: 1.8;
        display: block;
        margin-bottom: 40px;
    }
`;
