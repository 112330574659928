import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useWorkWithUs } from "contexts/WorkWithUs.contexts";

const useModalSelectOptions = () => {
  const { dataFields, openModal, updateDataField, handleCloseModal } =
    useWorkWithUs();
  const { t: translate } = useTranslation();

  const handleChange = useCallback(
    ({ target }) => {
      if (!target.checked) {
        updateDataField(target.name, {
          ...dataFields[target.name],
          options: [
            ...dataFields[target.name].options.filter(
              (item) => item !== target.value
            ),
          ],
        });
        return;
      }

      updateDataField(target.name, {
        ...dataFields[target.name],
        options: [...dataFields[target.name].options, target.value],
      });
    },
    [dataFields, updateDataField]
  );

  const getValuesCheckeds = (name, value) => 
      dataFields[name]?.options.find((item) =>  parseInt(item) === value);
  
  

  return {
    translate,
    openModal,
    dataFields,
    handleCloseModal,
    handleChange,
    getValuesCheckeds,
  };
};

export default useModalSelectOptions;
