import styled from "styled-components";
import { Button } from "@material-ui/core";
import Theme from "Theme";

export const BtnDefault = styled(Button)`
    && {
        width: 100%;
        height: 100%;
        color: ${Theme.colorWhite};
        font-weight: 600;
        text-transform: none;
        background-color: ${Theme.buttonMain.default[0]};
        background-image: linear-gradient(
            to right,
            ${Theme.buttonMain.default[0]},
            ${Theme.buttonMain.default[1]}
        );
        border: 0;
        border-radius: 6px;
        transition: all 0.6s linear;

        &:hover {
            background-color: ${Theme.buttonMain.hover[0]};
            background-image: linear-gradient(
                to right,
                ${Theme.buttonMain.hover[0]},
                ${Theme.buttonMain.hover[1]}
            );
        }

        &:disabled {
            color: ${Theme.colorWhite};
            opacity: 0.6;
        }

        &.btn-align-left {
            .MuiButton-label {
                justify-content: left;
            }
        }

        &.btn-green {
            background-color: ${Theme.buttonGreen.default[0]};
            background-image: linear-gradient(
                to right,
                ${Theme.buttonGreen.default[0]},
                ${Theme.buttonGreen.default[1]}
            );

            &:hover {
                background-color: ${Theme.buttonGreen.hover[0]};
                background-image: linear-gradient(
                    to right,
                    ${Theme.buttonGreen.hover[0]},
                    ${Theme.buttonGreen.hover[1]}
                );
            }
        }

        &.btn-blue {
            background-color: ${Theme.buttonBlue.default[0]};
            background-image: linear-gradient(
                to right,
                ${Theme.buttonBlue.default[0]},
                ${Theme.buttonBlue.default[1]}
            );

            &:hover {
                background-color: ${Theme.buttonBlue.hover[0]};
                background-image: linear-gradient(
                    to right,
                    ${Theme.buttonBlue.hover[0]},
                    ${Theme.buttonBlue.hover[1]}
                );
            }
        }

        &.btn-yellow {
            color: ${Theme.colorBlack};
            font-weight: 500;
            background-color: ${Theme.colorYellow};
            background-image: linear-gradient(
                to right,
                ${Theme.colorYellow},
                ${Theme.colorYellow}
            );

            &:hover {
                color: ${Theme.colorBlack};
                background-color: ${Theme.colorYellowDark};
                background-image: linear-gradient(
                    to right,
                    ${Theme.colorYellowDark},
                    ${Theme.colorYellowDark}
                );
            }
        }

        &.btn-cancel {
            background-color: ${Theme.buttonRed.default[0]};
            background-image: linear-gradient(
                to right,
                ${Theme.buttonRed.default[0]},
                ${Theme.buttonRed.default[1]}
            );

            &:hover {
                background-color: ${Theme.buttonRed.hover[0]};
                background-image: linear-gradient(
                    to right,
                    ${Theme.buttonRed.hover[0]},
                    ${Theme.buttonRed.hover[1]}
                );
            }
        }

        &.btn-goback {
            width: auto;
            background-color: transparent;
            background-image: none;
            padding: 8px 0;
            color: black;

            .icon {
                font-size: 16px;
            }

            &:hover {
                background-color: transparent;
                background-image: none;
            }
        }

        &.btn-home {
            background-color: transparent;
            background-image: none;
            border: 1px solid ${Theme.colorWhite};

            &:hover {
                background-color: ${Theme.buttonGreen.hover[0]};
            }
        }

        &.btn-open-menu {
            width: 30px;
            height: 30px;
            background-color: transparent;
            background-image: none;
            border: 0;

            &:hover {
                background-color: transparent;
            }
        }
    }
`;

export const BtnPrimary = styled(Button)`
    && {
        width: 100%;
        height: 100%;
        color: #fff;
        font-weight: 600;
        text-transform: none;
        color: ${Theme.colorBlueDark};
        background-color: ${Theme.buttonGray.default[0]};
        background-image: linear-gradient(
            to right,
            ${Theme.buttonGray.default[0]},
            ${Theme.buttonGray.default[1]}
        );
        border: 0;
        border-radius: 6px;
        transition: all 0.6s linear;

        &:hover {
            background-color: ${Theme.buttonGray.hover[0]};
            background-image: linear-gradient(
                to right,
                ${Theme.buttonGray.hover[0]},
                ${Theme.buttonGray.hover[1]}
            );
        }

        &:disabled {
            color: ${Theme.colorBlueDark};
            opacity: 0.6;
        }

        &.btn-align-left {
            .MuiButton-label {
                justify-content: left;
                padding: 0 15px;
            }
        }
    }
`;

export const BtnSecondary = styled(Button)`
    && {
        width: 100%;
        height: 100%;
        color: #fff;
        font-weight: 600;
        text-transform: none;
        color: ${Theme.colorWhite};
        background-color: ${Theme.buttonBlue.default[0]};
        background-image: linear-gradient(
            to right,
            ${Theme.buttonBlue.default[0]},
            ${Theme.buttonBlue.default[1]}
        );
        border: 0;
        border-radius: 6px;
        transition: all 0.6s linear;

        &:hover {
            background-color: ${Theme.buttonBlue.hover[0]};
            background-image: linear-gradient(
                to right,
                ${Theme.buttonBlue.hover[0]},
                ${Theme.buttonBlue.hover[1]}
            );
        }

        &:disabled {
            color: ${Theme.colorWhite};
            opacity: 0.6;
        }

        &.btn-align-left {
            .MuiButton-label {
                justify-content: left;
                padding: 0 15px;
                text-align: left;

                @media (max-width: 768px) {
                    line-height: 1.3;
                }
            }
        }
    }
`;
