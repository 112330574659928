import React, { useCallback, useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { InputSelect, InputCheckbox, InputAutoComplete } from "components/form";
import useBasicInformation from "./useBasicInformation";
import ButtonCustom from "components/buttonCustom";

import { InputMaskPisNumber } from "components/form";
import { Subtitle, GroupField, Input } from "../../../Styled";

import { Modal, ModalBody, ModalTitle, PcdCidField } from "./Styled";
import { Small } from "../../../Styled";
import { DatePicker } from "components/DatePicker";


const BasicInformation = () => {
  const {
    translate,
    dataFields,
    errors,
    getOptionsMaritalStatus,
    getOptionsGender,
    getOptionsBloodType,
    getOptionsEducationLevel,
    getDriveLicenceCategories,
    getColorRace,
    nationalities,
    isVigilant,
    handleChangeText,
    handleChangeSelect,
    handleChangeAutocomplete,
    handleStateChangeSelect,
    handleChangeCheckbox,
    handleChangeDate,
    listHeight,
    listSizesShoes,
    listSizesShirts,
    listSizesTshirts,
    listSizesPants,
    listStates,
    listCities,
    listIssuingCities,
    driverExpirationDate,
    setDriverExpirationDate,
    isCnhRequired,
    setCnhRequired,
    birthDate,
    setBirthDate,
    timeCourse,
    setTimeCourse,
    ctpsDispatchDate,
    setCtpsDispatchDate,
    driverFirstLicenceDate,
    setDriverFirstLicenceDate,
  } = useBasicInformation();

  const [openModal, setOpenModal] = useState(false);
  const [isFirstJobRequired, setIsFirstJobRequired] = useState(
    dataFields.basicInformation?.firstJob
  );
  const [isCtpsDigital, setIsCtpsDigital] = useState(
    dataFields.basicInformation?.ctpsDigital
  );

  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
  }, []);

  const initBirthDate = useCallback(() => {
    setBirthDate(dataFields.basicInformation?.birthDate || null);
  }, []);

  const initTimeCourse = useCallback(() => {
    setTimeCourse(dataFields.basicInformation?.timeCourse || null);
  }, []);

  const initCtpsDispatchDate = useCallback(() => {
    setCtpsDispatchDate(dataFields.basicInformation?.ctpsDispatchDate || null);
  }, []);

  const initDriverExpirationDate = useCallback(() => {
    setDriverExpirationDate(
      dataFields.basicInformation?.driverExpirationDate || null
    );
  }, []);

  const initDriverFirstLicenceDate = useCallback(() => {
    setDriverFirstLicenceDate(
      dataFields.basicInformation?.driverFirstLicenceDate || null
    );
  }, []);

  const init = useCallback(() => {
    initBirthDate();
    initTimeCourse();
    initCtpsDispatchDate();
    initDriverExpirationDate();
    initDriverFirstLicenceDate();
  }, [
    initBirthDate,
    initTimeCourse,
    initCtpsDispatchDate,
    initDriverExpirationDate,
    initDriverFirstLicenceDate,
  ]);

  useEffect(() => {
    init();
  }, [init]);

  return (
    <React.Fragment>
      <Modal open={openModal}>
        <ModalTitle>Primeira via CNV</ModalTitle>

        <ModalBody>
          Ao selecionar primeira via desconsidere o campo CNV
        </ModalBody>

        <ButtonCustom className="btn-green" onClick={handleCloseModal}>
          Entendi
        </ButtonCustom>
      </Modal>
      <Subtitle>
        {translate("workWithUs.form.step3.title")}
        <br />
        <Small>{translate("workWithUs.requiredFields")}</Small>
      </Subtitle>
      <Grid container spacing={2}>
        {isVigilant && (
          <>
            <Grid item xs={12} md={12} lg={9} xl={9}>
              <GroupField>
                <Input
                  fullWidth
                  transparent="true"
                  id="cnv"
                  name="cnv"
                  label={
                    dataFields?.basicInformation?.first_copy_cnv
                      ? translate("workWithUs.form.step3.cnv")
                      : translate("workWithUs.form.step3.cnv") + " (*)"
                  }
                  autoComplete="off"
                  value={dataFields?.basicInformation?.cnv || ""}
                  onChange={(props) =>
                    handleChangeText(props, "basicInformation")
                  }
                  error={errors?.basicInformation?.cnv}
                />
              </GroupField>
            </Grid>
            <Grid item xs={12} md={12} lg={3} xl={3}>
              <GroupField>
                <InputCheckbox
                  label="Primeira via?"
                  name="first_copy_cnv"
                  marginTop={22}
                  checked={!!dataFields?.basicInformation?.first_copy_cnv}
                  onChange={(props) =>
                    handleChangeCheckbox(
                      props,
                      "basicInformation",
                      setOpenModal(props.target.checked)
                    )
                  }
                />
              </GroupField>
            </Grid>

            <Grid item xs={12} md={12} lg={9} xl={9}>
              <GroupField>
                <Input
                  fullWidth
                  transparent="true"
                  id="reservist"
                  name="reservist"
                  label={translate("workWithUs.form.step3.reservist")}
                  autoComplete="off"
                  value={dataFields?.basicInformation?.reservist || ""}
                  onChange={(props) =>
                    handleChangeText(props, "basicInformation")
                  }
                  error={errors?.basicInformation?.reservist}
                />
              </GroupField>
            </Grid>
          </>
        )}

        <Grid item xs={12} md={12} lg={12} xl={12}>
          <GroupField>
            <InputCheckbox
              label="Você é uma pessoa com deficiência ?"
              name="isPcd"
              checked={!!dataFields?.basicInformation?.isPcd}
              onChange={(props) =>
                handleChangeCheckbox(props, "basicInformation")
              }
            />
          </GroupField>
        </Grid>

        <Grid item xs={12} md={12} lg={9} xl={9}>
          <GroupField>
            <PcdCidField
              fullWidth
              transparent="true"
              id="pcdCid"
              isVisible={`${dataFields?.basicInformation?.isPcd}`}
              name="pcdCid"
              label={translate("workWithUs.form.step3.pcdCid")}
              autoComplete="off"
              value={(dataFields?.basicInformation?.isPcd && dataFields?.basicInformation?.pcdCid) || ""}
              onChange={(props) => handleChangeText(props, "basicInformation")}
              error={errors?.basicInformation?.pcdCid}
            />
          </GroupField>
        </Grid>

        <Grid item xs={12} md={12} lg={12} xl={12}>
          <GroupField>
            <InputCheckbox
              label={translate("workWithUs.form.step3.vvdmp")}
              name="vvdmp"
              checked={!!dataFields?.basicInformation?.vvdmp}
              onChange={(props) =>
                handleChangeCheckbox(props, "basicInformation")
              }
            />
          </GroupField>
        </Grid>

        <Grid item xs={12} md={12} lg={6} xl={6}>
          <GroupField>
            <Input
              fullWidth
              transparent="true"
              id="rg"
              name="rg"
              label={translate("workWithUs.form.step3.rg") + "(*)"}
              autoComplete="off"
              value={dataFields?.basicInformation?.rg || ""}
              onChange={(props) => handleChangeText(props, "basicInformation")}
              error={errors?.basicInformation?.rg}
            />
          </GroupField>
        </Grid>

        <Grid item xs={12} md={12} lg={6} xl={6}>
          <GroupField>
            <Input
              fullWidth
              transparent="true"
              id="rgIssuingAgency"
              name="rgIssuingAgency"
              label={translate("workWithUs.form.step3.rgIssuingAgency") + "(*)"}
              autoComplete="off"
              value={dataFields?.basicInformation?.rgIssuingAgency || ""}
              onChange={(props) => handleChangeText(props, "basicInformation")}
              error={errors?.basicInformation?.rgIssuingAgency}
            />
          </GroupField>
        </Grid>

        <Grid item xs={12} md={12} lg={4} xl={4}>
          <GroupField>
            <InputSelect
              fullWidth
              transparent="true"
              id="rgIssuingState"
              name="rgIssuingState"
              label={translate("workWithUs.form.step3.rgIssuingState") + " (*)"}
              labeldark={false}
              autoComplete="off"
              value={dataFields?.basicInformation?.rgIssuingState || ""}
              onChange={(props) =>
                handleStateChangeSelect(
                  props,
                  "rgIssuingCity",
                  "basicInformation",
                  "fetchIssuingCities"
                )
              }
              options={listStates}
              error={errors?.basicInformation?.rgIssuingState}
            />
          </GroupField>
        </Grid>

        <Grid item xs={12} md={12} lg={4} xl={4}>
          <GroupField>
            <InputAutoComplete
              transparent="true"
              name="rgIssuingCity"
              id="rgIssuingCity"
              label={translate("workWithUs.form.step3.rgIssuingCity") + " (*)"}
              value={dataFields?.basicInformation?.rgIssuingCity || null}
              onChange={(props, value) =>
                handleChangeAutocomplete(
                  props,
                  value,
                  "rgIssuingCity",
                  "basicInformation"
                )
              }
              options={listIssuingCities}
              error={errors?.basicInformation?.rgIssuingCity}
            />
          </GroupField>
        </Grid>

        <Grid item xs={12} md={4} lg={4} xl={4}>
          <GroupField>
            <InputSelect
              fullWidth
              transparent="true"
              id="colorRace"
              name="colorRace"
              label={translate("workWithUs.form.step3.colorRace")}
              labeldark={false}
              autoComplete="off"
              value={dataFields?.basicInformation?.colorRace || ""}
              onChange={(props) => handleChangeText(props, "basicInformation")}
              options={getColorRace}
              error={errors?.basicInformation?.colorRace}
            ></InputSelect>
          </GroupField>
        </Grid>

        <Grid item xs={12} md={12} lg={12} xl={12}>
          <GroupField>
            <InputCheckbox
              label="CTPS Digital?"
              name="ctpsDigital"
              checked={!!dataFields?.basicInformation?.ctpsDigital}
              onChange={(props) =>
                handleChangeCheckbox(
                  props,
                  "basicInformation",
                  setIsCtpsDigital(props.target.checked)
                )
              }
            />
          </GroupField>
        </Grid>

        <Grid item xs={12} md={12} lg={4} xl={4}>
          <GroupField>
            <Input
              fullWidth
              transparent="true"
              id="ctpsNumber"
              name="ctpsNumber"
              label={
                !isCtpsDigital
                  ? translate("workWithUs.form.step3.ctpsNumber") + " (*)"
                  : translate("workWithUs.form.step3.ctpsNumber")
              }
              autoComplete="off"
              value={dataFields?.basicInformation?.ctpsNumber || ""}
              onChange={(props) => handleChangeText(props, "basicInformation")}
              error={errors?.basicInformation?.ctpsNumber}
            />
          </GroupField>
        </Grid>

        <Grid item xs={12} md={12} lg={4} xl={4}>
          <GroupField>
            <Input
              fullWidth
              transparent="true"
              id="ctpsSerie"
              name="ctpsSerie"
              label={
                !isCtpsDigital
                  ? translate("workWithUs.form.step3.ctpsSerie") + " (*)"
                  : translate("workWithUs.form.step3.ctpsSerie")
              }
              autoComplete="off"
              value={dataFields?.basicInformation?.ctpsSerie || ""}
              onChange={(props) => handleChangeText(props, "basicInformation")}
              error={errors?.basicInformation?.ctpsSerie}
            />
          </GroupField>
        </Grid>

        <Grid item xs={12} md={12} lg={4} xl={4}>
          <GroupField>
            <InputSelect
              fullWidth
              transparent="true"
              id="ctpsUf"
              name="ctpsUf"
              label={
                !isCtpsDigital
                  ? translate("workWithUs.form.step3.ctpsUf") + " (*)"
                  : translate("workWithUs.form.step3.ctpsUf")
              }
              labeldark={false}
              autoComplete="off"
              value={dataFields?.basicInformation?.ctpsUf || ""}
              onChange={(props) => handleChangeText(props, "basicInformation")}
              options={listStates}
              error={errors?.basicInformation?.ctpsUf}
            />
          </GroupField>
        </Grid>

        <Grid item xs={12} md={12} lg={4} xl={4}>
          <GroupField>
            <DatePicker
              label={
                !isCtpsDigital
                  ? translate("workWithUs.form.step3.ctpsDispatchDate") + " (*)"
                  : translate("workWithUs.form.step3.ctpsDispatchDate")
              }
              value={ctpsDispatchDate}
              onChange={(newValue) =>
                handleChangeDate(
                  newValue,
                  "basicInformation",
                  "ctpsDispatchDate",
                  setCtpsDispatchDate(newValue)
                )
              }
              error={errors?.basicInformation?.ctpsDispatchDate}
            />
          </GroupField>
        </Grid>

        <Grid item xs={12} md={12} lg={12} xl={12}>
          <GroupField>
            <InputCheckbox
              label="Primeiro Emprego"
              name="firstJob"
              checked={!!dataFields?.basicInformation?.firstJob}
              onChange={(props) =>
                handleChangeCheckbox(
                  props,
                  "basicInformation",
                  setIsFirstJobRequired(props.target.checked)
                )
              }
            />
          </GroupField>
        </Grid>

        <Grid item xs={12} md={12} lg={12} xl={12}>
          <GroupField>
            <Input
              fullWidth
              transparent="true"
              id="pis"
              name="pis"
              label={
                !isFirstJobRequired
                  ? translate("workWithUs.form.step3.pis") + " (*)"
                  : translate("workWithUs.form.step3.pis")
              }
              autoComplete="off"
              value={dataFields?.basicInformation?.pis || ""}
              onChange={(props) => handleChangeText(props, "basicInformation")}
              InputProps={{
                inputComponent: InputMaskPisNumber,
              }}
              error={errors?.basicInformation?.pis}
            />
          </GroupField>
        </Grid>

        <Grid item xs={12} md={12} lg={6} xl={6}>
          <GroupField>
            <Input
              fullWidth
              transparent="true"
              id="voter_registration"
              name="voter_registration"
              label={translate("workWithUs.form.step3.voter_registration")}
              autoComplete="off"
              value={dataFields?.basicInformation?.voter_registration || ""}
              onChange={(props) => handleChangeText(props, "basicInformation")}
              error={errors?.basicInformation?.voter_registration}
            />
          </GroupField>
        </Grid>

        <Grid item xs={6} md={12} lg={3} xl={3}>
          <GroupField>
            <Input
              fullWidth
              transparent="true"
              id="voter_registration_zone"
              name="voter_registration_zone"
              label={translate("workWithUs.form.step3.voter_registration_zone")}
              autoComplete="off"
              value={
                dataFields?.basicInformation?.voter_registration_zone || ""
              }
              onChange={(props) => handleChangeText(props, "basicInformation")}
              error={errors?.basicInformation?.voter_registration_zone}
            />
          </GroupField>
        </Grid>

        <Grid item xs={6} md={12} lg={3} xl={3}>
          <GroupField>
            <Input
              fullWidth
              transparent="true"
              id="voter_registration_section"
              name="voter_registration_section"
              label={translate(
                "workWithUs.form.step3.voter_registration_section"
              )}
              autoComplete="off"
              value={
                dataFields?.basicInformation?.voter_registration_section || ""
              }
              onChange={(props) => handleChangeText(props, "basicInformation")}
              error={errors?.basicInformation?.voter_registration_section}
            />
          </GroupField>
        </Grid>

        <Grid item xs={12} md={12} lg={12} xl={12}>
          <GroupField>
            <Input
              fullWidth
              transparent="true"
              id="motherName"
              name="motherName"
              label={translate("workWithUs.form.step3.motherName") + " (*)"}
              autoComplete="off"
              value={dataFields?.basicInformation?.motherName || ""}
              onChange={(props) => handleChangeText(props, "basicInformation")}
              error={errors?.basicInformation?.motherName}
            />
          </GroupField>
        </Grid>
        <Grid item xs={12} md={12} lg={12} xl={12}>
          <GroupField>
            <Input
              fullWidth
              transparent="true"
              id="fatherName"
              name="fatherName"
              label={translate("workWithUs.form.step3.fatherName") + " (*)"}
              autoComplete="off"
              value={dataFields?.basicInformation?.fatherName || ""}
              onChange={(props) => handleChangeText(props, "basicInformation")}
              error={errors?.basicInformation?.fatherName}
            />
          </GroupField>
        </Grid>

        <Grid item xs={12} md={12} lg={4} xl={4}>
          <GroupField>
            <InputSelect
              fullWidth
              transparent="true"
              id="naturalnessState"
              name="naturalnessState"
              label={
                translate("workWithUs.form.step3.naturalnessState") + " (*)"
              }
              labeldark={false}
              autoComplete="off"
              value={dataFields?.basicInformation?.naturalnessState || ""}
              onChange={(props) =>
                handleStateChangeSelect(
                  props,
                  "naturalness",
                  "basicInformation",
                  "fetchCities"
                )
              }
              options={listStates}
              error={errors?.basicInformation?.naturalnessState}
            />
          </GroupField>
        </Grid>

        <Grid item xs={12} md={12} lg={4} xl={4}>
          <GroupField>
            <InputAutoComplete
              transparent="true"
              name="naturalness"
              id="naturalness"
              label={translate("workWithUs.form.step3.naturalness") + " (*)"}
              value={dataFields?.basicInformation?.naturalness || null}
              onChange={(props, value) =>
                handleChangeAutocomplete(
                  props,
                  value,
                  "naturalness",
                  "basicInformation"
                )
              }
              options={listCities}
              error={errors?.basicInformation?.naturalness}
            />
          </GroupField>
        </Grid>

        <Grid item xs={12} md={12} lg={4} xl={4}>
          <GroupField>
            <InputAutoComplete
              transparent="true"
              name="nationality"
              id="nationality"
              label={translate("workWithUs.form.step3.nationality") + " (*)"}
              value={dataFields?.basicInformation?.nationality || null}
              onChange={(props, value) =>
                handleChangeAutocomplete(
                  props,
                  value,
                  "nationality",
                  "basicInformation"
                )
              }
              options={nationalities}
        
              error={errors?.basicInformation?.nationality}
            />
          </GroupField>
        </Grid>

        <Grid item xs={12} md={12} lg={6} xl={6}>
          <GroupField>
            <DatePicker
              label={translate("workWithUs.form.step3.birthDate") + " (*)"}
              value={birthDate}
              onChange={(newValue) =>
                handleChangeDate(
                  newValue,
                  "basicInformation",
                  "birthDate",
                  setBirthDate(newValue)
                )
              }
              error={errors?.basicInformation?.birthDate}
            />
          </GroupField>
        </Grid>
        <Grid item xs={12} md={12} lg={6} xl={6}>
          <GroupField>
            <InputSelect
              transparent="true"
              name="maritalStatus"
              id="maritalStatus"
              label={translate("workWithUs.form.step3.maritalStatus") + " (*)"}
              labeldark={false}
              value={dataFields?.basicInformation?.maritalStatus || ""}
              onChange={(props) =>
                handleChangeSelect(props, "basicInformation")
              }
              options={getOptionsMaritalStatus}
              error={errors?.basicInformation?.maritalStatus}
            />
          </GroupField>
        </Grid>
        <Grid item xs={12} md={12} lg={6} xl={6}>
          <GroupField>
            <InputSelect
              transparent="true"
              name="gender"
              id="gender"
              label={translate("workWithUs.form.step3.gender") + " (*)"}
              labeldark={false}
              value={dataFields?.basicInformation?.gender || ""}
              onChange={(props) =>
                handleChangeSelect(props, "basicInformation")
              }
              options={getOptionsGender}
              error={errors?.basicInformation?.gender}
            />
          </GroupField>
        </Grid>
        <Grid item xs={12} md={12} lg={6} xl={6}>
          <GroupField>
            <InputSelect
              transparent="true"
              name="bloodType"
              id="bloodType"
              label={translate("workWithUs.form.step3.bloodType")}
              labeldark={false}
              value={dataFields?.basicInformation?.bloodType || ""}
              onChange={(props) =>
                handleChangeSelect(props, "basicInformation")
              }
              options={getOptionsBloodType}
              error={errors?.basicInformation?.bloodType}
            />
          </GroupField>
        </Grid>
        <Grid item xs={12} md={12} lg={5} xl={5}>
          <GroupField>
            <InputSelect
              transparent="true"
              name="educationLevel"
              id="educationLevel"
              label={translate("workWithUs.form.step3.educationLevel") + " (*)"}
              value={dataFields?.basicInformation?.educationLevel || ""}
              onChange={(props) =>
                handleChangeSelect(props, "basicInformation")
              }
              options={getOptionsEducationLevel}
              error={errors?.basicInformation?.educationLevel}
            />
          </GroupField>
        </Grid>
        <Grid item xs={12} md={12} lg={7} xl={7}>
          <GroupField>
            <Input
              fullWidth
              transparent="true"
              id="educationalInstitution"
              name="educationalInstitution"
              label={translate("workWithUs.form.step3.educationalInstitution")}
              autoComplete="off"
              value={dataFields?.basicInformation?.educationalInstitution || ""}
              onChange={(props) => handleChangeText(props, "basicInformation")}
              error={errors?.basicInformation?.educationalInstitution}
            />
          </GroupField>
        </Grid>
        <Grid item xs={12} md={7} lg={8} xl={8}>
          <GroupField>
            <Input
              fullWidth
              transparent="true"
              id="course"
              name="course"
              label={translate("workWithUs.form.step3.course")}
              autoComplete="off"
              value={dataFields?.basicInformation?.course || ""}
              onChange={(props) => handleChangeText(props, "basicInformation")}
              error={errors?.basicInformation?.course}
            />
          </GroupField>
        </Grid>
        <Grid item xs={12} md={5} lg={4} xl={4}>
          <GroupField>
            <DatePicker
              label={translate("workWithUs.form.step3.timeCourse")}
              value={timeCourse}
              onChange={(newValue) =>
                handleChangeDate(
                  newValue,
                  "basicInformation",
                  "timeCourse",
                  setTimeCourse(newValue)
                )
              }
              error={errors?.basicInformation?.timeCourse}
            />
          </GroupField>
        </Grid>

        <Grid item xs={12} md={3} lg={3} xl={3}>
          <GroupField>
            <InputSelect
              fullWidth
              transparent="true"
              id="driverLicenceCategory"
              name="driverLicenceCategory"
              label={translate("workWithUs.form.step3.driverLicenceCategory")}
              labeldark={false}
              autoComplete="off"
              value={dataFields?.basicInformation?.driverLicenceCategory || ""}
              onChange={(props) =>
                handleChangeText(
                  props,
                  "basicInformation",
                  setCnhRequired(
                    props.target.value && props.target.value !== "NT"
                  )
                )
              }
              options={getDriveLicenceCategories}
              error={errors?.basicInformation?.driverLicenceCategory}
            ></InputSelect>
          </GroupField>
        </Grid>

        <Grid item xs={12} md={6} lg={6} xl={6}>
          <GroupField>
            <Input
              fullWidth
              transparent="true"
              id="driverLicenceNumber"
              name="driverLicenceNumber"
              label={
                isCnhRequired
                  ? translate("workWithUs.form.step3.driverLicenceNumber") +
                    " (*)"
                  : translate("workWithUs.form.step3.driverLicenceNumber")
              }
              autoComplete="off"
              value={dataFields?.basicInformation?.driverLicenceNumber || ""}
              onChange={(props) => handleChangeText(props, "basicInformation")}
              error={errors?.basicInformation?.driverLicenceNumber}
            ></Input>
          </GroupField>
        </Grid>

        <Grid item xs={12} md={3} lg={3} xl={3}>
          <GroupField>
            <DatePicker
              label={
                isCnhRequired
                  ? translate("workWithUs.form.step3.driverFirstLicenceDate") +
                    " (*)"
                  : translate("workWithUs.form.step3.driverFirstLicenceDate")
              }
              value={driverFirstLicenceDate}
              onChange={(newValue) =>
                handleChangeDate(
                  newValue,
                  "basicInformation",
                  "driverFirstLicenceDate",
                  setDriverFirstLicenceDate(newValue)
                )
              }
              error={errors?.basicInformation?.driverFirstLicenceDate}
            />
          </GroupField>
        </Grid>

        <Grid item xs={12} md={4} lg={4} xl={4}>
          <GroupField>
            <InputSelect
              fullWidth
              transparent="true"
              id="driverLicenceIssuingAgency"
              name="driverLicenceIssuingAgency"
              label={
                isCnhRequired
                  ? translate(
                      "workWithUs.form.step3.driverLicenceIssuingAgency"
                    ) + " (*)"
                  : translate(
                      "workWithUs.form.step3.driverLicenceIssuingAgency"
                    )
              }
              labeldark={false}
              autoComplete="off"
              value={
                dataFields?.basicInformation?.driverLicenceIssuingAgency || ""
              }
              onChange={(props) => handleChangeText(props, "basicInformation")}
              options={[
                { label: "DETRAN", value: "DETRAN" },
                { label: "CIRETRAN", value: "CIRETRAN" },
                { label: "DENATRAN", value: "DENATRAN" },
              ]}
              error={errors?.basicInformation?.driverLicenceIssuingAgency}
            ></InputSelect>
          </GroupField>
        </Grid>

        <Grid item xs={12} md={4} lg={4} xl={4}>
          <GroupField>
            <InputSelect
              fullWidth
              transparent="true"
              id="driverLicenceUfIssuing"
              name="driverLicenceUfIssuing"
              label={
                isCnhRequired
                  ? translate("workWithUs.form.step3.driverLicenceUfIssuing") +
                    " (*)"
                  : translate("workWithUs.form.step3.driverLicenceUfIssuing")
              }
              labeldark={false}
              autoComplete="off"
              value={dataFields?.basicInformation?.driverLicenceUfIssuing || ""}
              onChange={(props) =>
                handleStateChangeSelect(
                  props,
                  "naturalness",
                  "basicInformation"
                )
              }
              options={listStates}
              error={errors?.basicInformation?.driverLicenceUfIssuing}
            />
          </GroupField>
        </Grid>

        <Grid item xs={12} md={4} lg={4} xl={4}>
          <GroupField>
            <DatePicker
              label={
                isCnhRequired
                  ? translate("workWithUs.form.step3.driverExpirationDate") +
                    " (*)"
                  : translate("workWithUs.form.step3.driverExpirationDate")
              }
              value={driverExpirationDate}
              onChange={(newValue) =>
                handleChangeDate(
                  newValue,
                  "basicInformation",
                  "driverExpirationDate",
                  setDriverExpirationDate(newValue)
                )
              }
              error={errors?.basicInformation?.driverExpirationDate}
            />
          </GroupField>
        </Grid>

        <Grid item xs={12} md={4} lg={6} xl={6}>
          <GroupField>
            <Input
              min="40"
              max="300"
              type="number"
              fullWidth
              transparent="true"
              id="weight"
              name="weight"
              label={translate("workWithUs.form.step3.weight")}
              autoComplete="off"
              value={dataFields?.basicInformation?.weight || ""}
              onChange={(props) => handleChangeText(props, "basicInformation")}
              error={errors?.basicInformation?.weight}
            ></Input>
          </GroupField>
        </Grid>

        <Grid item xs={12} md={4} lg={6} xl={6}>
          <GroupField>
            <InputSelect
              type="number"
              fullWidth
              transparent="true"
              id="height"
              name="height"
              label={translate("workWithUs.form.step3.height")}
              labeldark={false}
              autoComplete="off"
              value={dataFields?.basicInformation?.height || ""}
              onChange={(props) => handleChangeText(props, "basicInformation")}
              options={listHeight}
              error={errors?.basicInformation?.height}
            ></InputSelect>
          </GroupField>
        </Grid>

        <Grid item xs={12} md={4} lg={4} xl={4}>
          <GroupField>
            <InputSelect
              fullWidth
              transparent="true"
              id="sizePant"
              name="sizePant"
              label={translate("workWithUs.form.step3.sizePant")}
              labeldark={false}
              value={dataFields?.basicInformation?.sizePant || ""}
              onChange={(props) => handleChangeText(props, "basicInformation")}
              options={listSizesPants}
              error={errors?.basicInformation?.sizePant}
            ></InputSelect>
          </GroupField>
        </Grid>

        <Grid item xs={12} md={4} lg={4} xl={4}>
          <GroupField>
            <InputSelect
              fullWidth
              transparent="true"
              id="sizeShirt"
              name="sizeShirt"
              label={translate("workWithUs.form.step3.sizeShirt")}
              labeldark={false}
              value={dataFields?.basicInformation?.sizeShirt || ""}
              onChange={(props) => handleChangeText(props, "basicInformation")}
              options={listSizesShirts}
              error={errors?.basicInformation?.sizeShirt}
            ></InputSelect>
          </GroupField>
        </Grid>

        <Grid item xs={12} md={4} lg={4} xl={4}>
          <GroupField>
            <InputSelect
              fullWidth
              transparent="true"
              id="sizeTshirt"
              name="sizeTshirt"
              label={translate("workWithUs.form.step3.sizeTshirt")}
              labeldark={false}
              value={dataFields?.basicInformation?.sizeTshirt || ""}
              onChange={(props) => handleChangeText(props, "basicInformation")}
              options={listSizesTshirts}
              error={errors?.basicInformation?.sizeTshirt}
            ></InputSelect>
          </GroupField>
        </Grid>

        <Grid item xs={12} md={4} lg={6} xl={6}>
          <GroupField>
            <InputSelect
              fullWidth
              transparent="true"
              id="sizeJacket"
              name="sizeJacket"
              label={translate("workWithUs.form.step3.sizeJacket")}
              labeldark={false}
              value={dataFields?.basicInformation?.sizeJacket || ""}
              onChange={(props) => handleChangeText(props, "basicInformation")}
              options={listSizesTshirts}
              error={errors?.basicInformation?.sizeJacket}
            ></InputSelect>
          </GroupField>
        </Grid>

        <Grid item xs={12} md={4} lg={6} xl={6}>
          <GroupField>
            <InputSelect
              fullWidth
              transparent="true"
              id="sizeShoe"
              name="sizeShoe"
              label={translate("workWithUs.form.step3.sizeShoe")}
              labeldark={false}
              value={dataFields?.basicInformation?.sizeShoe || ""}
              onChange={(props) => handleChangeText(props, "basicInformation")}
              options={listSizesShoes}
              error={errors?.basicInformation?.sizeShoe}
            ></InputSelect>
          </GroupField>
        </Grid>

        <Grid item xs={12} md={4} lg={6} xl={6}>
          <GroupField>
            <InputSelect
              fullWidth
              transparent="true"
              id="availabilityPeriod"
              name="availabilityPeriod"
              label={translate("workWithUs.form.step3.availabilityPeriod")}
              labeldark={false}
              value={dataFields?.basicInformation?.availabilityPeriod || ""}
              onChange={(props) => handleChangeText(props, "basicInformation")}
              options={[
                { label: "DIURNO", value: "DIURNO" },
                { label: "NOTURNO", value: "NOTURNO" },
                { label: "AMBOS", value: "AMBOS" },
              ]}
              error={errors?.basicInformation?.availabilityPeriod}
            ></InputSelect>
          </GroupField>
        </Grid>

        <Grid item xs={12} md={4} lg={6} xl={6}>
          <GroupField>
            <InputSelect
              fullWidth
              transparent="true"
              id="availabilityTimetable"
              name="availabilityTimetable"
              label={translate("workWithUs.form.step3.availabilityTimetable")}
              labeldark={false}
              value={dataFields?.basicInformation?.availabilityTimetable || ""}
              onChange={(props) => handleChangeText(props, "basicInformation")}
              options={[
                { label: "4X2", value: "4X2" },
                { label: "5X1", value: "5X1" },
                { label: "5X2", value: "5X2" },
                { label: "6X1", value: "6X1" },
                { label: "12x36", value: "12x36" },
                { label: "18X36", value: "18X36" },
                { label: "24X48", value: "24X48" },
                { label: "Todos", value: "Todos" },
              ]}
              error={errors?.basicInformation?.availabilityTimetable}
            ></InputSelect>
          </GroupField>
        </Grid>

        <Grid item xs={12} md={12} lg={12} xl={12}>
          <GroupField>
            <InputCheckbox
              label="Declaro que não apresento nenhum sintoma do Covid-19 nas últimas 24 horas e confirmo que não faço parte de grupo de risco conforme definições em protocolos do ministério da saúde."
              name="covid_declaration"
              checked={!!dataFields?.basicInformation?.covid_declaration}
              onChange={(props) =>
                handleChangeCheckbox(props, "basicInformation")
              }
            />
          </GroupField>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default BasicInformation;
