import axios from "axios";
import Store from "store";
import objectToFormData from "utils/objtof2";
import { Utils } from "utils";

const API_URL = process.env.REACT_APP_API_URL;

const Api = axios.create({
    baseURL: API_URL,
    transformRequest: [
        (data, headers) => {
            return objectToFormData(data);
        },
    ],
    transformResponse: [
        function (data, headers) {
            data = JSON.parse(data);
            if (data.status_code === 401) {
                Store.remove("token_altum");
                window.location.href = Utils.setPrefix("/logout");
                return;
            }
            return data.status_code >= 300 ? data.errors : data.data;
        },
    ],
});

export default Api;
