export default {
    colorBodyDefault: "#EEEEEE",
    colorDark: "#2C2C2C",
    colorBlack: "#000000",
    colorPrimary: "#03E548",
    colorSecondary: "#00A13A",
    colorWhite: "#FFFFFF",
    colorGray: "#727272",
    colorGrayLight: "#EFEFEF",
    colorDarkGray: "#9E9E9E",
    colorBlue: "#4B5F85",
    colorBlueDark: "#14162D",
    colorGreen: "#008D25",
    colorRed: "#FF0000",
    colorYellow: "#e79917",
    colorYellowDark: "#c37f0d",
    colorError: "#FF1010",
    colorBgTermsOfUser: "#EEEEEE",
    colorBgCommunication: "#191F2D",
    colorStatusFinalizado: "#01C434",
    colorStatusPendente:"#3C4295",
    colorStatusEmAnalise:"#F8E839",
    colorStatusEmAnalisePendente: "#E6E600",
    colorStatusRecusado: "#FF1010",
    colorTextTable: "#D2D2D2",
    colorTableRowHover: "#2a3244",
    colorMessage: "#52688F",

    colorLightBgCommunication:"#EEEEEE",
    colorLightTableRowHover:"#D7D6D6",

    colorBorderImgMessage: "#6c6f73",
    buttonMain: {
        default: ["#2D2D2D", "#2D2D2D"],
        hover: ["#4c4c4c", "#4c4c4c"],
    },
    buttonDark: {
        default: ["#2D2D2D", "#2D2D2D"],
        hover: ["#282b54", "#282b54"],
        focus: ["#282b54", "#282b54"],
    },
    buttonGray: {
        default: ["#C1C1C1", "#C1C1C1"],
        hover: ["#bdbdbd", "#bdbdbd"],
        focus: ["#bdbdbd", "#bdbdbd"],
    },
    buttonGreen: {
        default: ["#008D25", "#008D25"],
        hover: ["#057723", "#057723"],
        focus: ["#057723", "#057723"],
    },
    buttonBlue: {
        default: ["#14162D", "#14162D"],
        hover: ["#212448", "#212448"],
        focus: ["#212448", "#212448"],
    },
    buttonRed: {
        default: ["#D80415", "#D80415"],
        hover: ["#D80415", "#D80415"],
        focus: ["#D80415", "#D80415"],
    },
    buttonAnexo: {
        default: ["#4B5F85", "#4B5F85"],
        hover: ["#445577", "#445577"],
        focus: ["#445577", "#445577"],
    },
    formText: {
        bg: {
            default: "#fff",
            hover: "#efefef",
            focus: "#dfdfdf",
        },
        border: {
            default: "#707070",
            hover: "#009143",
            focus: "#009143",
            error: "#cc2500",
        },
        label: {
            default: "#9E9E9E",
            focus: "#fff",
            focusDark: "#2C2C2C",
            error: "#cc2500",
        },
    },
    alert: {
        error: ["#cc2500", "#aa1d00"],
        success: ["#1c9e3e", "#008D25"],
    },
    step: {
        default: {
            circleOut: "#757575",
            circleIn: "#c6cecc",
            border: "#757575",
        },
        active: {
            circleOut: "#000000",
            circleIn: "#fff",
            border: "#000000",
        },
    },
};
