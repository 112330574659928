import React, { useState, useCallback, useMemo } from "react";
import { useEffect } from "react";
import ReactSelect from "react-select";
import { useWorkWithUs } from "contexts/WorkWithUs.contexts";

import { Container } from "./Styled";
import Api from "service/api";

export function Regions({
    selectedStateOption,
    selectedRegionOption,
    setSelectedStateOption,
    setSelectedRegionOption,
    getSelectedRegions,
    fnState,
    fnRegion,
    clearVtLines,
}) {
    const { dataFields, updateDataField } = useWorkWithUs();

    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);

    const clearRegionsInLocalStorage = useCallback(() => {
        const work_with_us = JSON.parse(JSON.parse(localStorage.work_with_us));

        work_with_us.regions.options = [];

        localStorage.work_with_us = JSON.stringify(
            JSON.stringify(work_with_us)
        );
    }, []);

    const fillCitiesByState = useCallback(async (id) => {
        const {
            data: { cidades: savedCities },
        } = await Api.get(`cities-ibge/${id}`);

        const allCitiesByState = [];

        savedCities.forEach((city) => {
            allCitiesByState.push({
                label: city.nome,
                value: city.codigo,
            });
        });

        setCities(allCitiesByState);
    }, []);

    const handleSelectedState = useCallback(
        (value) => {
            setSelectedStateOption(value);

            fillCitiesByState(value.value);

            if (fnState) {
                fnState(value);
            }

            setSelectedRegionOption(null);

            clearRegionsInLocalStorage();

            updateDataField("regions", {
                ...{
                    ...dataFields["regions"],
                    state: value,
                    options: [],
                    type: "regions",
                },
            });

            if (clearVtLines) {
                clearVtLines();
            }
        },
        [
            fillCitiesByState,
            clearRegionsInLocalStorage,
            dataFields,
            fnState,
            setSelectedRegionOption,
            setSelectedStateOption,
            updateDataField,
            clearVtLines
        ]
    );

    const handleSelectedRegions = useCallback(
        (value) => {
            getSelectedRegions(value);
            setSelectedRegionOption(value);

            if (fnRegion) {
                fnRegion(value);
            }

            updateDataField("regions", {
                ...{
                    ...dataFields["regions"],
                    options: value,
                    type: "regions",
                },
            });

            if (clearVtLines) {
                clearVtLines();
            }
        },
        [
            dataFields,
            fnRegion,
            getSelectedRegions,
            setSelectedRegionOption,
            updateDataField,
            clearVtLines
        ]
    );

    const initWorkWithUsRegions = useCallback(() => {
        const workWithUs = JSON.parse(JSON.parse(localStorage.work_with_us));

        workWithUs.regions = {
            type: "regions",
            state: workWithUs.regions?.state || null,
            options: workWithUs.regions?.options || [],
        };

        localStorage.work_with_us = JSON.stringify(JSON.stringify(workWithUs));
    }, []);

    const getStates = useCallback(async () => {
        const {
            data: { states: savedStates },
        } = await Api.get("states");

        const allStates = [];

        savedStates.forEach((state) => {
            allStates.push({
                label: state.name,
                value: state.uf,
                cities: [],
            });
        });

        setStates(allStates);
    }, []);

    useMemo(()=>{
        getStates();
    }, [getStates]);

    useEffect(() => {
 
        const selectedStateValue = selectedStateOption
            ? selectedStateOption.value
            : null;

        if (selectedStateValue) {
            fillCitiesByState(selectedStateValue);
        }

        initWorkWithUsRegions();
    }, []);

    return (
        <Container>
            <ReactSelect
                defaultValue={selectedStateOption}
                value={selectedStateOption}
                onChange={handleSelectedState}
                options={states}
                className="react-select"
                placeholder="Selecione o estado"
                noOptionsMessage={() => "Nenhum item encontrado"}
            />

            <ReactSelect
                value={selectedRegionOption}
                onChange={handleSelectedRegions}
                options={cities}
                isMulti
                className="react-select"
                placeholder="Selecione a região"
                noOptionsMessage={() => "Nenhum item encontrado"}
            />
        </Container>
    );
}
