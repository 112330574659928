import i18n from "i18next";
import { initReactI18next } from "react-i18next";
//import LanguageDetector from "i18next-browser-languagedetector";

import en from "./lang/en";
import es from "./lang/es";
import ptBR from "./lang/pt-BR";


i18n.use(initReactI18next).init({
    resources: {
        "pt-BR": {
            translation: {
                headerPage: ptBR.headerPage,
                selectLang: ptBR.selectLang,
                modalCustom: ptBR.modalCustom,
                login: ptBR.login,
                updatePassword: ptBR.updatePassword,
                home: ptBR.home,
                addMessage: ptBR.addMessage,
                messageSendSuccess: ptBR.messageSendSuccess,
                messages: ptBR.messages,
                termsOfUse: ptBR.termsOfUse,
                documents: ptBR.documents,
                termsOfUseComplet: ptBR.termsOfUseComplet,
                workWithUs: ptBR.workWithUs,
                holerite: ptBR.holerite,
                informeRendimento: ptBR.informeRendimento,
                updateEmail: ptBR.updateEmail,
                logout: ptBR.logout,
                pendingEmailConfirmation: ptBR.pendingEmailConfirmation,
                page404: ptBR.page404,
            },
        },
        en: {
            translation: {
                headerPage: en.headerPage,
                selectLang: en.selectLang,
                modalCustom: en.modalCustom,
                login: en.login,
                updatePassword: en.updatePassword,
                home: en.home,
                addMessage: en.addMessage,
                messageSendSuccess: en.messageSendSuccess,
                messages: en.messages,
                termsOfUse: en.termsOfUse,
                documents: en.documents,
                termsOfUseComplet: en.termsOfUseComplet,
                workWithUs: en.workWithUs,
                holerite: en.holerite,
                informeRendimento: en.informeRendimento,
                updateEmail: en.updateEmail,
                logout: en.logout,
                pendingEmailConfirmation: en.pendingEmailConfirmation,
                page404: en.page404,
            },
        },
        es: {
            translation: {
                headerPage: es.headerPage,
                selectLang: es.selectLang,
                modalCustom: es.modalCustom,
                login: es.login,
                updatePassword: es.updatePassword,
                home: es.home,
                addMessage: es.addMessage,
                messageSendSuccess: es.messageSendSuccess,
                messages: es.messages,
                termsOfUse: es.termsOfUse,
                documents: es.documents,
                termsOfUseComplet: es.termsOfUseComplet,
                workWithUs: es.workWithUs,
                holerite: es.holerite,
                informeRendimento: es.informeRendimento,
                updateEmail: es.updateEmail,
                logout: es.logout,
                pendingEmailConfirmation: es.pendingEmailConfirmation,
                page404: es.page404,
            },
        },
    },
    lng: window.localStorage.i18nextLng || "pt-BR",
    fallbackLng: window.localStorage.i18nextLng || "pt-BR",
    interpolation: {
        escapeValue: false,
    },
});
