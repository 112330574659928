import React from "react";
import { Container, Grid } from "@material-ui/core";
import Loading from "components/loading";
import ButtonCustom from "components/buttonCustom";
import { InputRadioCustomNew } from "components/form";
import ModalSelectOptions from "components/modalSelectOptions";
import AlertCustom from "components/alertCustom";
import useWelcome from "./useSelectTypeOfVacancy";

import {
  Main,
  HeaderPage,
  Anime,
  Title,
  BtnGroupGoBack,
  Icon,
  GroupRadiosCustom,
} from "../Styled";

import { ContainerSelect, InputSelect } from "./styled";

const SelectTypeOfVacancy = () => {
  const {
    errors,
    translate,
    transitionIn,
    loadingPage,
    search,
    typeOfVacancy,
    professions,
    openModal,
    openAlert,
    handleSearch,
    handleCloseAlert,
    hadleGoback,
    hadleGoLink,
    getValueChecked,
    handleSelectTypeOfVacancy,
    handleChangeTypeContract,
    dataFields,
  } = useWelcome();

  if (loadingPage) {
    return <Loading />;
  }

  return (
    <React.Fragment>
      <Main className={openModal?.name ? "open-modal" : ""}>
        <HeaderPage>
          <BtnGroupGoBack transition={transitionIn}>
            <ButtonCustom
              color="default"
              type="button"
              size="large"
              className="btn-goback"
              onClick={hadleGoback}
              fullWidth
            >
              <Icon className="icon icon-arrow" />
            </ButtonCustom>
          </BtnGroupGoBack>
          <Title transition={transitionIn}>
            {translate("workWithUs.selectTypeOfVacancy.title")}
          </Title>
        </HeaderPage>

        <Container>
          <Grid container className="justify-content-center">
            <Grid item xs={11} md={10} lg={10} xl={10}>
              <Anime transition={transitionIn}>
                <ContainerSelect>
                  <InputSelect
                    fullWidth
                    multiple={true}
                    transparent={true}
                    value={dataFields?.typeOfContract || []}
                    onChange={handleChangeTypeContract}
                    id="typeOfContract"
                    name="typeOfContract"
                    label="Tipo de Vaga"
                    labeldark={false}
                    hideSelected={true}
                    options={[
                      { value: "CLT", label: "CLT" },
                      { value: "PJ", label: "PJ" },
                      { value: "ESTAGIO", label: "Estágio" },
                      { value: "TEMPORARIO", label: "Temporário" },
                      { value: "FREELANCER", label: "Freelancer" }
                    ]}
                    error={ dataFields?.typeOfContract?.length === 0 || dataFields?.typeOfContract === undefined}
                  />
                </ContainerSelect>

                <GroupRadiosCustom>
                  <InputRadioCustomNew
                    icon="icon-policeman"
                    label="Vigilante"
                    name="typeOfVacancy"
                    value="1"
                    key="1"
                    onChange={() =>
                      handleSelectTypeOfVacancy("typeOfVacancy", "1")
                    }
                    checked={getValueChecked("typeOfVacancy", "1")}
                  />
                  <InputRadioCustomNew
                    icon="icon-group"
                    label="Demais funções"
                    name="typeOfVacancy"
                    value="2"
                    key="2"
                    onChange={() =>
                      handleSelectTypeOfVacancy("typeOfVacancy", "2")
                    }
                    checked={getValueChecked("typeOfVacancy", "2")}
                  />
                  <InputRadioCustomNew
                    icon="icon-deficient"
                    label="Exclusivo para pessoas com deficiencia (PCD)"
                    name="typeOfVacancy"
                    value="3"
                    key="3"
                    onChange={() =>
                      handleSelectTypeOfVacancy("typeOfVacancy", "3")
                    }
                    checked={getValueChecked("typeOfVacancy", "3")}
                  />
                </GroupRadiosCustom>
              </Anime>
            </Grid>
          </Grid>
        </Container>
      </Main>
      <ModalSelectOptions
        search={search}
        onSearch={handleSearch}
        optionName={typeOfVacancy}
        data={professions}
        action={{
          name: "advance",
          fn: () => hadleGoLink("/trabalhe-conosco/cadastro"),
        }}
      />
      <AlertCustom
        message={openAlert?.message}
        openMessage={openAlert?.status}
        closeMessage={handleCloseAlert}
        transition="down"
        origin={{ vertical: "top", horizontal: "right" }}
      />
    </React.Fragment>
  );
};

export default SelectTypeOfVacancy;
