import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import { InputCheckbox } from "components/form";
import ButtonCustom from "components/buttonCustom";
import { useAddProfessionExperience } from "./useAddProfessionExperience";
import { DatePicker } from "components/DatePicker";

import { Title, GroupField, Input } from "./Styled";

export const WorkWithUSAddProfessionExperience = (props) => {
    const {
        translate,
        fieldsModal,
        errorModal,
        handleChangeText,
        handleChangeDate,
        handleChangeCheckbox,
        handleAddCourse,
    } = useAddProfessionExperience();

    const [startDate, setStartDate] = useState(null);

    const [endDate, setEndDate] = useState(null);

    return (
        <>
            <Title>
                {translate("workWithUs.modalProfessionExperience.title")}
            </Title>

            <Grid container justifyContent="center" spacing={2}>
                <Grid item xs={12} md={10}>
                    <GroupField>
                        <Input
                            fullWidth
                            transparent="true"
                            id="company"
                            name="company"
                            label={translate(
                                "workWithUs.modalProfessionExperience.form.company"
                            )}
                            autoComplete="off"
                            value={fieldsModal?.company || ""}
                            onChange={(props) =>
                                handleChangeText(props, "experience")
                            }
                            error={errorModal?.company}
                        />
                    </GroupField>
                </Grid>
                <Grid item xs={12} md={10}>
                    <GroupField>
                        <Input
                            fullWidth
                            transparent="true"
                            id="office"
                            name="office"
                            label={translate(
                                "workWithUs.modalProfessionExperience.form.office"
                            )}
                            autoComplete="off"
                            value={fieldsModal?.office || ""}
                            onChange={(props) =>
                                handleChangeText(props, "experience")
                            }
                            error={errorModal?.office}
                        />
                    </GroupField>
                </Grid>
                <Grid item xs={12} md={5}>
                    <GroupField>
                        <DatePicker
                            label={translate(
                                "workWithUs.modalProfessionExperience.form.startDate"
                            )}
                            value={startDate}
                            onChange={(newValue) =>
                                handleChangeDate(
                                    newValue,
                                    "startDate",
                                    setStartDate(newValue)
                                )
                            }
                        />
                    </GroupField>
                </Grid>
                {!fieldsModal?.actualjob && (
                    <Grid item xs={12} md={5}>
                        <GroupField>
                            <DatePicker
                                label={translate(
                                    "workWithUs.modalProfessionExperience.form.endDate"
                                )}
                                value={endDate}
                                onChange={(newValue) =>
                                    handleChangeDate(
                                        newValue,
                                        "endDate",
                                        setEndDate(newValue)
                                    )
                                }
                            />
                        </GroupField>
                    </Grid>
                )}
                <Grid item xs={12} md={10}>
                    <InputCheckbox
                        label={translate(
                            "workWithUs.modalProfessionExperience.form.Actualjob"
                        )}
                        id="actualjob"
                        name="actualjob"
                        value={fieldsModal?.actualjob || ""}
                        checked={fieldsModal?.actualjob || false}
                        onChange={handleChangeCheckbox}
                    />
                </Grid>
                <Grid item xs={12} md={10}>
                    <ButtonCustom
                        className="btn-green"
                        onClick={() => handleAddCourse(props.handleSave)}
                    >
                        {translate("workWithUs.save")}
                    </ButtonCustom>
                </Grid>
            </Grid>
        </>
    );
};
