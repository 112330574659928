import { createGlobalStyle } from 'styled-components';

import RobotoRegularEot from './fonts/Roboto-Regular.eot';
import RobotoRegularWoff from './fonts/Roboto-Regular.woff';
import RobotoRegularTtf from './fonts/Roboto-Regular.ttf';
import RobotoRegularSvg from './fonts/Roboto-Regular.svg';

import RobotoMediumEot from './fonts/Roboto-Medium.eot';
import RobotoMediumWoff from './fonts/Roboto-Medium.woff';
import RobotoMediumTtf from './fonts/Roboto-Medium.ttf';
import RobotoMediumSvg from './fonts/Roboto-Medium.svg';

import RobotoBoldEot from './fonts/Roboto-Bold.eot';
import RobotoBoldWoff from './fonts/Roboto-Bold.woff';
import RobotoBoldTtf from './fonts/Roboto-Bold.ttf';
import RobotoBoldSvg from './fonts/Roboto-Bold.svg';

import RobotoBlackEot from './fonts/Roboto-Black.eot';
import RobotoBlackWoff from './fonts/Roboto-Black.woff';
import RobotoBlackTtf from './fonts/Roboto-Black.ttf';
import RobotoBlackSvg from './fonts/Roboto-Black.svg';

export default createGlobalStyle`
    @font-face {
        font-family: 'Roboto';
        src: local('Roboto'), local('Roboto'),
        url(${RobotoRegularEot}) format('eot'),
        url(${RobotoRegularWoff}) format('woff'),
        url(${RobotoRegularTtf}) format('ttf'),
        url(${RobotoRegularSvg}) format('svg');
        font-weight: 300;
        font-style: normal;
    }
    @font-face {
        font-family: 'Roboto';
        src: local('Roboto'), local('Roboto'),
        url(${RobotoMediumEot}) format('eot'),
        url(${RobotoMediumWoff}) format('woff'),
        url(${RobotoMediumTtf}) format('ttf'),
        url(${RobotoMediumSvg}) format('svg');
        font-weight: 400;
        font-style: normal;
    }
    @font-face {
        font-family: 'Roboto';
        src: local('Roboto'), local('Roboto'),
        url(${RobotoBoldEot}) format('eot'),
        url(${RobotoBoldWoff}) format('woff'),
        url(${RobotoBoldTtf}) format('ttf'),
        url(${RobotoBoldSvg}) format('svg');
        font-weight: 500;
        font-style: normal;
    }
    @font-face {
        font-family: 'Roboto';
        src: local('Roboto'), local('Roboto'),
        url(${RobotoBlackEot}) format('eot'),
        url(${RobotoBlackWoff}) format('woff'),
        url(${RobotoBlackTtf}) format('ttf'),
        url(${RobotoBlackSvg}) format('svg');
        font-weight: 600;
        font-style: normal;
    }
`;