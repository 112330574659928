import Store from "store";
import Theme from "Theme";

const PATH_PUBLIC = process.env.REACT_APP_BASE_URL;

export class Utils {
  static urlImages = "http://192.168.1.183";

  static isAuthenticated = () => Store.get("token") !== undefined;

  static validateCPF = (strCPF) => {
    strCPF = strCPF.replace(/\D/g, "");
    if (strCPF.toString().length !== 11 || /^(\d)\1{10}$/.test(strCPF))
      return false;
    let result = true;
    [9, 10].forEach(function (j) {
      let soma = 0,
        resto;
      strCPF
        .split(/(?=)/)
        .splice(0, j)
        .forEach(function (e, i) {
          soma += parseInt(e) * (j + 2 - (i + 1));
        });
      resto = soma % 11;
      resto = resto < 2 ? 0 : 11 - resto;
      if (resto !== parseInt(strCPF.substring(j, j + 1))) result = false;
    });
    return result;
  };

  static validateCellPhone = (strCellPhone) => {
    const cellNumber = this.clearMask(strCellPhone);
    return cellNumber.length >= 10;
  };

  static validateEmail = (email) => {
    const regexEmail =
      /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return regexEmail.test(email);
  };

  static verifyMedia = (nota, media) => {
    nota = parseFloat(nota);
    return nota > media;
  };

  static maskCellphoneOrPhone = (number) => {
    if (/^(\d\d)9/.test(number.replace(/[^0-9]/g, "")))
      return "(11) 11111-1111";
    else return "(11) 1111-1111";
  };

  static hexToRgba = (hex, alpha = 1) => {
    const [r, g, b] = hex.match(/\w\w/g).map((x) => parseInt(x, 16));
    return `rgba(${r},${g},${b},${alpha})`;
  };

  static hexLightDarkColor = (hex, percent) => {
    let R = parseInt(hex.substring(1, 3), 16);
    let G = parseInt(hex.substring(3, 5), 16);
    let B = parseInt(hex.substring(5, 7), 16);
    R = parseInt((R * (100 + percent)) / 100);
    G = parseInt((G * (100 + percent)) / 100);
    B = parseInt((B * (100 + percent)) / 100);
    R = R < 255 ? R : 255;
    G = G < 255 ? G : 255;
    B = B < 255 ? B : 255;
    let RR =
      parseInt(R.toString(16).length) === 1
        ? "0" + R.toString(16)
        : R.toString(16);
    let GG =
      parseInt(G.toString(16).length) === 1
        ? "0" + G.toString(16)
        : G.toString(16);
    let BB =
      parseInt(B.toString(16).length) === 1
        ? "0" + B.toString(16)
        : B.toString(16);
    return "#" + RR + GG + BB;
  };

  static setPrefix = (link) => {
    return PATH_PUBLIC !== "/" ? `${PATH_PUBLIC}${link}` : link;
  };

  static degraderColor = (cores, to = "right") => {
    return `linear-gradient(to ${to}, ${cores})`;
  };

  static range = (start, end, step) => {
    let arr = [];
    for (let i = start; i <= end; i += step) {
      arr.push(i);
    }
    return arr;
  };

  static formatNumberZeroLeft = (pad, number, padPos) => {
    if (typeof number === "undefined") {
      return pad;
    }
    if (padPos === "l") {
      return (pad + number).slice(-pad.length);
    } else {
      return (number + pad).substring(0, pad.length);
    }
  };

  static statusComunication = (status) => {
    const themes = {
      FINALIZADO: Theme.colorStatusFinalizado,
      EM_ANALISE: Theme.colorStatusEmAnalise,
      PENDENTE: Theme.colorStatusPendente,
      RECUSADO: Theme.colorStatusRecusado,
    };
    return themes[status] || Theme.colorStatusEmAnalisePendente;
  };

  static formataStatusComunicacao = (status) => {
    status = status.replace('_',' ');
    status = status.toLowerCase();
    return status.charAt(0).toUpperCase() + status.slice(1);
  };

  static formatDataIsLang = (lang) => {
    return lang === "en" ? "YYYY/MM" : "MM/YYYY";
  };

  static formatDataHoraIsLang = (lang) => {
    return lang === "en" ? "YYYY/MM/DD hh:mm a" : "DD/MM/YYYY HH:mm";
  };

  static shortName = (fullname) => `${fullname}`.split(/(\s).+\s/).join("");

  static clearMask = (str) => {
    return str
      ? str
          .replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "")
          .replace(/\s/g, "")
      : str;
  };
}
