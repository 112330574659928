import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Api from "service/api";

const useEmailValidate = () => {
  const history = useHistory();
  const { hash } = useParams();
  const [isValidated, setIsValidated] = useState(false);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const validateEmail = async () => {
    setLoading(true);
    const hashValidate = hash ?? "";
    const response = await Api.get(`/email/confirmation/${hashValidate}`);
    //console.log(response.data);
    setIsValidated(response.data.isValidated);
    setMessage(response.data.message);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const handleLogin = () => {
    history.push("/login");
  };

  useEffect(() => {
    validateEmail();
  }, []);

  return { isValidated, handleLogin, message, loading };
};

export default useEmailValidate;
