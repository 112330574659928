import styled from "styled-components";
import {
    Dialog,
    DialogTitle,
    List as Items,
    ListItem as Item,
} from "@material-ui/core";

import Theme from "Theme";

export const Modal = styled(Dialog)`
    && {
        .MuiPaper-root {
            width: 100%;
            max-width: 500px;
            background-color: rgba(15, 15, 15, 0.9);

            .btn-green {
                max-width: 180px;
                margin: 10px auto 20px;
            }
        }
    }
`;

export const ModalTitle = styled(DialogTitle)`
    && {
        color: ${Theme.colorWhite};
        text-align: center;
    }
`;

export const GroupSearch = styled.div`
    margin: 0 25px 10px;

    .MuiFormControl-root {
        .MuiFormLabel-root {
            color: ${Theme.colorWhite};
        }
    }
`;

export const List = styled(Items)`
    && {
        max-height: calc(100vh - 400px);
        margin: 0 5px 10px;
        overflow-y: auto;

        ::-webkit-scrollbar {
            width: 10px;
        }

        ::-webkit-scrollbar-track {
            border-radius: 10px;
        }

        ::-webkit-scrollbar-thumb {
            background: ${Theme.colorDarkGray};
            border-radius: 10px;
        }

        ::-webkit-scrollbar-thumb:hover {
            background: ${Theme.colorWhite};
        }
    }
`;

export const ListItem = styled(Item)`
    && {
    }
`;
