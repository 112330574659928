import React from "react";
import Loading from "components/loading";
import AlertCustom from "components/alertCustom";
import {
  Container,
  Header,
  Mensagem,
  Button,
  ButtonSubmit,
  Logo,
  Main,
  ContainerButton,
  ContainerField,
  InputVerificationCode,
  InputVerificationCodeLabel,
  LinkChangeEmail,
} from "./Styled";

import LogoImg from "../../assets/images/altum.png";
import usePendingEmailConfirmation from "./usePendingEmailConfirmation";

const PendingEmailConfirmation = () => {
  const {
    message,
    messageDefault,
    handleSendEmailConfimation,
    resendEmail,
    loading,
    handleLogout,
    handleChangeCodeVerification,
    codeVerification,
    disabledButton,
    handleCodeVerification,
    emailConfirmed,
    setOpenMessage,
    openMessage,
    alertSeverity,
    isPendingEmailConfirmation,
    translate,
    goLink,
  } = usePendingEmailConfirmation();

  if (loading) {
    return <Loading />;
  }

  const ViewEmailConfirmedMessage = () => {
    return (
      <Mensagem>
        <p>{message}</p>
      </Mensagem>
    );
  };

  const ViewContainerButtom = () => {
    return (
      <>
        <ContainerButton>
          <Button disabled={resendEmail} onClick={handleSendEmailConfimation}>
            <span>
              {translate("pendingEmailConfirmation.body.button-resend")}
            </span>
          </Button>

          <Button color="grey" onClick={handleLogout}>
            <span>
              {translate("pendingEmailConfirmation.body.button-exit")}
            </span>
          </Button>
        </ContainerButton>
      </>
    );
  };

  const ButtonSubmitComponent = (props) => {
    return <ButtonSubmit {...props}>{props.children}</ButtonSubmit>;
  };

  if (emailConfirmed) {
    return (
      <Container>
        <Logo src={LogoImg} />
        <Main>
          <ViewEmailConfirmedMessage />
        </Main>
      </Container>
    );
  }
  if (isPendingEmailConfirmation) {
    return (
      <Container>
        <Header>
          <Logo src={LogoImg} />
        </Header>
        <Main>
          <Mensagem className="justify">
            <p>{messageDefault}</p>
          </Mensagem>
          <ContainerField>
            <InputVerificationCodeLabel htmlFor="codeVerification">
              {translate("pendingEmailConfirmation.body.label")}
            </InputVerificationCodeLabel>
            <InputVerificationCode
              type="text"
              id="codeVerification"
              name="codeVerification"
              value={codeVerification}
              onChange={handleChangeCodeVerification}
              key={"codeVerification"}
            />
            <ButtonSubmitComponent
              disabled={disabledButton}
              onClick={handleCodeVerification}
            >
              <span>
                {translate("pendingEmailConfirmation.body.button-confirm")}
              </span>
            </ButtonSubmitComponent>
          </ContainerField>
          <ViewContainerButtom />

          <LinkChangeEmail onClick={() => goLink("/change-email")}>
            Alterar E-mail
          </LinkChangeEmail>
        </Main>
        <AlertCustom
          message={message}
          openMessage={openMessage}
          closeMessage={() => setOpenMessage(false)}
          transition="down"
          severity={alertSeverity}
          origin={{ vertical: "top", horizontal: "right" }}
        />
      </Container>
    );
  }

  return <Loading />;
};

export default PendingEmailConfirmation;
