import React, { useCallback, useState, useEffect, useMemo } from "react";
import { Grid } from "@material-ui/core";
import useWelcome from "../../../SelectTypeOfVacancy/useSelectTypeOfVacancy";
import { InputRadioCustom } from "components/form";
import { Regions } from "components/Regions";
import ReactSelect from "react-select";
import { useWorkWithUs } from "contexts/WorkWithUs.contexts";

import {
  Anime,
  Text,
  GroupRadiosCustom,
  VTBusLinesContainer,
} from "../../../Styled";
//import Api from "service/api";

const Region = () => {
  const { translate, transitionIn } = useWelcome();

  const { dataFields, updateDataField } = useWorkWithUs();

  const vtBusLinesDefault = useMemo(() => {
    return [
      {
        label: "Pega VT",
        value: "Pega VT",
      },
      {
        label: "Não pega VT",
        value: "Não pega VT",
      },
      {
        label: "Outros",
        value: "Outros",
      },
      {
        label: "Veículo Particular",
        value: "Veículo Particular"
      }
    ];
  }, []);

  const [selectedStateOption, setSelectedStateOption] = useState(() => {
    const work_with_us = JSON.parse(JSON.parse(localStorage.work_with_us));

    return work_with_us.regions?.state || null;
  });

  const [selectedRegionOption, setSelectedRegionOption] = useState(() => {
    const work_with_us = JSON.parse(JSON.parse(localStorage.work_with_us));

    return work_with_us.regions?.options || null;
  });

  const [vtBusLines, setVTBusLines] = useState(vtBusLinesDefault);

  const [selectedVtBusLines, setSelectedVTBusLines] = useState(() => {
    const work_with_us = JSON.parse(JSON.parse(localStorage.work_with_us));

    return work_with_us.vtBusLines?.options || null;
  });

  const setWorkWithUsState = useCallback((value) => {
    const work_with_us = JSON.parse(JSON.parse(localStorage.work_with_us));

    work_with_us.regions = {
      type: "regions",
      options: work_with_us.regions?.options || [],
      state: value ? { label: value.value, value: value.value } : null,
    };

    localStorage.work_with_us = JSON.stringify(
      JSON.stringify(work_with_us)
    );
  }, []);

  const setWorkWithUsRegion = useCallback((value) => {
    const work_with_us = JSON.parse(JSON.parse(localStorage.work_with_us));

    work_with_us.regions = {
      type: "regions",
      state: work_with_us.regions.state || null,
      options: value.map((val) => val.value),
    };

    localStorage.work_with_us = JSON.stringify(
      JSON.stringify(work_with_us)
    );
  }, []);

  const fillSelectedRegionSaved = useCallback(() => {
    const work_with_us = JSON.parse(JSON.parse(localStorage.work_with_us));

    const regionOptions = work_with_us?.regions?.options || null;

    const regionsOptionsSaved = [];

    if (regionOptions && regionOptions.length) {
      regionOptions.forEach((region) => {
        regionsOptionsSaved.push({
          label: region,
          value: region,
        });
      });
    }
  }, []);

/*
  const getVTBusLinesByCitiesId = useCallback(async () => {
    if (!selectedRegionOption || !selectedRegionOption.length) {
      return;
    }

    const cities_id = selectedRegionOption.map((region) => region.value);

    if (!cities_id || !cities_id.length) {
      return;
    }

    const {
      data: { vt_bus_lines: vt_bus_lines_by_cities },
    } = await Api.get(
      `cities/vt/bus/lines?cities_id=${cities_id.join("|")}`
    );

    setVTBusLines(
      vt_bus_lines_by_cities
        .map((line) => ({
          label: line.name,
          value: line.id,
        }))
        .concat(vtBusLinesDefault)
    );
  }, [selectedRegionOption]);
*/
  const handleChangeVTBusLines = useCallback(
    (value) => {
      updateDataField("vtBusLines", {
        ...{
          ...dataFields["vtBusLines"],
          options: value,
          type: "vtBusLines",
        },
      });

      setSelectedVTBusLines(value);
    },
    [dataFields, updateDataField]
  );

  const clearVtLines = useCallback(() => {
    const work_with_us = JSON.parse(JSON.parse(localStorage.work_with_us));

    work_with_us.vtBusLines = {
      type: "vtBusLines",
      options: [],
    };

    localStorage.work_with_us = JSON.stringify(
      JSON.stringify(work_with_us)
    );

    setVTBusLines(vtBusLinesDefault);

    setSelectedVTBusLines(null);
  }, [vtBusLinesDefault]);

  useEffect(() => {
    fillSelectedRegionSaved();

  }, [fillSelectedRegionSaved, vtBusLinesDefault]);

  return (
    <React.Fragment>
      <Grid container className="justify-content-center">
        <Grid item xs={11} md={10} lg={10} xl={10}>
          <Anime transition={transitionIn}>
            <Text center>
              {translate("workWithUs.selectTypeOfVacancy.info")}
            </Text>
            <GroupRadiosCustom>
              <InputRadioCustom
                icon="icon-location"
                label="Região"
                name="region"
                onChange={() => { }}
                checked={false}
              />
            </GroupRadiosCustom>
          </Anime>
        </Grid>
      </Grid>

      <Regions
        selectedStateOption={selectedStateOption}
        selectedRegionOption={selectedRegionOption}
        setSelectedStateOption={setSelectedStateOption}
        setSelectedRegionOption={setSelectedRegionOption}
        fnState={setWorkWithUsState}
        getSelectedRegions={() => { }}
        fnRegion={setWorkWithUsRegion}
        clearVtLines={clearVtLines}
      />

      <VTBusLinesContainer>
        <ReactSelect
          value={selectedVtBusLines}
          onChange={handleChangeVTBusLines}
          options={vtBusLines}
          isMulti
          className="react-select"
          placeholder="Selecione a(s) linhas de ônibus"
          noOptionsMessage={() => "Nenhum item encontrado"}
        />
      </VTBusLinesContainer>
    </React.Fragment>
  );
};

export default Region;
