import React from "react";
import { Grid } from "@material-ui/core";

import { InputMaskCep, InputSelect } from "components/form";
import useAddress from "./useAddress";

import { Subtitle, GroupField, Input, Small } from "../../../Styled";

const Address = () => {
  const { translate, dataFields, errors, handleChangeText, logradouroList } =
    useAddress();

  return (
    <React.Fragment>
      <Subtitle>
        {translate("workWithUs.form.step4.title")}
        <br />
        <Small>{translate("workWithUs.requiredFields")}</Small>
      </Subtitle>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={4} xl={3}>
          <GroupField>
            <Input
              fullWidth
              transparent="true"
              type="tel"
              id="cep"
              name="cep"
              label={translate("workWithUs.form.step4.cep") + " (*)"}
              autoComplete="off"
              value={dataFields?.address?.cep || ""}
              onChange={(props) => handleChangeText(props, "address")}
              error={errors?.address?.cep}
              InputProps={{
                inputComponent: InputMaskCep,
              }}
            />
          </GroupField>
        </Grid>

        <Grid item xs={12} md={4} lg={4} xl={4}>
          <GroupField>
            <InputSelect
              fullWidth
              transparent="true"
              id="publicPlace"
              name="publicPlace"
              label={translate("workWithUs.form.step4.publicPlace") + " (*)"}
              labeldark={false}
              autoComplete="off"
              value={dataFields?.address?.publicPlace || ""}
              onChange={(props) => handleChangeText(props, "address")}
              options={logradouroList}
              error={errors?.address?.publicPlace}
            />
          </GroupField>
        </Grid>

        <Grid item xs={12} md={12} lg={12} xl={12}>
          <GroupField>
            <Input
              fullWidth
              transparent="true"
              id="street"
              name="street"
              label={translate("workWithUs.form.step4.street") + " (*)"}
              autoComplete="off"
              value={dataFields?.address?.street || ""}
              onChange={(props) => handleChangeText(props, "address")}
              error={errors?.address?.street}
            />
          </GroupField>
        </Grid>
        <Grid item xs={12} md={12} lg={4} xl={4}>
          <GroupField>
            <Input
              fullWidth
              transparent="true"
              id="number"
              name="number"
              label={translate("workWithUs.form.step4.number") + " (*)"}
              autoComplete="off"
              value={dataFields?.address?.number || ""}
              onChange={(props) => handleChangeText(props, "address")}
              error={errors?.address?.number}
            />
          </GroupField>
        </Grid>

        <Grid item xs={12} md={12} lg={4} xl={4}>
          <GroupField>
            <Input
              fullWidth
              transparent="true"
              id="complement"
              name="complement"
              label={translate("workWithUs.form.step4.complement")}
              autoComplete="off"
              value={dataFields?.address?.complement || ""}
              onChange={(props) => handleChangeText(props, "address")}
              error={errors?.address?.complement}
            />
          </GroupField>
        </Grid>

        <Grid item xs={12} md={12} lg={4} xl={4}>
          <GroupField>
            <Input
              fullWidth
              transparent="true"
              id="neighborhood"
              name="neighborhood"
              label={translate("workWithUs.form.step4.neighborhood") + " (*)"}
              autoComplete="off"
              value={dataFields?.address?.neighborhood || ""}
              onChange={(props) => handleChangeText(props, "address")}
              error={errors?.address?.neighborhood}
            />
          </GroupField>
        </Grid>
        <Grid item xs={12} md={8} lg={8} xl={9}>
          <GroupField>
            <Input
              fullWidth
              transparent="true"
              id="city"
              name="city"
              label={translate("workWithUs.form.step4.city") + " (*)"}
              autoComplete="off"
              value={dataFields?.address?.city || ""}
              onChange={(props) => handleChangeText(props, "address")}
              error={errors?.address?.city}
            />
          </GroupField>
        </Grid>
        <Grid item xs={12} md={4} lg={4} xl={3}>
          <GroupField>
            <Input
              fullWidth
              transparent="true"
              id="state"
              name="state"
              label={translate("workWithUs.form.step4.state") + " (*)"}
              autoComplete="off"
              value={dataFields?.address?.state || ""}
              inputProps={{ maxLength: 2 }}
              onChange={(props) => handleChangeText(props, "address")}
              error={errors?.address?.state}
            />
          </GroupField>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default Address;
