import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { AuthProvider } from "./contexts/Auth.contexts";

import GlobalFonts from "./assets/fonts";
import GlobalIcons from "./assets/icons";
import GlobalStyled from "./assets/globalStyled";

import Router from "./Router";
import Store from "./store";

import "./i18n";

//navigator.serviceWorker.register('service-worker.js');

const App = () => (
    <React.Fragment>
        <GlobalIcons />
        <GlobalFonts />
        <GlobalStyled />
        <AuthProvider>
            <Provider store={Store}>
                <BrowserRouter>
                    <Router />
                </BrowserRouter>
            </Provider>
        </AuthProvider>
    </React.Fragment>
);

export default App;
