import React, { memo } from "react";
import { BtnDefault, BtnDark } from "./Styled";

const ButtonCustom = (props) => <Btn {...props}>{props.children}</Btn>;

const Btn = (props) => {
    switch (props.color) {
        case "default":
            return <BtnDefault {...props}>{props.children}</BtnDefault>;
        case "dark":
            return <BtnDark {...props}>{props.children}</BtnDark>;
        default:
            return <BtnDefault {...props}>{props.children}</BtnDefault>;
    }
};

export default memo(ButtonCustom);
