import React, { useCallback, useState } from "react";
import { Container, Grid } from "@material-ui/core";
import Loading from "components/loading";
import ButtonCustom from "components/buttonCustom";
import useWelcome from "./useWelcome";
import { Modal, ModalBody, ModalTitle } from "./Styled";

import {
  Main,
  HeaderPage,
  Anime,
  Image,
  Title,
  Text,
  BtnGroupGoBack,
  BtnGroup,
  Icon,
} from "../Styled";

import Altum from "assets/images/altum.png";

const Welcome = () => {
  const [openModal, setOpenModal] = useState(false);

  const { transitionIn, loadingPage, translate, hadleGoback, hadleGoLink } =
    useWelcome();

  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
    hadleGoLink("/trabalhe-conosco/selecione-tipo-vaga");
  },[setOpenModal, hadleGoLink]);

  if (loadingPage) {
    return <Loading />;
  }

  return (
    <React.Fragment>
      <Modal open={openModal}>
        <ModalTitle>TERMOS DE PRIVACIDADE</ModalTitle>

        <ModalBody>
          TERMOS DE PRIVACIDADE - DE ACORDO COM A LEI GERAL DE PROTEÇÃO DE DADOS
          – LGPD Este documento visa registrar a manifestação livre, informada e
          inequívoca pela qual o titular concorda com o tratamento de seus dados
          pessoais para finalidade específica previstas e em conformidade com os
          incisos I e II do Artigo 7º , a Lei 13709/18 LEI GERAL DE PROTEÇÃO DE
          DADOS PESSOAIS (LGPD). Estamos empenhados em salvaguardar a sua
          privacidade ao proceder/realizar este processo seletivo. Este termo
          tem a finalidade de deixar o mais claro possível a nossa política de
          coleta e compartilhamento de dados, informando sobre os dados
          coletados e como os utilizamos. Ao participar deste processo seletivo,
          você declara o seu EXPRESSO CONSENTIMENTO para podermos armazenar
          informações pessoais, específicas e concernentes ao PROCESSO DE
          RECRUTAMENTO E SELEÇÃO para integrar em nosso quadro de COLABORADORES.
          Do Embasamento Legal. A lei Geral de Proteção de dados ( Lei
          13709/2018) traz em seu bojo a expressa autorização para tratamento de
          dados do titular em casos : No artigo 7º, I - mediante o fornecimento
          de consentimento pelo titular; II - para o cumprimento de obrigação
          legal ou regulatória pelo controlador; Os dados coletados dos
          candidatos serão utilizados UNICAMENTE para fins de recrutamento nesta
          oportunidade e, não sendo aprovado, a ficha do candidato permanecerá
          conosco em nosso banco de dados por 6 (seis) meses com objetivo de
          oportunizar ao candidato em novas avaliações nesse período a vaga de
          emprego buscada, após esse prazo, qualquer informação enviada será
          descartada. Comunicação. Não utilizaremos seus dados para enviar
          e-mails de marketing ou mala direta. Toda a comunicação será via
          Whatsapp, ligação telefônica ou contato presencial respeitando os
          protocolos de segurança determinados pelos órgãos oficiais de proteção
          à saúde devido ao estado de pandemia.
        </ModalBody>

        <ButtonCustom className="btn-green" onClick={handleCloseModal}>
          Concordo
        </ButtonCustom>
      </Modal>

      <Main>
        <HeaderPage>
          <BtnGroupGoBack transition={transitionIn}>
            <ButtonCustom
              color="default"
              type="button"
              size="large"
              className="btn-goback"
              onClick={hadleGoback}
              fullWidth
            >
              <Icon className="icon icon-arrow" />
            </ButtonCustom>
          </BtnGroupGoBack>
        </HeaderPage>
        <Container>
          <Anime transition={transitionIn}>
            <Image
              transition={transitionIn}
              src={Altum}
              alt="Grupo Altum"
              width="130px"
            />
          </Anime>

          <Grid className="justify-content-center" container>
            <Grid item xs={11} md={10} lg={10} xl={10}>
              <Title transition={transitionIn}>
                {translate("workWithUs.welcome.title")}
              </Title>

              <Grid className="justify-content-center" container>
                <Grid item xs={11} md={10} lg={6} xl={6}>
                  <Anime transition={transitionIn}>
                    <Text center>
                      {translate("workWithUs.welcome.subtitle")}
                    </Text>
                    <Text center>
                      {translate("workWithUs.welcome.description")}
                    </Text>
                  </Anime>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={11} md={10} lg={10} xl={10}>
              <Grid className="justify-content-center" container>
                <Grid item xs={12} md={6} lg={3} xl={3}>
                  <BtnGroup transition={transitionIn}>
                    <ButtonCustom
                      color="default"
                      type="button"
                      size="large"
                      className="btn-yellow"
                      onClick={() => setOpenModal(true)}
                      fullWidth
                    >
                      {translate("workWithUs.advance")}
                    </ButtonCustom>
                  </BtnGroup>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Main>
    </React.Fragment>
  );
};

export default Welcome;
