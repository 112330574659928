import styled from "styled-components";
import MuiDatePicker from "@mui/lab/DatePicker";

export const Container = styled.div`
    width: 100%;

    .MuiFormControl-root {
        width: 100%;
    }

    .MuiOutlinedInput-root {
        height: 54px;
    }

    fieldset {
        border-color: ${(props) => props.error ? 'red' : '#fff'}  !important;
    }

    fieldset:hover {
        border-color: #fff !important;
    }

    fieldset:focus {
        border-color: #fff !important;
    }

    fieldset:active {
        border-color: #fff !important;
    }

    input {
        pointer-events: none;
        outline: none;
        color:  ${(props) => props.error ? 'red' : '#ddd'};
    }

    svg {
        color:  ${(props) => props.error ? 'red' : '#ddd'};
    }
`;

export const Content = styled(MuiDatePicker)`
    border: 1px solid #fff;
    width: 100%;
    padding-left: 16px;
`;

export const Label = styled.label`
    color:  ${(props) => props.error ? 'red' : '#fff'};
`;
