import React, { memo } from "react";
import { GroupChecked, Checkbox } from "./Styled";

const InputChecked = (props) => (
    <GroupChecked marginTop={props.marginTop || 0}>
        <Checkbox
            id={props.name}
            name={props.name}
            value={props.value}
            checked={props.checked}
            onChange={props.onChange}
            inputProps={{ "aria-label": "primary checkbox" }}
        />
        {props.label && <label htmlFor={props.name}>{props.label}</label>}
    </GroupChecked>
);

export default memo(InputChecked);
