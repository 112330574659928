import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Utils } from "utils";

const useMessagesSendSuccess = () => {
    const [loadingPage, setLoadingPage] = useState(true);
    const [transitionIn, setTransitionIn] = useState(false);
    const history = useHistory();
    const { t: translate } = useTranslation();

    document.title = translate("messageSendSuccess.head.title");

    useEffect(() => {
        window.scrollTo(0, 0);
        setTimeout(() => {
            setLoadingPage(false);
        }, 600);

        setTimeout(() => {
            setTransitionIn(true);
        }, 800);
    }, []);

    const hadleGoback = (link) => {
        history.push(Utils.setPrefix(link));
    };

    return {
        transitionIn,
        loadingPage,
        hadleGoback,
        translate,
    };
};

export default useMessagesSendSuccess;
