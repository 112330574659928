import React, {
  useState,
  useEffect,
  useContext,
  createContext,
  useRef,
  useCallback,
} from "react";
import mqtt from "mqtt";
import Store from "store";
import Loading from "components/loading";
import Api from "service/api";
import AlertCustom from "components/alertCustom";
import { bool } from "prop-types";

const AuthContext = createContext({
  loadingPage: true,
  signed: false,
  contractAccepted: false,
  message: "",
  profile: {},
  AddProfile: () => {},
  RemoveProfile: () => {},
  SignIn: () => {},
  SignOut: () => {},
  notificationComunication: [],
  setGlobalAlert: () => {},
  globalAlert: false,
  setAlertMessageDetails: () => {},
  isFired: false,
});

export const AuthProvider = ({ children }) => {
  const [loadingPage, setLoadingPage] = useState(true);
  const [signed, setSigned] = useState(false);
  const [notificationIncoming, setNotificationIncoming] = useState(false);
  const [updatePassword, setUpdatePassword] = useState(false);
  const [isPendingEmailConfirmation, setIsPendingEmailConfirmation] =
    useState(false);
  const [emailPending, setEmailPending] = useState("");
  const [contractAccepted, setContractAccepted] = useState(null);
  const [message, setMessage] = useState("");
  const [profile, setProfile] = useState({});
  const [globalAlert, setGlobalAlert] = useState(false);
  const [isFired, setIsFired] = useState(false);
  const [alertMessageDetails, setAlertMessageDetails] = useState({
    'title': '', 'message': '', 'severity': ''
  });

  const isMounted = useRef(null);

  const configMqtt = useCallback(
    async (pessoaId) => {
      // Configurar o cliente MQTT
      const mqttClient = mqtt.connect("wss://mqttbroker.qualityinteligencia.com.br:8084/mqtt");

      mqttClient.on("connect", () => {
        console.log("Conectado ao broker MQTT");
        const topic = `comunicacao/response-app-${pessoaId}`;
        mqttClient.subscribe(topic, (err) => {
          if (!err) {
            console.log("Inscrito no tópico: " + topic);
          }
        });
      });

      mqttClient.on("message", (topic, message) => {
        console.log(`Mensagem recebida: ${message.toString()}`);
        setNotificationIncoming(true);
      });

      return () => {
        if (mqttClient) {
          mqttClient.end();
        }
      };
    },
    [profile.pess_id_pessoa]
  );

  useEffect(() => {
    (async () => {
      await isAuth();
    })();

    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const isAuth = async () => {
    if (Store.get("token_altum") === undefined) {
      setSigned(false);
      setTimeout(() => {
        setLoadingPage(false);
      }, 500);
    } else {
      setSigned(false);
      setLoadingPage(true);

      Api.defaults.headers.Authorization = `Bearer ${Store.get("token_altum")}`;

      await Api.get("/usuario").then((response) => {
        if (isMounted.current) {
          const {
            termo_uso,
            usuario,
            confirmacao_email_pendente,
            email_pending,
            fired,
          } = response.data;
          const { pess_solicita_senha } = usuario;
          setProfile(usuario);
          setSigned(usuario !== undefined);
          setUpdatePassword(
            pess_solicita_senha !== undefined && pess_solicita_senha === "S"
              ? true
              : false
          );
          setIsPendingEmailConfirmation(confirmacao_email_pendente);
          setIsFired(fired);
          setEmailPending(email_pending);
          setContractAccepted(termo_uso);
          configMqtt(usuario.pess_id_pessoa);
          setTimeout(() => {
            setLoadingPage(false);
          }, 500);
        }
      });
    }
  };

  const AddProfile = (profile) => {
    setProfile(profile);
  };

  const RemoveProfile = () => {
    setProfile({});
  };

  const SignIn = () => {
    setSigned(true);
  };

  const SignOut = () => {
    setSigned(false);
  };

  if (loadingPage) {
    return <Loading />;
  }

  if (globalAlert) {
    return (
      <AlertCustom
        message={alertMessageDetails.message}
        openMessage={alertMessageDetails.title}
        severity={alertMessageDetails.severity}
        closeMessage={() => setGlobalAlert(false)}
        transition="down"
        origin={{ vertical: "top", horizontal: "right" }}
      />
    );
  }

  return (
    <AuthContext.Provider
      value={{
        signed,
        updatePassword,
        isPendingEmailConfirmation,
        setIsPendingEmailConfirmation,
        contractAccepted,
        profile,
        message,
        setUpdatePassword,
        setContractAccepted,
        setLoadingPage,
        setMessage,
        AddProfile,
        RemoveProfile,
        SignIn,
        SignOut,
        isAuth,
        emailPending,
        notificationIncoming,
        setGlobalAlert,
        globalAlert,
        setAlertMessageDetails,
        isFired,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  const context = useContext(AuthContext);
  return context;
}
