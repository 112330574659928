import { createGlobalStyle } from "styled-components";

import IcomoonEot from "./fonts/icomoon.eot";
import IcomoonWoff from "./fonts/icomoon.woff";
import IcomoonTtf from "./fonts/icomoon.ttf";
import IcomoonSvg from "./fonts/icomoon.svg";

export default createGlobalStyle`
    @font-face {
        font-family: 'icomoon';
        src: url('${IcomoonEot}?ofkby7');
        src: url('${IcomoonEot}?ofkby7#ofkby7') format('embedded-opentype'),
          url('${IcomoonTtf}?ofkby7') format('truetype'),
          url('${IcomoonWoff}?ofkby7') format('woff'),
          url('${IcomoonSvg}?ofkby7#icomoon') format('svg');
        font-weight: normal;
        font-style: normal;
        font-display: block;
    }

    [class^="icon-"], [class*=" icon-"] {
        font-family: 'icomoon' !important;
        speak: never;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;

        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    .icon-video:before {
        content: "\\e900";
    }

    .icon-map:before {
        content: "\\e90e";
    }

    .icon-view:before {
        content: "\\e901";
    }
    .icon-play:before {
        content: "\\e902";
    }
    .icon-close:before {
        content: "\\e903";
    }
    .icon-check:before {
        content: "\\e904";
    }
    .icon-document:before {
        content: "\\e905";
    }
    .icon-plus:before {
        content: "\\e906";
    }
    .icon-hide-view:before {
        content: "\\e907";
    }
    .icon-download:before {
        content: "\\e908";
    }
    .icon-group:before {
        content: "\\e909";
    }
    .icon-deficient:before {
        content: "\\e90a";
    }
    .icon-send:before {
        content: "\\e90b";
    }
    .icon-policeman:before {
        content: "\\e90c";
    }
    .icon-arrow:before {
        content: "\\e90d";
    }
    .icon-attached:before {
        content: "\\e90e";
    }
    .icon-document-edit:before {
        content: "\\e90f";
    }
    .icon-delete:before {
        content: "\\e910";
    }
    .icon-location:before {
        content: "\\e911";
    }

`;
