import styled from "styled-components";
import ButtonCustom from "components/buttonCustom";
import Theme from "Theme";

export const Item = styled.div`
    width: 100%;
    height: 40px;
    color: ${Theme.colorWhite};
    font-size: 14px;
    font-weight: 400;
    border-radius: 0px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    padding-right: 20px;
    position: relative;

    @media (max-width: 768px) {
        justify-content: space-around;
    }
`;

export const ItemGroup = styled.div``;

export const ItemTitle = styled.h3`
    color: ${Theme.colorYellow};
    font-size: 16px;
    margin-bottom: 5px;
`;

export const ItemText = styled.h3`
    color: ${Theme.colorWhite};
    line-height: 1.3;
    margin-bottom: 10px;
`;

export const ButtonClose = styled(ButtonCustom)`
   && {
        width: 20px;
        height: 100%;
        background: transparent;
        color: ${Theme.colorWhite};
        position: absolute;
        right: 0;

        @media (max-width: 768px) {
            position: static;
        }

        &:hover {
            background: transparent;
        }
   }
`;


export const Icon = styled.span`
    color: ${Theme.colorGray};
    font-size: 20px;
`;
