import React, { useCallback, useState } from "react";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import ptBrLocale from "date-fns/locale/pt-BR";

import { Container, Content, Label } from "./Styled";

export function DatePicker(props) {
    const [open, setOpen] = useState(false);
    const handleClick = useCallback(() => {
        setOpen(true);
    }, []);

    const handleAccept = useCallback(() => {
        setTimeout(() => {
            setOpen(false);
        }, 100);
    }, []);
    return (
        <Container onClick={handleClick} error={props.error}>
            <Label error={props.error}>{props.label}</Label>
            <LocalizationProvider
                dateAdapter={AdapterDateFns}
                locale={ptBrLocale}
            >
                <Content
                    open={open}
                    value={props.value}
                    onChange={(newValue) => {
                        props.onChange(newValue);
                        setOpen(false);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                    disableHighlightToday
                    onAccept={handleAccept}
                />
            </LocalizationProvider>
        </Container>
    );
}
