import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Store from "store";
import { useTranslation } from "react-i18next";
import { useAuth } from "contexts/Auth.contexts";
import { Utils } from "utils";

const Logout = () => {
    const history = useHistory();
    const { SignOut, RemoveProfile, setContractAccepted } = useAuth();
    const { t: translate } = useTranslation();

    useEffect(() => {
        document.title = translate("logout.head.title");
        Store.remove("token_altum");
        Store.remove("ead_token");
        setContractAccepted(null);
        RemoveProfile();
        SignOut();
       // console.log("aqui");
        history.push(Utils.setPrefix("/login"));
    });

    return null;
};

export default Logout;
