import React, { useCallback, useState } from "react";
import { Grid } from "@material-ui/core";
import ButtonCustom from "components/buttonCustom";
import ItemCourse from "components/itemCourse";
import useSpecializations from "./useSpecializations";
import { Subtitle, GroupCouses, GroupField, Icon } from "../../../Styled";
import { useMemo } from "react";
import { WorkWithUSAddCourse } from "components/WorkWithUSAddCourse";

import {
  InputUploadDoc
} from "../../../../../screens/workWithUs/Styled";

const Specializations = () => {
  const {
    translate,
    dataFields,
    handleRemoveCourse,
    handleChangeFile,
    handleRemoveFile,
    errors,
  } = useSpecializations();

  const isVigilant = useMemo(() => {
    return dataFields.typeOfVacancy?.type === "1";
  }, [dataFields]);

  const [courseType, setCourseType] = useState("");

  const [showAddCourse, setShowAddCourse] = useState(false);

  const handleSave = useCallback(() => {
    setShowAddCourse(false);
  }, []);

  const handleShowAddCourse = useCallback((courseType) => {
    setCourseType(courseType);
    setShowAddCourse(true);
  }, []);
   //console.log(errors)
  return (
    <React.Fragment>
      {showAddCourse && (
        <WorkWithUSAddCourse courseType={courseType} handleSave={handleSave} />
      )}

      {!showAddCourse && (
        <GroupCouses>
          <Grid container spacing={2}>
            {isVigilant && (
              <>
                <Grid item xs={12} md={12}>
                  <GroupField>
                  <Subtitle>
                        Anexar Cópia CNV (Obrigatório para vagas de Vigilante)
                    </Subtitle>
                    <InputUploadDoc
                      className="btn-dark"
                      icon="icon-document-edit"
                      name="cnv_anexo"
                      label="Anexar Cópia CNV (Obrigatório para vagas de Vigilante)"
                      file={dataFields?.cnv_anexo?.name}
                      onChange={handleChangeFile}
                      onRemove={() => handleRemoveFile("cnv_anexo")}
                      error={errors.specializations?.cnv_anexo}
                    />
                  </GroupField>
                </Grid>

                <Grid item xs={12} md={12} lg={12} xl={12}>
                  <GroupField>
                    <Subtitle>
                      {translate("workWithUs.form.step5.guardTrainingCourse")}
                    </Subtitle>
                    {dataFields?.guardTrainingCourse?.map((item, index) => (
                      <ItemCourse
                        key={index}
                        onRemove={() =>
                          handleRemoveCourse("guardTrainingCourse", item.name)
                        }
                      >
                        {item.name}
                      </ItemCourse>
                    ))}
                    <ButtonCustom
                      color="default"
                      type="button"
                      size="large"
                      className="btn-dark"
                      startIcon={<Icon className="icon icon-plus" />}
                      onClick={() => handleShowAddCourse("guardTrainingCourse")}
                      fullWidth
                    >
                      {translate("workWithUs.form.step5.addCourse")}
                    </ButtonCustom>
                  </GroupField>
                </Grid>
              </>
            )}

            {isVigilant && (
              <>
                <Grid item xs={12} md={12} lg={12} xl={12}>
                  <GroupField>
                    <Subtitle>
                      {translate("workWithUs.form.step5.lastRecycle")}
                    </Subtitle>
                    {dataFields?.lastRecycle?.map((item, index) => (
                      <ItemCourse
                        key={index}
                        onRemove={() =>
                          handleRemoveCourse("lastRecycle", item.name)
                        }
                      >
                        {item.name}
                      </ItemCourse>
                    ))}
                    <ButtonCustom
                      color="default"
                      type="button"
                      size="large"
                      className="btn-dark"
                      startIcon={<Icon className="icon icon-plus" />}
                      onClick={() => handleShowAddCourse("lastRecycle")}
                      fullWidth
                    >
                      {translate("workWithUs.form.step5.addCourse")}
                    </ButtonCustom>
                  </GroupField>
                </Grid>
              </>
            )}

            <Grid item xs={12} md={12} lg={12} xl={12}>
              <GroupField>
                <Subtitle>
                  {translate("workWithUs.form.step5.extensionCourses")}
                </Subtitle>
                {dataFields?.extensionCourse?.map((item, index) => (
                  <ItemCourse
                    key={index}
                    onRemove={() =>
                      handleRemoveCourse("extensionCourse", item.name)
                    }
                  >
                    {item.name}
                  </ItemCourse>
                ))}
                <ButtonCustom
                  color="default"
                  type="button"
                  size="large"
                  className="btn-dark"
                  startIcon={<Icon className="icon icon-plus" />}
                  onClick={() => handleShowAddCourse("extensionCourse")}
                  fullWidth
                >
                  {translate("workWithUs.form.step5.addCourse")}
                </ButtonCustom>
              </GroupField>
            </Grid>
          </Grid>
        </GroupCouses>
      )}
    </React.Fragment>
  );
};

export default Specializations;
