import React, { memo } from "react";
import ButtonBase from "@material-ui/core/ButtonBase";

import { CheckboxCustom } from "./Styled";

const InputCheckboxCustom = ({ label, name, value, onChange, checked }) => (
    <CheckboxCustom htmlFor={name}>
        <ButtonBase>
            <input
                type="checkbox"
                id={name}
                name={name}
                value={value}
                onChange={onChange}
                checked={checked}
            />
            <div className="bg">
                <span>{label}</span>
            </div>
        </ButtonBase>
    </CheckboxCustom>
);

export default memo(InputCheckboxCustom);
