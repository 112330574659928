import React, { memo } from "react";

import { Item, ItemGroup, ItemTitle, ItemText, ButtonClose, Icon } from "./Styled";

const ItemExperience = ({ onRemove, company, office, startDate, endDate }) => {
    return (
        <Item>
            <ItemGroup>
                <ItemTitle>{company}</ItemTitle>
                <ItemText>{office} | {startDate} - {endDate}</ItemText>
            </ItemGroup>
            <ButtonClose onClick={onRemove}>
                <Icon className="icon icon-delete" />
            </ButtonClose>
        </Item>
    );
}

export default memo(ItemExperience);
