import { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useWorkWithUs } from "contexts/WorkWithUs.contexts";
import { Utils } from "utils";

const useModalProfessionExperience = () => {
    const [fieldsModal, setFieldsModal] = useState({});
    const [errorModal, setErrorModal] = useState({});
    const { dataFields, openModal, updateDataField, handleCloseModal } =
        useWorkWithUs();
    const { t: translate } = useTranslation();

    const onValidate = useCallback(() => {
        const newErrors = {
            compay: false,
            office: false,
            startDate: false,
            endDate: false,
        };
        let status = false;

        if (!fieldsModal?.company || fieldsModal?.company.length < 2) {
            newErrors.company = true;
            status = true;
        }
        if (!fieldsModal?.office || fieldsModal?.office.length < 2) {
            newErrors.office = true;
            status = true;
        }
        if (
            !fieldsModal?.startDate ||
            Utils.clearMask(fieldsModal?.startDate).length < 8
        ) {
            newErrors.startDate = true;
            status = true;
        }
        if (
            fieldsModal?.actualjob === undefined ||
            fieldsModal?.actualjob === false
        ) {
            if (
                !fieldsModal?.endDate ||
                Utils.clearMask(fieldsModal?.endDate).length < 8
            ) {
                newErrors.endDate = true;
                status = true;
            }
        }

        setErrorModal({ ...newErrors });
        return status;
    },[fieldsModal.actualjob,fieldsModal.company, fieldsModal.endDate, fieldsModal.office, fieldsModal.startDate]);

    const handleChangeText = useCallback(
        ({ target }) => {
            setFieldsModal({ ...fieldsModal, [target.name]: target.value });
            setErrorModal({ ...errorModal, [target.name]: false });
        },
        [fieldsModal, errorModal]
    );

    const handleChangeCheckbox = useCallback(
        ({ target }) => {
            setFieldsModal(() => {
                if (target.checked) {
                    delete fieldsModal.endDate;
                    return { ...fieldsModal, [target.name]: target.checked };
                }

                return { ...fieldsModal, [target.name]: target.checked };
            });
        },
        [fieldsModal]
    );

    const handleAddCourse = useCallback(() => {
        console.log(openModal.extra);
        const courses =
            dataFields[openModal.extra] !== undefined
                ? [...dataFields[openModal.extra]]
                : [];

        if (onValidate()) {
            return;
        }

        courses.push({
            company: fieldsModal?.company,
            office: fieldsModal?.office,
            startDate: fieldsModal?.startDate,
            endDate: fieldsModal?.endDate || "Atualmente",
        });
        updateDataField(openModal.extra, courses);
        handleCloseModal(true);
        setFieldsModal({});
    }, [
        dataFields,
        openModal.extra,
        onValidate,
        fieldsModal.company,
        fieldsModal.office,
        fieldsModal.startDate,
        fieldsModal.endDate,
        updateDataField,
        handleCloseModal,
    ]);

    return {
        translate,
        fieldsModal,
        dataFields,
        errorModal,
        openModal,
        handleCloseModal,
        handleChangeText,
        handleChangeCheckbox,
        handleAddCourse,
    };
};

export default useModalProfessionExperience;
