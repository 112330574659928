import React from "react";
import { withRouter, Switch, Route, Redirect } from "react-router-dom";
import { Utils } from "utils";

// Páginas
import PendingEmailConfimation from "screens/pendingEmailConfirmation";
//import Home from "screens/home";
import Logout from "screens/logout";
import ChangeEmail from "screens/changeEmail/ChangeEmail";

const PendingConfimationEmail = () => (
  <React.Fragment>
    <Switch>
      <Route
        exact
        path={Utils.setPrefix("/pending-email-confirmation")}
        component={PendingEmailConfimation}
      />
      <Route
        exact
        path={Utils.setPrefix("/change-email")}
        component={ChangeEmail}
      />
      <Route exact path={Utils.setPrefix("/logout")} component={Logout} />
      <Redirect to={Utils.setPrefix("/pending-email-confirmation")} />
    </Switch>
  </React.Fragment>
);

export default withRouter(PendingConfimationEmail);
