import React, { memo } from "react";
import { Grid } from "@material-ui/core";
import Form, { InputDate } from "components/form";
import ButtonCustom from "components/buttonCustom";

import {
    Modal,
    ModalTitle,
    ModalBody,
    GroupField,
    Input,
    InputFileContainer,
    InputFileText,
} from "./Styled";

import useModalAddCourse from "./useModalAddCourse";
import { Icon } from "screens/workWithUs/Styled";

const ModalAddCourse = () => {
    const {
        translate,
        attachCertificateText,
        fieldsModal,
        openModal,
        errorModal,
        handleCloseModal,
        handleChangeText,
        handleAddCourse,
        handleChangeFileCertificate,
    } = useModalAddCourse();

    return (
        <Modal
            onClose={handleCloseModal}
            open={openModal?.name === "ModalAddCourse"}
        >
            <ModalTitle>
                {translate("workWithUs.modalAddCourse.title")}
            </ModalTitle>
            <ModalBody>
                <Form>
                    <Grid container justifyContent="center">
                        <Grid item xs={12} md={10}>
                            <GroupField>
                                <Input
                                    fullWidth
                                    transparent="true"
                                    id="name"
                                    name="name"
                                    label={translate(
                                        "workWithUs.modalAddCourse.form.course"
                                    )}
                                    autoComplete="off"
                                    value={fieldsModal?.name || ""}
                                    onChange={(props) =>
                                        handleChangeText(props, "courses")
                                    }
                                    error={errorModal?.name}
                                />
                            </GroupField>
                        </Grid>
                        <Grid item xs={12} md={10}>
                            <GroupField>
                                <Input
                                    fullWidth
                                    transparent="true"
                                    id="academy"
                                    name="academy"
                                    label={translate(
                                        "workWithUs.modalAddCourse.form.academy"
                                    )}
                                    autoComplete="off"
                                    value={fieldsModal?.academy || ""}
                                    onChange={(props) =>
                                        handleChangeText(props, "courses")
                                    }
                                    error={errorModal?.academy}
                                />
                            </GroupField>
                        </Grid>
                        <Grid item xs={12} md={10}>
                            <GroupField>
                                <Input
                                    fullWidth
                                    transparent="true"
                                    id="dateOfTheConclusion"
                                    name="dateOfTheConclusion"
                                    label={translate(
                                        "workWithUs.modalAddCourse.form.dateOfTheConclusion"
                                    )}
                                    autoComplete="off"
                                    value={
                                        fieldsModal?.dateOfTheConclusion || ""
                                    }
                                    onChange={(props) =>
                                        handleChangeText(props, "courses")
                                    }
                                    error={errorModal?.dateOfTheConclusion}
                                    InputProps={{
                                        inputComponent: InputDate,
                                    }}
                                />
                            </GroupField>
                        </Grid>
                        <Grid item xs={12} md={10}>
                            <GroupField>
                                <InputFileContainer>
                                    <Icon className="icon icon-document-edit" />

                                    <InputFileText>
                                        {attachCertificateText}
                                    </InputFileText>
                                    <input
                                        type="file"
                                        className="display-none"
                                        id="input-certificate-file"
                                        onChange={(e) =>
                                            handleChangeFileCertificate(e)
                                        }
                                    />
                                </InputFileContainer>
                            </GroupField>
                        </Grid>
                    </Grid>
                </Form>
            </ModalBody>
            <ButtonCustom className="btn-green" onClick={handleAddCourse}>
                {translate("workWithUs.save")}
            </ButtonCustom>
        </Modal>
    );
};

export default memo(ModalAddCourse);
