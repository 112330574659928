import React from "react";
import { Container, Grid } from "@material-ui/core";
import Loading from "components/loading";

import useDocuments from "./useDocuments";
import ModalCustom from "./modalCustom";
import AlertCustom from "components/alertCustom";

import {
  Main,
  ContentPage,
  Transition,
  Title,
  WrapDocuments,
  Document,
  Icon,
} from "./Styled";

const ButtonDocument = ({ label, documentStatus, onClick }) => (
  <Document onClick={onClick}>
    {documentStatus === "ACEITO" && <Icon className="icon icon-check" />}
    <span>&nbsp;{label}</span>
    <Icon className="icon icon-view" />
  </Document>
);

const TermsOfUser = () => {
  const {
    transitionIn,
    loadingPage,
    loadingForm,
    loadingModal,
    openModal,
    documents,
    contentModal,
    modalType,
    message,
    messageType,
    openMessage,
    refSignature,
    handleClearSignature,
    handleSendSignature,
    translate,
    handleOpenModal,
    handleCloseModal,
    handleShowAccept,
    handleOpenAlert,
  } = useDocuments();

  if (loadingPage) {
    return <Loading />;
  }

  return (
    <React.Fragment>
      <Main>
        <Container>
          <ContentPage loadingForm={loadingForm}>
            <Title transition={transitionIn}>
              {translate("documents.title")}
            </Title>
            <Grid container justifyContent="center">
              <Grid item xs={11} md={10}>
                <Transition transitionIn={transitionIn}>
                  <WrapDocuments>
                    {documents?.map((item, index) => (
                      <ButtonDocument
                        key={index}
                        label={item.nome_documento}
                        documentStatus={item.status_aceite}
                        onClick={() => handleOpenModal(item.id_documento)}
                      />
                    ))}
                  </WrapDocuments>
                </Transition>
              </Grid>
            </Grid>
          </ContentPage>
        </Container>
      </Main>
      <ModalCustom
        loadingModal={loadingModal}
        open={openModal}
        onClose={handleCloseModal}
        content={contentModal}
        modalType={modalType}
        refSignature={refSignature}
        handleShowAccept={handleShowAccept}
        handleClearSignature={handleClearSignature}
        handleSendSignature={handleSendSignature}
      />
      <AlertCustom
        message={message}
        severity={messageType}
        openMessage={openMessage}
        closeMessage={handleOpenAlert}
        transition="down"
        origin={{ vertical: "top", horizontal: "right" }}
      />
    </React.Fragment>
  );
};

export default TermsOfUser;
