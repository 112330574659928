import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuth } from "contexts/Auth.contexts";
import { Utils } from "utils";
import Api from "service/api";

const useChangeEmail = () => {
  const { emailPending } = useAuth();
  const [newEmail, setNewEmail] = useState(emailPending);
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [loadingPage, setLoadingPage] = useState(true);
  const [loadingForm, setLoadingForm] = useState(false);
  const [transitionIn, setTransitionIn] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [newEmailError, setNewEmailError] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [openMessage, setOpenMessage] = useState(false);
  const [message, setMessage] = useState("");

  const history = useHistory();
  const goLink = (link) => history.push(Utils.setPrefix(link));
  const { t: translate } = useTranslation();

  const handleEmailChange = (event) => {
    const newEmail = event.target.value;
    setNewEmail(newEmail);
    onButtonSend(newEmail);
  };

  const handlePasswordChange = (event) => {
    const password = event.target.value;
    setPassword(password);
  };

  const onButtonSend = (newEmail) => {
    const regex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
    const isValid = regex.test(newEmail);
    setNewEmailError(!isValid);
    if (!isValid) {
      setButtonDisabled(true);
    } else {
      setButtonDisabled(false);
    }
  };

  const handleSubmit = async () => {
    setError("");
    setLoadingPage(true);
    try {
      await changeEmail(newEmail);
      setSuccess(true);
    } catch (error) {
      setError(error.message);
    }
    setLoadingPage(false);
  };

  const changeEmail = async (newEmail) => {
    await Api.post("/usuario/alterar-email", {
      newEmail,
    })
      .then((response) => {
        const { status } = response;
        if (status === 204) {
          history
            .push(Utils.setPrefix("/"))
            .then(() => window.location.reload());
        }
      })
      .catch((error) => {
        setLoadingForm(false);
        setOpenMessage(true);
        if (error.response && error.response.status === 400) {
          const errorMessage = JSON.parse(error.request.response);
          setMessage(errorMessage.data.message);
        } else {
          setMessage("Digite um email válido.");
        }
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      setLoadingPage(false);
    }, 600);

    setTimeout(() => {
      setTransitionIn(true);
    }, 800);
  }, []);

  return {
    newEmail,
    password,
    error,
    success,
    handleEmailChange,
    handlePasswordChange,
    handleSubmit,
    passwordError,
    newEmailError,
    transitionIn,
    loadingPage,
    loadingForm,
    buttonDisabled,
    message,
    translate,
    openMessage,
    setOpenMessage,
    goLink
  };
};

export default useChangeEmail;
